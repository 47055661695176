.twitter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    border: 1px solid var(--border-primary);
    border-radius: 6px;

    @include breakpoint(d) {
        flex-direction: row;
        width: 100%;
        height: 110px;
    }

    &__image,
    &__icon {
        flex-shrink: 0;
        position: relative;
        top: -1px;
        left: -1px;
        right: -1px;
        width: calc(100% + 2px);
        aspect-ratio: 220/140;
        border-radius: 6px 6px 0 0;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        display: flex;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: none;
        }

        @include breakpoint(d) {
            top: unset;
            right: unset;
            width: 110px;
            height: 110px;
            border-radius: 6px 0 0 6px;
        }
    }

    &__icon {
        justify-content: center;
        align-items: center;
        // fv icon
        &--freevideo {
            background-color: #2C2C2C;
        }
        &--freevideo path {
            fill: #C9C9C9;
        }
        // xf icon
        &--xfree {
            background-color: #EAEAEA;
        }
        &--xfree path {
            fill: #2C2C2C;
        }
    }

    &__title {
        color: var(--text-secondary);
        padding: 3.38vw 3.9vw;
        transition: color .2s;

        @include breakpoint(t) {
            padding: 2.15vw 2.35vw;
        }

        @include breakpoint(d) {
            padding: 0 20px;
        }

        &:hover {
            color: var(--text-secondary-hover);
        }

        h3 {
            font-weight: 700;
            font-size: 3.38vw;
            line-height: 1.285;

            @include breakpoint(t) {
                font-size: 2.15vw;
            }

            @include breakpoint(d) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
