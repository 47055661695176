.index {
    &__header {
        padding: 0 var(--spacing-75);
        height: 40px;
        display: flex;
        align-items: center;
        background: var(--background-primary);
        position: sticky;
        top: var(--header-height);
        z-index: 1;
    }

    &__items {
        padding-left: var(--spacing-250);
        margin-left: var(--spacing-150);
        border-left: 1px solid var(--border-primary);

    }
}
