.label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-25);
    height: 20px;
    padding: 0 var(--spacing-75);
    margin: var(--spacing-50) var(--spacing-25);
    background: var(--custom-color, var(--transparent-50));
    color: var(--non-changing-light);
    border-radius: var(--radius-25);
    leading-trim: both;
    text-edge: cap;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;

    &--c {
        &-green {
            background: var(--color-green-300);
            color: var(--state-success-active);
        }
    }
}
