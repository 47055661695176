// Internal
@import '../../sass/settings/colors';
@import '../../sass/settings/global';

@import '../../sass/tools/mixins';

@import '../../sass/generic/reset';
@import '../../sass/generic/shared';

@import '../../sass/elements/buttons';
@import '../../sass/elements/editor-content';
@import '../../sass/elements/inputs';
@import '../../sass/elements/text';
@import '../../sass/elements/structure';

@import '../../sass/objects/themes';
@import '../../sass/objects/animations';
@import '../../sass/objects/layout';
@import '../../sass/objects/layout-content';
@import '../../sass/objects/layout-section';

// COMPONENTS
@import '../../sass/components/header';
@import '../../sass/components/footer';
@import '../../sass/components/blog-item';
@import '../../sass/components/author';
@import '../../sass/components/badges';
@import '../../sass/components/banner';
@import '../../sass/components/button';
@import '../../sass/components/hero';
@import '../../sass/components/label';
@import '../../sass/components/link-nav';
@import '../../sass/components/list';
@import '../../sass/components/preview';
@import '../../sass/components/pagination';
@import '../../sass/components/scroller';
@import '../../sass/components/search-results';
@import '../../sass/components/search';
@import '../../sass/components/slider';
@import '../../sass/components/socials';
@import '../../sass/components/static';
@import '../../sass/components/topics';
@import '../../sass/components/vertical-banner';
@import '../../sass/components/breadcrumb';

// Crema components
@import '../../sass/components/crema/card';
@import '../../sass/components/crema/category';
@import '../../sass/components/crema/index';
@import '../../sass/components/crema/site';
@import '../../sass/components/crema/tags';
@import '../../sass/components/crema/words-cloud';

// Xema components
@import '../../sass/components/xema/image-text';

// Freevideo components
@import '../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../sass/components/glamino/avatar';

// Admin components
@import '../../sass/components/admin/admin';

// PAGES
@import '../../sass/pages/error';

// PAGES - PARTS
@import '../../sass/pages/parts/aside';
@import '../../sass/pages/parts/article';
@import '../../sass/pages/parts/information';
@import '../../sass/pages/parts/listing';

@import '../../sass/utilities/common';



li {
	list-style: none;
}

.dropdown {
	&-holder {
		position: relative;
		z-index: 1;
		width: max-content;
	}

	display: flex;
	flex-direction: column;
	padding: 4px 4px 0;
	min-width: 0;
	text-align: left;
	color: var(--text-primary);
	max-height: 50px;
    border-radius: var(--radius-75);
    border: 1px solid transparent;
	overflow: hidden;
	transition: background 0.2s 0.3s, max-height 0.3s, box-shadow 0.2s 0.3s;

    &__drop-icon {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        z-index: 10;
        pointer-events: none;

        path {
            fill: var(--text-primary);
        }
    }

	&.measuring {
		opacity: 0;
		pointer-events: none;
	}

	&.data {
		position: absolute;
		top: 0;
		right: 0;
	}

	&.is-open {
		max-height: 200px;
		background: var(--background-primary);
        border-color: var(--border-secondary);
		z-index: 1;
		transition: background 0.2s, box-shadow 0.2s, max-height 0.3s 0.1s;

		.dropdown__item.selected {
			.dropdown__item-inner {
				background: var(--background-secondary);
			}
		}
	}

	&__item {
		flex-shrink: 0;
		margin-bottom: 4px;

		&.selected {
			order: -1;
		}

		@media (hover: hover) {
			&:hover .dropdown__item-inner {
				background: var(--background-secondary);
			}
		}

		.icon {
			margin-right: var(--spacing-75);

			&--image {
				width: 24px;
                height: 16px;
			}

			&--emoji {
				font-size: 20px;
				margin-right: 8px;
			}
		}

		span {
			margin-right: auto;
		}

		img:not(.icon) {
			margin-left: 12px;
		}

		&-inner {
			display: flex;
			align-items: center;
			height: 42px;
			padding: 0 34px 0 var(--spacing-150);
			border-radius: 6px;
			transition: background-color 0.2s linear;
			cursor: pointer;
		}
	}

	&--no-text {
        .dropdown__item {
            &-inner {
                padding-right: 28px;
                transition: padding .2s;
            }
        }

        &.is-open {
            .dropdown__item {
                &-inner {
                    padding-right: 34px;
                }
            }
        }

		&:not(.measuring) {
			.dropdown__item {
				.text {
					opacity: 0;
					max-width: 0;
					transition: max-width 0.3s, opacity 0.2s;
				}
			}

			&.is-open {
				.dropdown__item {
					.text {
						opacity: 1;
						max-width: 200px;
						transition: max-width 0.3s, opacity 0.2s 0.1s;
					}
				}
			}
		}
	}
}
