@mixin font-big {
    font-family: 'SF Pro Display', '-apple-system',  'HelveticaNeue', 'Roboto', Helvetica, Arial, sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: 600;
    color: var(--text-primary);

    a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }
}

h1,
.h1 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.1px;
    @include font-big;

    @include breakpoint(t) {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.29px;
    }

    &.s {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.1px;
    }

    &.m {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.29px;
    }

    &.l {
        font-size: 48px;
        line-height: 58px;
        letter-spacing: 0.37px;
    }
}

h2,
.h2 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.23px;

    @include breakpoint(t) {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.07px;
        @include font-big;
    }

    &.s {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.23px;
    }

    &.m {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.07px;
        @include font-big;
    }

    &.l {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.1px;
        @include font-big;
    }
}

h3,
.h3 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.15px;

    @include breakpoint(t) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.23px;
    }

    &.s {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.15px;
    }

    &.m {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.23px;
    }

    &.l {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.07px;
        @include font-big;
    }
}

h4,
.h4 {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;

    @include breakpoint(t) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.15px;
    }

    &.s {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0;
    }

    &.m {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.15px;
    }

    &.l {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.23px;
    }
}

p,
.p {
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.15px;

    &.p2 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
    }

    &.p3 {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.06px;
    }

    &.p4 {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.17px;
    }

    a {
        font-weight: 500;
    }
}

button,
.button {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);

    &.button--small {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.06px;
    }
}

a {
    &.primary {
        text-decoration: underline;
        color: var(--text-link);

        &:hover {
            color: var(--text-link-hover);
        }
    }

    &.secondary {
        text-decoration: underline;
        color: inherit;
    }

    &.plain {
        text-decoration: none;
    }
}

// Utilities
.text {
    &--c {
        &-primary {
            color: var(--text-primary);
        }

        &-secondary {
            color: var(--text-secondary);
        }

        &-tertiary {
            color: var(--text-tertiary);
        }

        &-brand {
            color: var(--text-brand-primary);
        }
    }

    &--fw {
       &-500 {
           font-weight: 500;
       }

        &-600 {
            font-weight: 600;
        }
    }

    &--center {
        text-align: center;
    }
}
