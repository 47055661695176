.scroller {
    position: relative;
}

.scroll-area {
    // MOBILE setup
    --scroller-items: 1; // how many items are fully visible
    --scroller-next-item: 33px; // how much is visible of the next item
    --scroller-gap: var(--l-scroller-gap, var(--spacing-200)); // spacing between items within scroller

    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: var(--scroller-gap);
    padding: 0 var(--side-gap);
    overflow-x: auto;
    overflow-y: hidden;

    &--snap {
        scroll-snap-type: x mandatory;
        scroll-padding: var(--side-gap);

        & > * {
            scroll-snap-align: center;
        }

        @include breakpoint(t) {
            scroll-padding: 0;

            & > * {
                scroll-snap-align: start;
            }
        }
    }

    @include invisibleScrollbar;

    &:not(.scroll-area--custom) > * {
        // side-gap - only one side is relevat, as the other is partially covered by the next item
        width: calc((100% + var(--side-gap) - (var(--scroller-gap) * var(--scroller-items)) - var(--scroller-next-item)) / var(--scroller-items));
        flex-shrink: 0;
    }

    @include breakpoint(t) {
        //TABLET+ setup rewrite
        --scroller-items: 2;
        --scroller-next-item: 0px;
        padding: 0;

        &:not(.scroll-area--custom) > * {
            width: calc(( 100% - (var(--scroller-gap) * (var(--scroller-items) - 1)) - var(--scroller-next-item)) / var(--scroller-items));
        }
    }

    @include breakpoint(d) {
        --scroller-items: 3;
    }

    @include breakpoint(f) {
        --scroller-items: 4;
        --scroller-gap: var(--spacing-400);
    }

    &--hero {
        @include breakpoint(d) {
            --scroller-items: 2;
        }

        @include breakpoint(f) {
            --scroller-items: 3;
            --scroller-gap: var(--spacing-400);
        }

        & + .category__controls button {
            top: 50%;
            height: 100%;
        }
    }
}

// relative `scroller-next-item`
.vertical .scroll-area {
    --scroller-items: 1.5;
    --scroller-next-item: 0;

    &:not(.scroll-area--custom) > * {
        width: calc((100% + var(--side-gap) - var(--scroller-gap)) / var(--scroller-items));
    }

    @media (min-width: 440px) {
        --scroller-items: 2.25;

        &:not(.scroll-area--custom) > * {
            width: calc(( 100% - var(--scroller-gap) * (var(--scroller-items) - 1.25)) / var(--scroller-items));
        }
    }

    @include breakpoint(t) {
        --scroller-items: 3;
        padding: 0;

        &:not(.scroll-area--custom) > * {
            width: calc(( 100% - var(--scroller-gap) * (var(--scroller-items) - 1)) / var(--scroller-items));
        }
    }

    @include breakpoint(d) {
        --scroller-items: 4;
    }

    @media (min-width: 1400px) {
        --scroller-items: 5;
        --scroller-gap: var(--spacing-400);
    }
}

// MODIFIERS
.scroller {
    &--fade-sides {
        &::before,
        &::after {
            content: '';
            display: block;
            width: var(--side-gap);
            z-index: 1;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &::before {
            left: 0;
            background: linear-gradient(to right, var(--background-primary) 0%, rgba(255, 255, 255, 0.00) 100%);;
        }

        &::after {
            right: 0;
            background: linear-gradient(to left, var(--background-primary) 0%, rgba(255, 255, 255, 0.00) 100%);;
        }

        &--d-only {
            @include max-breakpoint(d) {
                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }

    &--d {
        @include breakpoint(d) {
            &--grid {
                margin: 0;

                .scroll-area {
                    overflow-x: unset;
                    overflow-y: unset;
                    padding: 0;
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0,1fr));

                    & > * {
                        width: 100%;
                    }
                }
            }

            &--cols {
                margin: 0;

                .scroll-area {
                    overflow-x: unset;
                    overflow-y: unset;
                    padding: 0;
                    display: grid;

                    & > * {
                        width: 100%;
                    }
                }

                &--2 {
                    .scroll-area {
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                    }
                }
            }
        }
    }

    &--avatars {
        .scroll-area {
            & > * {
                max-width: 156px;
            }

            @include breakpoint(d) {
                max-width: 360px;
            }
        }
    }

    &--twitter {
        .scroll-area {
            @include breakpoint(d) {
                flex-flow: column nowrap;

                & > .twitter-item {
                    &:not(:first-child) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
