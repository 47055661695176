// Internal
@import '../../sass/settings/colors';
@import '../../sass/settings/global';

@import '../../sass/tools/mixins';

@import '../../sass/generic/reset';
@import '../../sass/generic/shared';

@import '../../sass/elements/buttons';
@import '../../sass/elements/editor-content';
@import '../../sass/elements/inputs';
@import '../../sass/elements/text';
@import '../../sass/elements/structure';

@import '../../sass/objects/themes';
@import '../../sass/objects/animations';
@import '../../sass/objects/layout';
@import '../../sass/objects/layout-content';
@import '../../sass/objects/layout-section';

// COMPONENTS
@import '../../sass/components/header';
@import '../../sass/components/footer';
@import '../../sass/components/blog-item';
@import '../../sass/components/author';
@import '../../sass/components/badges';
@import '../../sass/components/banner';
@import '../../sass/components/button';
@import '../../sass/components/hero';
@import '../../sass/components/label';
@import '../../sass/components/link-nav';
@import '../../sass/components/list';
@import '../../sass/components/preview';
@import '../../sass/components/pagination';
@import '../../sass/components/scroller';
@import '../../sass/components/search-results';
@import '../../sass/components/search';
@import '../../sass/components/slider';
@import '../../sass/components/socials';
@import '../../sass/components/static';
@import '../../sass/components/topics';
@import '../../sass/components/vertical-banner';
@import '../../sass/components/breadcrumb';

// Crema components
@import '../../sass/components/crema/card';
@import '../../sass/components/crema/category';
@import '../../sass/components/crema/index';
@import '../../sass/components/crema/site';
@import '../../sass/components/crema/tags';
@import '../../sass/components/crema/words-cloud';

// Xema components
@import '../../sass/components/xema/image-text';

// Freevideo components
@import '../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../sass/components/glamino/avatar';

// Admin components
@import '../../sass/components/admin/admin';

// PAGES
@import '../../sass/pages/error';

// PAGES - PARTS
@import '../../sass/pages/parts/aside';
@import '../../sass/pages/parts/article';
@import '../../sass/pages/parts/information';
@import '../../sass/pages/parts/listing';

@import '../../sass/utilities/common';



 .rating {
     position: relative;
     overflow: hidden;

     &-buttons,
     &-message {
         display: flex;
         align-items: center;
         justify-content: center;

         @include breakpoint(t) {
             justify-content: flex-start;
         }
     }

     &-buttons {
         transition: opacity .3s;
         flex-direction: column;
         gap: var(--spacing-200);

         @include breakpoint(t) {
             flex-direction: row;
         }
     }

     h3.rating__title {
         font-weight: 400;
         font-size: 16px;
         margin-bottom: 0;
     }

     &__buttons {
         display: flex;
         align-items: center;
         justify-content: center;
         flex-flow: column;
         gap: var(--spacing-200);

         @include breakpoint(t) {
             justify-content: flex-start;
             flex-flow: row;
         }
     }

     &__button {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 200px;
         height: 50px;
         border-radius: 6px;
         background: var(--background-button-default);
         font-size: 16px;
         font-weight: 700;
         transition: all .3s;
         color: var(--text-primary);

         &:not(.active):hover {
             filter: brightness(90%);
         }

         &:active {
             transform: scale(0.97);
         }

         &.active {
             border-color: var(--background-button-brand-primary-default, var(--background-button-default));
             font-weight: 600;
         }

         &.disabled {
             cursor: not-allowed;
             opacity: .7;
         }
     }

     &-message {
         justify-content: flex-start;
         gap: var(--spacing-150);
         font-style: italic;
         opacity: 0;
         transform: translateX(200px);
         transition: opacity .3s .2s, transform .3s .2s;
         position: absolute;
         left: 0;
         height: 100%;
         width: 100%;
         z-index: -1;
     }

     &.rated {
         .rating {
             &-buttons {
                 opacity: 0;
                 z-index: -1;
             }

             &-message {
                 opacity: 1;
                 transform: translateX(0);
                 z-index: unset;
             }
         }
     }
}
