.socials {
    display: flex;
    align-items: center;
    margin: calc(var(--spacing-100) * -1);
}

.social {
    padding: var(--spacing-100);
    cursor: pointer;

    &__inner {
        display: flex;
        width: 32px;
        height: 32px;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

