.aside {
    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);

        @include breakpoint(d) {
            .aside {
                &__header,
                &__content {
                    max-width: 330px;
                }
            }
        }

        &--sticky {
            position: sticky;
            top: calc(var(--header-height) + var(--spacing-400));
            max-height: calc(100dvh - (var(--header-height) + var(--spacing-400)));
            overflow-y: auto;
            @include invisibleScrollbar;

            &--blog-detail {
                margin-top: calc(var(--spacing-400) + 21px); //breadcrumbs are 21px + grid spacing
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 14px;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 160px;
            height: 2px;
            background: var(--element-icon-primary);
            position: absolute;
            top: 0;
            left: 0;
        }

        a {
            display: flex;
            align-items: flex-end;
            gap: var(--spacing-150);
            color: var(--text-primary);

            .icon {
                display: flex;

                path {
                    fill: var(--element-icon-secondary);
                }
            }
        }
    }
}
