// EXTRA CSS TO BEND SLIDERS

// Placeholders (CLS prevention)
.HeroSlider:not(.tns-slider),
.CenteredSlider:not(.tns-slider),
.LeftSlider:not(.tns-slider),
.LeftSliderPlus:not(.tns-slider) {
    display: flex;
    flex-flow: row;
    overflow: hidden;

    & > div {
        flex-shrink: 0;
        width: 100%;
    }
}

.HeroSlider:not(.tns-slider) {
    gap: var(--spacing-300);
    margin: 0 36px 21px;

    @include breakpoint(t) {
        margin: 0;
        gap: var(--spacing-200);

        & > div {
            width: calc((100% - var(--spacing-200)) / 2);
        }
    }

    @include breakpoint(m) {
        gap: var(--spacing-400);

        & > div {
            width: calc((100% - var(--spacing-400)*2) / 3);
        }
    }
}

.CenteredSlider:not(.tns-slider) {
    gap: var(--spacing-300);
    margin: 0 36px 21px;

    @include breakpoint(t) {
        margin: 0;

        & > div {
            width: calc((100% - var(--spacing-300)) / 2);
        }
    }

    @include breakpoint(d) {
        & > div {
            width: calc((100% - var(--spacing-300)*2) / 3);
        }
    }

    @include breakpoint(f) {
        & > div {
            width: calc((100% - var(--spacing-300)*3) / 4);
        }
    }
}

.LeftSlider:not(.tns-slider) {
    gap: var(--spacing-200);
    margin: 0 0 21px var(--spacing-300) ;

    & > div {
        width: calc(100% - var(--spacing-600));
    }

    @include breakpoint(t) {
        margin: 0;

        & > div {
            width: calc((100% - var(--spacing-200)) / 2);
        }
    }

    @include breakpoint(d) {
        & > div {
            width: calc((100% - var(--spacing-200)*2) / 3);
        }
    }

    @include breakpoint(f) {
        gap: var(--spacing-400);

        & > div {
            width: calc((100% - var(--spacing-400)*3) / 4);
        }
    }
}

.LeftSliderPlus:not(.tns-slider) {
    gap: var(--spacing-200);
    margin: 0 0 21px var(--spacing-300);

    & > div {
        width: calc(((100% - var(--spacing-200) - var(--spacing-300)) / 1.5));
    }

    @media (min-width: 440px) {
        & > div {
            width: calc(((100% - (var(--spacing-200)*2) - var(--spacing-300)) / 2.5));
        }
    }

    @include breakpoint(t) {
        margin: 0;

        & > div {
            width: calc((100% - var(--spacing-200)*2) / 3);
        }
    }

    @include breakpoint(d) {
        & > div {
            width: calc((100% - var(--spacing-200)*3) / 4);
        }
    }

    @media (min-width: 1400px) {
        gap: var(--spacing-400);

        & > div {
            width: calc((100% - var(--spacing-400)*4) / 5);
        }
    }
}

.tns-inner:has(.LeftSlider) {
    @include max-breakpoint(t) {
        margin: 0 24px !important;
    }
}

// Controls centered to thumbnail
.tns-outer:has(.card--blog) + .category__controls button {
    top: calc((100% - 76px)/2);
}

.tns-outer:has(.banner, .site-item--content-inside) + .category__controls button {
    top: 50%;
}
