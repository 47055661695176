.static {
    &-aside {
        &__section {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 50px;
            }

            &__header {
                font-weight: 700;
                font-size: 28px;
                line-height: 25px;
                text-align: left;
                color: var(--text-secondary);
                margin-bottom: 40px;
            }
        }
    }
}

.aside-links {
    ul {
        display: flex;
        flex-direction: column;
    }

    li {
        list-style: none;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        color: var(--text-secondary);

        &:hover {
            color: var(--text-secondary-hover);
        }
    }
}
