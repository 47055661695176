.card {
    display: flex;
    position: relative;
    aspect-ratio: 1/1;

    &--hero {
        aspect-ratio: 16/9;

        @include breakpoint(d) {
            gap: var(--spacing-400);
        }
    }

    &--blog {
        aspect-ratio: unset;
    }

    &--offer {
        @include breakpoint(t) {
            aspect-ratio: 49/50;
        }
    }

    &__thumbnail {
        position: relative;
        border-radius: var(--radius-150);
        overflow: hidden;

        @include max-breakpoint(t) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        @include breakpoint(t) {
            aspect-ratio: 16/9;
        }

        & > img,
        & > video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--radius-150);
        }
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-150);
        padding: var(--spacing-150);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 var(--radius-150) var(--radius-150);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);


        &__left,
        &__right {
            display: flex;
            align-items: center;
            gap: var(--spacing-100);
        }

        &__left {
            overflow: hidden;
        }

        &__right {
            flex-shrink: 0;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--background-button-default);
        width: var(--spacing-400);
        height: var(--spacing-400);
        border-radius: var(--radius-75);
        flex-shrink: 0;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--radius-75);
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__title,
    &__subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--non-changing-light);
        font-size: 12px;
        letter-spacing: 0.17px;
    }

    &__title {
        font-weight: 500;
        line-height: 18px;
    }

    &__subtitle {
        line-height: 17px;
    }

    &__button {
        background: var(--color-transparent-black-75);
        color: #fff;

        &:hover {
            background: var(--color-transparent-black-75);
            color: #fff;
        }
    }
}

// Glide overwrite for cards
.tns-outer {

    &__track {
        line-height: 0;
    }

    .tns-nav {
        display: flex;
        justify-content: center;
        position: relative;
        bottom: unset;
        gap: var(--spacing-100);
        margin-top: var(--spacing-200);
        overflow: hidden;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(t) {
            margin-top: var(--spacing-400);
        }
    }

    [aria-controls] {
        flex: 0 0 5px;
        background: var(--transparent-25);
        margin: 0;
        width: 5px;
        height: 5px;
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: var(--radius-1000);

        &.tns-nav-active {
            flex: 0 0 20px;
            background: var(--color-pink-500);
        }
    }
}

