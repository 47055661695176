.footer {
    text-align: center;
    background-color: var(--background-primary);
    color: var(--text-tertiary);

    &-inner {
        max-width: var(--max-content-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: var(--spacing-200) 0;
        gap: var(--spacing-200);
        border-top: 1px solid var(--border-secondary);

        @include breakpoint(t) {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
            height: 64px;
            align-items: center;
            padding: 0;
        }
    }

    a {
        color: var(--text-tertiary);
        font-weight: 400;

        &:hover {
            color: var(--text-tertiary-hover);
        }
    }

    &__links {
        order: 2;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            list-style: none;

            &:not(:last-child)::after {
                content: "•";
                flex-shrink: 0;
                margin: 0 var(--spacing-200);
                color: var(--text-tertiary);
            }
        }
    }

    &__copyright {
        order: 3;
        text-align: center;
        color: var(--text-tertiary);

        span {
            cursor: pointer;
        }

        @include breakpoint(t) {
            text-align: end;
        }
    }

    &__socials {
        order: 1;
        display: flex;
        justify-content: center;

        path {
            fill: var(--element-icon-secondary);
        }

        @include breakpoint(t) {
            justify-content: flex-start;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        text-decoration: none;

        span {
            margin-left: var(--spacing-100);
        }

        &:not(:last-child)::after {
            content: "•";
            flex-shrink: 0;
            margin: 0 var(--spacing-200);
            color: var(--text-tertiary);
            font-weight: 500;
        }
    }

    &__languages {
        order: 4;
    }
}
