/* ==========================================================================
   EL: BUTTONS
   ========================================================================== */

button {
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	background: none;
	border: 0;
	outline: 0;
	padding: 0;
	cursor: pointer;
	font-family: '-apple-system', 'SF Pro Text', 'HelveticaNeue', 'Roboto', Helvetica, Arial, sans-serif;
}
