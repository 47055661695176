// Preview - consist of:
//  -   thumbnail at certain aspect ratio
//  -   line of badges

.preview {
    position: relative;
    display: flex;
    width: 100%;
    aspect-ratio: 16/9;

    @supports not (aspect-ratio: 1 / 1) {
        height: 0;
        padding-bottom: 56.25%;
    }

    &__thumbnail {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        // Placeholder
        &::before {
            content: '';
            background: var(--background-secondary);
            border: 1px solid var(--border-secondary);
        }

        &::before,
        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: cover;
        }
    }

    &--vertical {
        aspect-ratio: auto;

        .preview__thumbnail {
            height: auto;
        }

        img,
        video {
            position: static;
            width: 100%;
            height: auto;
        }
    }

    &__badges {
        position: absolute;
        bottom: var(--spacing-200);
        left: var(--spacing-200);
    }
}
