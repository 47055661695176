/* ==========================================================================
   $GLOBAL
   ========================================================================== */

// breakpoints map
$breakpoints: (
	// until normal-size phones
	's': 414px,
	// tablet
	't': 600px,
	// desktop
	'd': 1040px,
    // medium desktop
    'm': 1200px,
    // full (desktop XL)
    'f': 1600px,
);

// Primitives
:root {
    --radius-0: 0px;
    --radius-25: 2px;
    --radius-50: 4px;
    --radius-75: 6px;
    --radius-100: 8px;
    --radius-150: 12px;
    --radius-200: 16px;
    --radius-300: 24px;
    --radius-1000: 999px;

    --spacing-0: 0px;
    --spacing-25: 2px;
    --spacing-50: 4px;
    --spacing-75: 6px;
    --spacing-100: 8px;
    --spacing-150: 12px;
    --spacing-200: 16px;
    --spacing-250: 20px;
    --spacing-300: 24px;
    --spacing-400: 32px;
    --spacing-500: 40px;
    --spacing-600: 48px;
    --spacing-800: 64px;
    --spacing-1000: 80px;
    --spacing-1600: 128px;
}

// Component specific
// Slide up max width on mobile
$slide-up-mw: 500px;
