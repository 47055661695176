.link-nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 100%;

    &--l {
        max-width: 650px;
        width: 100%;

        .link-nav-cat {
            height: 72px;
        }
    }

    &--aside {
        padding: 0;
    }

    &--enhanced {
        .link-nav {
            &-cat {
                cursor: pointer;
                border-radius: var(--radius-75);
                border: 1px solid var(--border-secondary);
            }

            &-sub {
                padding: 0 var(--spacing-200);
                max-height: 0;

                &.opened {
                    max-height: 96px;
                }

                &__item {
                    &:first-child {
                        margin-top: var(--spacing-150);
                    }

                    &:last-child {
                        margin-bottom: var(--spacing-200);
                    }
                }
            }

            @include max-breakpoint(t) {
                &__item {
                    border-color: transparent;
                }
            }
        }

        &.opened {
            .chevron {
                transform: rotate(-90deg);
            }
        }
    }

    &__item {
        font-weight: 500;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--text-primary);

        &:not(:first-child) {
            transition: border-top-color .2s;
            border-top: 1px solid var(--border-secondary);
        }

        &:hover,
        &:focus {
            border-color: transparent;

            .link-nav-cat {
                background: var(--background-secondary);
            }
        }

        &:hover + .link-nav__item,
        &:focus + .link-nav__item {
            border-top-color: transparent;
        }

        & > button {
            font-weight: 500;
            color: var(--text-primary);
        }

        & > a {
            color: var(--text-primary);
        }

        img {
            width: 16px;
        }

        .chevron {
            width: 16px;
            height: 16px;
            margin-left: auto;
            transition: transform .2s;
        }
    }

    &-cat {
        display: flex;
        align-items: center;
        height: var(--default-height);
        gap: var(--spacing-150);
        padding: 0 var(--spacing-200);
        width: 100%;
        text-align: left;
        border-radius: var(--radius-75);
        transition: background-color .3s;

        path {
            fill: var(--text-primary);
        }
    }

    &-sub {
        max-height: 0;
        opacity: 0;
        transition-duration: .3s;
        transition-property: max-height, opacity;
        transition-timing-function: ease-out;
        pointer-events: none;
        list-style: none;
        width: 100%;
        padding-left: 46px;
        padding-right: var(--spacing-200);
        will-change: max-height;

        &.opened {
            display: flex;
            flex-direction: column;
            opacity: 1;
            pointer-events: unset;

            .link-nav-sub__item {
                transform: translateY(0);
                opacity: 1;
                transition: transform .2s, opacity .2s;

                @for $i from 1 to 100 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i * 0.1s;
                    }
                }
            }
        }

        &__item {
            font-size: 14px;
            line-height: 20px;
            color: var(--text-secondary);
            position: relative;
            display: flex;

            // animation
            transform: translateY(-20px);
            opacity: 0;
            transition: transform .2s, opacity .1s;

            @for $i from 1 to 100 {
                &:nth-child(#{$i}) {
                    transition-delay: 0s;
                }
            }

            &:first-child {
                margin-top: calc(var(--spacing-75) * -1);
            }

            &:last-child {
                margin-bottom: var(--spacing-300);
            }

            a {
                max-width: 100%;
                width: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--text-secondary);
                font-weight: 400;
                padding: var(--spacing-75) 0;

                &:hover {
                    text-decoration: underline;
                }

                &:first-letter {
                    text-transform: capitalize;
                }
            }

            &.active {
                a {
                    color: var(--text-link);

                    &:hover {
                        color: var(--text-link-hover);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// Quick links nav
.quick-links {
    &__nav {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: var(--spacing-150);

        @include breakpoint(d) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            column-gap: var(--spacing-600);
            row-gap: var(--spacing-200);
        }
    }

    &__link {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.06px;
        color: var(--text-brand-primary);

        &:hover {
            color: var(--text-brand-primary);
        }
    }
}
