.author {
    display: flex;
    align-items: center;
    gap: var(--spacing-150);

    &__avatar {
        position: relative;
        display: flex;
        width: 32px;
        height: 32px;
        flex-shrink: 0;

        // Placeholder
        &::before {
            content: '';
            background: var(--background-secondary);
        }

        &::before,
        & > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__timestamp,
    &__reading-time {
        display: inline-flex;
        align-items: center;
        gap: var(--spacing-150);

        &::before {
            content: '|';
            color: var(--transparent-25);
        }
    }
}
