/* ==========================================================================
   $COLORS
   ========================================================================== */

// Primitives
:root {
    --color-blue-100: #d8e6fd;
    --color-blue-200: #c4dafc;
    --color-blue-300: #b1cdfb;
    --color-blue-350: #89b4fa;
    --color-blue-400: #629bf8;
    --color-blue-50: #ebf3fe;
    --color-blue-500: #3b82f6;
    --color-blue-600: #2f68c5;
    --color-blue-700: #234e94;
    --color-blue-750: #183462;
    --color-blue-800: #12274a;
    --color-blue-900: #0c1a31;
    --color-blue-950: #060d19;

    --color-green-100: #d3f3df;
    --color-green-200: #bdeecf;
    --color-green-300: #a7e8bf;
    --color-green-350: #7adc9e;
    --color-green-400: #4ed17e;
    --color-green-50: #e9f9ef;
    --color-green-500: #22c55e;
    --color-green-600: #1b9e4b;
    --color-green-700: #147638;
    --color-green-750: #0e4f26;
    --color-green-800: #0a3b1c;
    --color-green-900: #072713;
    --color-green-950: #031409;

    --color-neutral-0: #ffffff;
    --color-neutral-100: #f1f1f1;
    --color-neutral-1000: #000000;
    --color-neutral-200: #e5e5e5;
    --color-neutral-300: #cbcbcb;
    --color-neutral-350: #a3a3a3;
    --color-neutral-400: #8b8b8b;
    --color-neutral-50: #fafafa;
    --color-neutral-500: #737373;
    --color-neutral-600: #525252;
    --color-neutral-700: #404040;
    --color-neutral-750: #323232;
    --color-neutral-800: #252525;
    --color-neutral-900: #191919;
    --color-neutral-950: #0d0d0d;

    --color-pink-100: #f8dae4;
    --color-pink-200: #f5c7d7;
    --color-pink-300: #f1b4ca;
    --color-pink-350: #eb8faf;
    --color-pink-400: #e46995;
    --color-pink-50: #fcecf2;
    --color-pink-500: #dd447a;
    --color-pink-600: #b13662;
    --color-pink-700: #852949;
    --color-pink-750: #581b31;
    --color-pink-800: #421425;
    --color-pink-900: #2c0e18;
    --color-pink-950: #16070c;

    --color-red-100: #fcdada;
    --color-red-200: #fac7c7;
    --color-red-300: #f9b4b4;
    --color-red-350: #f58f8f;
    --color-red-400: #f26969;
    --color-red-50: #fdecec;
    --color-red-500: #ef4444;
    --color-red-600: #c13737;
    --color-red-700: #932929;
    --color-red-750: #661c1c;
    --color-red-800: #4f1515;
    --color-red-900: #380e0e;
    --color-red-950: #210808;

    --color-rose-100: #fdd9df;
    --color-rose-200: #fcc5cf;
    --color-rose-300: #fbb2bf;
    --color-rose-350: #f88c9e;
    --color-rose-400: #f6657e;
    --color-rose-50: #feecef;
    --color-rose-500: #f43f5e;
    --color-rose-600: #c3324b;
    --color-rose-700: #922638;
    --color-rose-750: #621926;
    --color-rose-800: #49131c;
    --color-rose-900: #310d13;
    --color-rose-950: #180609;

    --color-yellow-100: #fbf0ce;
    --color-yellow-200: #f9e8b5;
    --color-yellow-300: #f7e19c;
    --color-yellow-350: #f2d16b;
    --color-yellow-400: #eec239;
    --color-yellow-50: #fdf7e6;
    --color-yellow-500: #eab308;
    --color-yellow-600: #bb8f06;
    --color-yellow-700: #8c6b05;
    --color-yellow-750: #5e4803;
    --color-yellow-800: #463602;
    --color-yellow-900: #2f2402;
    --color-yellow-950: #171201;

    --color-other-briliant-azure: #3c95ff;
    --color-other-mantis: #63b53d;
    --color-other-vermilion: #ee3333;

    --color-transparent-black-5: #0000000d;
    --color-transparent-black-25: #00000040;
    --color-transparent-black-50: #00000080;
    --color-transparent-black-75: #000000bf;

    --color-transparent-white-5: #ffffff0d;
    --color-transparent-white-25: #ffffff40;
    --color-transparent-white-50: #ffffff80;
    --color-transparent-white-75: #ffffffbf;
}
