.search-results {
    display: flex;
    flex-direction: column;
    color: var(--text-primary);
    font-size: 16px;
    line-height: 1.5;

    &__title {
        text-transform: capitalize;
    }

    &__results {
        margin: 0;
    }

    &__label {
        color: var(--text-primary);
        font-size: 16px;
        line-height: 1.5;
    }

    &--no-results {
        justify-content: center;
        align-items: center;
        gap: var(--spacing-200);
        flex-grow: 1;
        text-align: center;

        @include breakpoint(d) {
            max-width: 640px;
            aspect-ratio: 1/1;
            flex-grow: 0;
        }

        .search-results {
            &__title {
                font-size: 16px;
                font-weight: 700;
            }

            &__subtitle {
                color: var(--text-secondary);
            }
        }
    }
}
