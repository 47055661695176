// SEARCH
.search {
    // 1) Search Container (holder)
    // > Search (wrapper) + Search button
    &-container {
        position: relative;
        display: flex;
        width: 100%;
        gap: var(--spacing-200);
    }

    // 1.1) Search (wrapper)
    // e.g. Search results comes from the size of Search form
    // > Search input, Search whisper (results)
    & {
        position: relative;
        display: flex;
        width: 100%;
        gap: var(--spacing-200);
        margin: 0;
    }

    // 1.1.1) Search input
    // > icon, input, status
    &-input {
        display: flex;
        align-items: center;
        gap: var(--spacing-75);
        height: var(--default-height);
        border-radius: var(--radius-75);
        background-color: var(--background-input-default);
        padding: 0 0 0 var(--spacing-200);
        width: 100%;

        &__icon {
            display: flex;
            width: var(--spacing-300);
            height: var(--spacing-300);

            path {
                stroke: var(--element-icon-primary);
            }
        }

        & > input {
            border: none;
            font-size: 16px;
            width: 100%;
            height: 100%;
            padding: 0;
            color: var(--text-primary);

            &::placeholder {
                color: var(--text-tertiary);
                opacity: 1;
            }
        }

        &__status {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 var(--spacing-200);

            .status {
                display: flex;

                &--loader {
                    animation: spin 750ms infinite;
                }

                &--cross {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    // 1.1.2) Search whisper
    // > results OR no results
    &-whisper {
        position: absolute;
        z-index: 10;
        background: var(--background-primary);
        border: 1px solid var(--border-primary);
        top: calc(100% + 8px);
        left: 0;
        right: 0;
        padding: var(--spacing-100);
        border-radius: 6px;
        box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
        max-height: 235px;
        overflow: hidden auto;

        &__list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: var(--spacing-100);

            &--no-results {
                .text {
                    white-space: normal;
                }
            }
        }

        li,
        &__item {
            min-height: 48px;
            border-radius: 3px;

        }

        &__item {
            padding: 0 var(--spacing-150);
            display: flex;
            align-items: center;
            gap: var(--spacing-150);
            font-size: 16px;
            color: var(--text-primary);

            svg {
                flex-shrink: 0;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }


            &:hover {
                background-color: var(--background-secondary);
                color: var(--text-primary);
            }
        }

        li:focus .search__results__item {
            background-color: var(--border-secondary);
            color: var(--text-primary);
        }
    }
}
