.badges {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 1;
    gap: 8px;
}

.badge {
    cursor: pointer;
    flex-shrink: 0;

    &__inner {
        display: flex;
        align-items: center;
        gap: var(--spacing-75);
        height: 44px;
        padding: 0 var(--spacing-300);
        border-radius: var(--b-badge-border-radius, 6px);
        background-color: var(--background-button-default);
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary);
        text-transform: uppercase;

        & > img {
            height: auto;
            width: auto;
            max-height: 24px;
        }

        & > svg path {
            stroke: var(--element-icon-primary);
        }

        &:not(.badge__inner--colors-set):hover {
            background-color: var(--background-button-hover);
        }

        &:focus-visible {
            outline: color-mix(in srgb, var(--state-focus-default) 25%, transparent) 2px solid;
            outline-offset: 2px;
        }

        &--colors-set {
            &:hover {
                filter: brightness(0.9);
            }
        }
    }
}
