@charset "UTF-8";
/* ==========================================================================
   $COLORS
   ========================================================================== */
:root {
  --color-blue-100: #d8e6fd;
  --color-blue-200: #c4dafc;
  --color-blue-300: #b1cdfb;
  --color-blue-350: #89b4fa;
  --color-blue-400: #629bf8;
  --color-blue-50: #ebf3fe;
  --color-blue-500: #3b82f6;
  --color-blue-600: #2f68c5;
  --color-blue-700: #234e94;
  --color-blue-750: #183462;
  --color-blue-800: #12274a;
  --color-blue-900: #0c1a31;
  --color-blue-950: #060d19;
  --color-green-100: #d3f3df;
  --color-green-200: #bdeecf;
  --color-green-300: #a7e8bf;
  --color-green-350: #7adc9e;
  --color-green-400: #4ed17e;
  --color-green-50: #e9f9ef;
  --color-green-500: #22c55e;
  --color-green-600: #1b9e4b;
  --color-green-700: #147638;
  --color-green-750: #0e4f26;
  --color-green-800: #0a3b1c;
  --color-green-900: #072713;
  --color-green-950: #031409;
  --color-neutral-0: #ffffff;
  --color-neutral-100: #f1f1f1;
  --color-neutral-1000: #000000;
  --color-neutral-200: #e5e5e5;
  --color-neutral-300: #cbcbcb;
  --color-neutral-350: #a3a3a3;
  --color-neutral-400: #8b8b8b;
  --color-neutral-50: #fafafa;
  --color-neutral-500: #737373;
  --color-neutral-600: #525252;
  --color-neutral-700: #404040;
  --color-neutral-750: #323232;
  --color-neutral-800: #252525;
  --color-neutral-900: #191919;
  --color-neutral-950: #0d0d0d;
  --color-pink-100: #f8dae4;
  --color-pink-200: #f5c7d7;
  --color-pink-300: #f1b4ca;
  --color-pink-350: #eb8faf;
  --color-pink-400: #e46995;
  --color-pink-50: #fcecf2;
  --color-pink-500: #dd447a;
  --color-pink-600: #b13662;
  --color-pink-700: #852949;
  --color-pink-750: #581b31;
  --color-pink-800: #421425;
  --color-pink-900: #2c0e18;
  --color-pink-950: #16070c;
  --color-red-100: #fcdada;
  --color-red-200: #fac7c7;
  --color-red-300: #f9b4b4;
  --color-red-350: #f58f8f;
  --color-red-400: #f26969;
  --color-red-50: #fdecec;
  --color-red-500: #ef4444;
  --color-red-600: #c13737;
  --color-red-700: #932929;
  --color-red-750: #661c1c;
  --color-red-800: #4f1515;
  --color-red-900: #380e0e;
  --color-red-950: #210808;
  --color-rose-100: #fdd9df;
  --color-rose-200: #fcc5cf;
  --color-rose-300: #fbb2bf;
  --color-rose-350: #f88c9e;
  --color-rose-400: #f6657e;
  --color-rose-50: #feecef;
  --color-rose-500: #f43f5e;
  --color-rose-600: #c3324b;
  --color-rose-700: #922638;
  --color-rose-750: #621926;
  --color-rose-800: #49131c;
  --color-rose-900: #310d13;
  --color-rose-950: #180609;
  --color-yellow-100: #fbf0ce;
  --color-yellow-200: #f9e8b5;
  --color-yellow-300: #f7e19c;
  --color-yellow-350: #f2d16b;
  --color-yellow-400: #eec239;
  --color-yellow-50: #fdf7e6;
  --color-yellow-500: #eab308;
  --color-yellow-600: #bb8f06;
  --color-yellow-700: #8c6b05;
  --color-yellow-750: #5e4803;
  --color-yellow-800: #463602;
  --color-yellow-900: #2f2402;
  --color-yellow-950: #171201;
  --color-other-briliant-azure: #3c95ff;
  --color-other-mantis: #63b53d;
  --color-other-vermilion: #ee3333;
  --color-transparent-black-5: #0000000d;
  --color-transparent-black-25: #00000040;
  --color-transparent-black-50: #00000080;
  --color-transparent-black-75: #000000bf;
  --color-transparent-white-5: #ffffff0d;
  --color-transparent-white-25: #ffffff40;
  --color-transparent-white-50: #ffffff80;
  --color-transparent-white-75: #ffffffbf;
}

/* ==========================================================================
   $GLOBAL
   ========================================================================== */
:root {
  --radius-0: 0px;
  --radius-25: 2px;
  --radius-50: 4px;
  --radius-75: 6px;
  --radius-100: 8px;
  --radius-150: 12px;
  --radius-200: 16px;
  --radius-300: 24px;
  --radius-1000: 999px;
  --spacing-0: 0px;
  --spacing-25: 2px;
  --spacing-50: 4px;
  --spacing-75: 6px;
  --spacing-100: 8px;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-400: 32px;
  --spacing-500: 40px;
  --spacing-600: 48px;
  --spacing-800: 64px;
  --spacing-1000: 80px;
  --spacing-1600: 128px;
}

/* ==========================================================================
   $MIXINS
   ========================================================================== */
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  /*remove the resize handle on the bottom right*/
  user-select: text;
}

label {
  cursor: pointer;
}

html {
  font-family: "SF Pro Text", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html.no-bounce {
  overscroll-behavior-y: none;
}
html.no-bounce body {
  overscroll-behavior-y: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  overscroll-behavior: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

img {
  -webkit-user-select: none;
  /* neoznacovat obrazky */
  -webkit-touch-callout: none;
  /* zakazani stahovani obrazku na iphonu */
  transform: translateZ(0);
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* ==========================================================================
   EL: BUTTONS
   ========================================================================== */
button {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  font-family: "-apple-system", "SF Pro Text", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
}

blockquote {
  position: relative;
  font-size: 22px;
  letter-spacing: 0;
  font-style: italic;
  color: var(--text-secondary);
}
@media (min-width: 600px) {
  blockquote {
    font-size: 26px;
  }
}
blockquote p {
  display: inline;
  font-size: inherit;
  color: var(--text-secondary);
}
blockquote:before {
  content: "„";
}
blockquote:after {
  content: "“";
}

em {
  font-style: normal;
}

*[data-crudadmin-editor] :is(h1, h2, h3, h4, ul, ol):not(:first-child),
.article:has(:not(.article__content)) :is(h1, h2, h3, h4, ul, ol):not(:first-child),
.article__content :is(h1, h2, h3, h4, ul, ol):not(:first-child) {
  margin-top: var(--spacing-300);
}
*[data-crudadmin-editor] :is(img, video, table, hr),
.article:has(:not(.article__content)) :is(img, video, table, hr),
.article__content :is(img, video, table, hr) {
  margin-top: var(--spacing-300);
}
*[data-crudadmin-editor] :is(h1, h2, h3, h4):not(:has(+ ul, + ol)),
.article:has(:not(.article__content)) :is(h1, h2, h3, h4):not(:has(+ ul, + ol)),
.article__content :is(h1, h2, h3, h4):not(:has(+ ul, + ol)) {
  margin-bottom: var(--spacing-100);
}
*[data-crudadmin-editor] p + :is(ul, ol),
.article:has(:not(.article__content)) p + :is(ul, ol),
.article__content p + :is(ul, ol) {
  margin-top: var(--spacing-100) !important;
}
*[data-crudadmin-editor] :is(ul, ol) + p,
.article:has(:not(.article__content)) :is(ul, ol) + p,
.article__content :is(ul, ol) + p {
  margin-top: var(--spacing-300);
}
*[data-crudadmin-editor] ol,
*[data-crudadmin-editor] ul,
.article:has(:not(.article__content)) ol,
.article:has(:not(.article__content)) ul,
.article__content ol,
.article__content ul {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}
*[data-crudadmin-editor] ol li,
*[data-crudadmin-editor] ul li,
.article:has(:not(.article__content)) ol li,
.article:has(:not(.article__content)) ul li,
.article__content ol li,
.article__content ul li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
}
*[data-crudadmin-editor] ol li::before,
*[data-crudadmin-editor] ul li::before,
.article:has(:not(.article__content)) ol li::before,
.article:has(:not(.article__content)) ul li::before,
.article__content ol li::before,
.article__content ul li::before {
  display: inline-block;
  width: var(--spacing-300);
  flex-shrink: 0;
  position: absolute;
  left: 0;
  line-height: 24px;
}
*[data-crudadmin-editor] ol,
.article:has(:not(.article__content)) ol,
.article__content ol {
  list-style: none;
  counter-reset: item;
}
*[data-crudadmin-editor] ol > li,
.article:has(:not(.article__content)) ol > li,
.article__content ol > li {
  padding-left: var(--spacing-300);
  counter-increment: item;
}
*[data-crudadmin-editor] ol > li::before,
.article:has(:not(.article__content)) ol > li::before,
.article__content ol > li::before {
  content: counters(item, ".") ". ";
}
*[data-crudadmin-editor] ol ol > li,
.article:has(:not(.article__content)) ol ol > li,
.article__content ol ol > li {
  padding-left: var(--spacing-600);
}
*[data-crudadmin-editor] ol ol > li > ol > li,
.article:has(:not(.article__content)) ol ol > li > ol > li,
.article__content ol ol > li > ol > li {
  padding-left: var(--spacing-800);
}
*[data-crudadmin-editor] ul,
.article:has(:not(.article__content)) ul,
.article__content ul {
  counter-reset: item-ul;
  list-style: disc;
}
*[data-crudadmin-editor] ul > li,
.article:has(:not(.article__content)) ul > li,
.article__content ul > li {
  margin-left: var(--spacing-300);
}
*[data-crudadmin-editor] img, *[data-crudadmin-editor] video,
.article:has(:not(.article__content)) img,
.article:has(:not(.article__content)) video,
.article__content img,
.article__content video {
  max-width: 100%;
  height: auto;
  border-radius: var(--radius-75);
}
*[data-crudadmin-editor] iframe,
.article:has(:not(.article__content)) iframe,
.article__content iframe {
  width: 100%;
  min-height: 440px;
  display: block;
}
*[data-crudadmin-editor] table,
.article:has(:not(.article__content)) table,
.article__content table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--border-secondary);
}
*[data-crudadmin-editor] table caption,
.article:has(:not(.article__content)) table caption,
.article__content table caption {
  margin-bottom: 8px;
  font-weight: 600;
}
*[data-crudadmin-editor] table thead,
.article:has(:not(.article__content)) table thead,
.article__content table thead {
  background: var(--background-tertiary);
  font-weight: 600;
}
*[data-crudadmin-editor] table th,
.article:has(:not(.article__content)) table th,
.article__content table th {
  border: none;
}
*[data-crudadmin-editor] table td,
.article:has(:not(.article__content)) table td,
.article__content table td {
  border: 1px solid;
}
*[data-crudadmin-editor] table td, *[data-crudadmin-editor] table th,
.article:has(:not(.article__content)) table td,
.article:has(:not(.article__content)) table th,
.article__content table td,
.article__content table th {
  padding: 8px;
  word-break: break-word;
  border-color: var(--border-secondary);
}
*[data-crudadmin-editor] table td p, *[data-crudadmin-editor] table th p,
.article:has(:not(.article__content)) table td p,
.article:has(:not(.article__content)) table th p,
.article__content table td p,
.article__content table th p {
  margin-bottom: 0;
}
*[data-crudadmin-editor] a,
.article:has(:not(.article__content)) a,
.article__content a {
  text-decoration: underline;
  color: var(--text-link);
}
*[data-crudadmin-editor] a:hover,
.article:has(:not(.article__content)) a:hover,
.article__content a:hover {
  color: var(--text-link-hover);
}

/* ==========================================================================
   EL: INPUTS
   ========================================================================== */
input {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}
input::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #fff inset !important;
  background-color: #fff !important;
  background-clip: content-box !important;
}

input[type=checkbox].labeled, input[type=file] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  pointer-events: none;
}

input {
  box-sizing: border-box;
  user-select: text;
}
input:focus {
  outline: none;
}
input:-moz-ui-invalid {
  box-shadow: none;
}

input:disabled,
textarea:disabled,
option:disabled,
optgroup:disabled,
select:disabled {
  color: unset;
  cursor: no-drop;
  opacity: 1;
  -webkit-text-fill-color: #000;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 600;
  color: var(--text-primary);
}
h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: none;
}
h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover {
  color: inherit;
}

h1,
.h1 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.1px;
  font-family: "SF Pro Display", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
}
@media (min-width: 600px) {
  h1,
.h1 {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.29px;
  }
}
h1.s,
.h1.s {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.1px;
}
h1.m,
.h1.m {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.29px;
}
h1.l,
.h1.l {
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.37px;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.23px;
}
@media (min-width: 600px) {
  h2,
.h2 {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.07px;
    font-family: "SF Pro Display", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
  }
}
h2.s,
.h2.s {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.23px;
}
h2.m,
.h2.m {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.07px;
  font-family: "SF Pro Display", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
}
h2.l,
.h2.l {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.1px;
  font-family: "SF Pro Display", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
}

h3,
.h3 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.15px;
}
@media (min-width: 600px) {
  h3,
.h3 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.23px;
  }
}
h3.s,
.h3.s {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.15px;
}
h3.m,
.h3.m {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.23px;
}
h3.l,
.h3.l {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.07px;
  font-family: "SF Pro Display", "-apple-system", "HelveticaNeue", "Roboto", Helvetica, Arial, sans-serif;
}

h4,
.h4 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
}
@media (min-width: 600px) {
  h4,
.h4 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.15px;
  }
}
h4.s,
.h4.s {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
}
h4.m,
.h4.m {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.15px;
}
h4.l,
.h4.l {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.23px;
}

p,
.p {
  color: var(--text-secondary);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.15px;
}
p.p2,
.p.p2 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
p.p3,
.p.p3 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.06px;
}
p.p4,
.p.p4 {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.17px;
}
p a,
.p a {
  font-weight: 500;
}

button,
.button {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}
button.button--small,
.button.button--small {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06px;
}

a.primary {
  text-decoration: underline;
  color: var(--text-link);
}
a.primary:hover {
  color: var(--text-link-hover);
}
a.secondary {
  text-decoration: underline;
  color: inherit;
}
a.plain {
  text-decoration: none;
}

.text--c-primary {
  color: var(--text-primary);
}
.text--c-secondary {
  color: var(--text-secondary);
}
.text--c-tertiary {
  color: var(--text-tertiary);
}
.text--c-brand {
  color: var(--text-brand-primary);
}
.text--fw-500 {
  font-weight: 500;
}
.text--fw-600 {
  font-weight: 600;
}
.text--center {
  text-align: center;
}

hr {
  background: var(--border-secondary);
  height: 1px;
  width: 100%;
  margin: 0;
  border: none;
  outline: none;
}

/* ==========================================================================
   OB: THEME
   ========================================================================== */
body {
  --element-icon-secondary: var(--color-neutral-400);
  --non-changing-dark: var(--color-neutral-900);
  --non-changing-light: var(--color-neutral-0);
  --state-error-active: var(--color-red-700);
  --state-error-default: var(--color-red-500);
  --state-error-hover: var(--color-red-600);
  --state-focus-active: var(--color-blue-700);
  --state-focus-default: var(--color-blue-500);
  --state-focus-hover: var(--color-blue-600);
  --state-success-active: var(--color-green-700);
  --state-success-default: var(--color-green-500);
  --state-success-hover: var(--color-green-600);
  --state-warning-active: var(--color-yellow-700);
  --state-warning-default: var(--color-yellow-500);
  --state-warning-hover: var(--color-yellow-600);
  --text-link: var(--color-blue-500);
  background: var(--background-primary);
  color: var(--text-secondary);
}
body[data-theme=light] {
  --background-primary: var(--color-neutral-0);
  --background-secondary: var(--color-neutral-50);
  --background-tertiary: var(--color-neutral-100);
  --background-button-active: var(--color-neutral-300);
  --background-button-default: var(--color-neutral-100);
  --background-button-hover: var(--color-neutral-200);
  --background-button-pill: var(--color-neutral-0);
  --background-input-default: var(--color-neutral-100);
  --background-input-hover: var(--color-neutral-200);
  --border-primary: var(--color-neutral-100);
  --border-secondary: var(--color-neutral-200);
  --element-icon-primary: var(--color-neutral-900);
  --text-primary: var(--color-neutral-950);
  --text-secondary: var(--color-neutral-600);
  --text-tertiary: var(--color-neutral-400);
  --text-link-hover: var(--color-blue-600);
  --text-primary-hover: var(--color-neutral-1000);
  --text-secondary-hover: var(--color-neutral-700);
  --text-tertiary-hover: var(--color-neutral-500);
  --transparent-25: var(--color-transparent-black-25);
  --transparent-5: var(--color-transparent-black-5);
  --transparent-50: var(--color-transparent-black-50);
  --transparent-75: var(--color-transparent-black-75);
  --icon-outline: var(--border-primary);
}
body[data-theme=dark] {
  --background-primary: var(--color-neutral-900);
  --background-secondary: var(--color-neutral-800);
  --background-tertiary: var(--color-neutral-950);
  --background-button-active: var(--color-neutral-600);
  --background-button-default: var(--color-neutral-750);
  --background-button-hover: var(--color-neutral-700);
  --background-button-pill: var(--color-neutral-800);
  --background-input-default: var(--color-neutral-750);
  --background-input-hover: var(--color-neutral-700);
  --border-primary: var(--color-neutral-800);
  --border-secondary: var(--color-neutral-700);
  --element-icon-primary: var(--color-neutral-0);
  --text-primary: var(--color-neutral-0);
  --text-secondary: var(--color-neutral-300);
  --text-tertiary: var(--color-neutral-400);
  --text-link-hover: var(--color-blue-400);
  --text-primary-hover: var(--color-neutral-0);
  --text-secondary-hover: var(--color-neutral-200);
  --text-tertiary-hover: var(--color-neutral-300);
  --transparent-25: var(--color-transparent-white-25);
  --transparent-5: var(--color-transparent-white-5);
  --transparent-50: var(--color-transparent-white-50);
  --transparent-75: var(--color-transparent-white-75);
}
body img {
  color: var(--background-tertiary);
}
body img[data-theme] {
  display: none;
}
body[data-theme=light] img[data-theme=light] {
  display: initial;
}
body[data-theme=dark] img[data-theme=dark] {
  display: initial;
}
body[data-project=pornsitesall][data-theme=light] {
  --background-button-brand-primary-active: var(--color-pink-700);
  --background-button-brand-primary-default: var(--color-pink-500);
  --background-button-brand-primary-hover: var(--color-pink-600);
  --background-button-brand-secondary-active: var(--color-pink-200);
  --background-button-brand-secondary-default: var(--color-pink-50);
  --background-button-brand-secondary-hover: var(--color-pink-100);
  --background-button-brand-tertiary-active: var(--color-blue-200);
  --background-button-brand-tertiary-default: var(--color-blue-50);
  --background-button-brand-tertiary-hover: var(--color-blue-100);
  --border-brand-focus: var(--color-pink-300);
  --element-link: var(--color-blue-500);
  --element-brand-icon-primary: var(--color-pink-500);
  --text-link: var(--color-blue-500);
  --text-brand-primary: var(--color-pink-500);
}
body[data-project=pornsitesall][data-theme=dark] {
  --background-button-brand-primary-active: var(--color-pink-350);
  --background-button-brand-primary-default: var(--color-pink-500);
  --background-button-brand-primary-hover: var(--color-pink-400);
  --background-button-brand-secondary-active: var(--color-pink-600);
  --background-button-brand-secondary-default: var(--color-pink-750);
  --background-button-brand-secondary-hover: var(--color-pink-700);
  --background-button-brand-tertiary-active: var(--color-blue-600);
  --background-button-brand-tertiary-default: var(--color-blue-750);
  --background-button-brand-tertiary-hover: var(--color-blue-700);
  --border-brand-focus: var(--color-pink-700);
  --element-link: var(--color-blue-500);
  --element-brand-icon-primary: var(--color-pink-500);
  --text-link: var(--color-blue-400);
  --text-brand-primary: var(--color-pink-400);
}
body[data-project=xfree][data-theme=dark] {
  --background-button-brand-primary-default: #7f1fe0;
  --background-button-brand-primary-hover: #8c35e3;
}
body[data-project=xfree][data-theme=light] {
  --background-button-brand-primary-default: #7f1fe0;
  --background-button-brand-primary-hover: #731cca;
}

/* ==========================================================================
   OB: ANIMATIONS
   ========================================================================== */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ==========================================================================
   OB: LAYOUT
   ========================================================================== */
body {
  --header-height: 60px;
  --max-content-width: 1120px;
  --side-gap: var(--spacing-200);
  --default-height: 44px;
}
body[data-project=pornsitesall] {
  --max-content-width: 1800px;
  --max-cl-landing-width: 1400px;
}
@media (min-width: 600px) {
  body {
    --side-gap: 3.6vw;
  }
}
@media (min-width: 1040px) {
  body {
    --side-gap: 80px;
  }
}

.l-main {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: grid;
  grid-template-rows: calc(var(--header-height) + env(safe-area-inset-top)) auto minmax(0, 1fr) auto;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-template-areas: "header     header      header" "hero       hero        hero" ".		    main        ." ".          footer      .";
  grid-column-gap: var(--side-gap);
}
.l-main--airy .page-content__data {
  padding: var(--spacing-300) 0;
}
@media (min-width: 600px) {
  .l-main--airy .page-content__data.p-t--t--400 {
    padding-top: var(--spacing-400);
  }
}
@media (min-width: 1040px) {
  .l-main--airy .page-content__data {
    padding-bottom: var(--spacing-800);
  }
}
@media (max-width: 599px) {
  .l-main--airy {
    --side-gap: var(--spacing-300);
  }
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}

/* ==========================================================================
   OB: CONTENT LAYOUT
   ========================================================================== */
body[data-project=pornsitesall] {
  --l-content-mw: 600px;
  --l-aside-mw: 352px;
}
body[data-project=pornsitesall].page--category {
  --l-scroller-gap: var(--spacing-200);
}

.page-content {
  grid-area: main;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-content, .page-content__data, .page-content__empty {
  width: 100%;
}
.page-content--centered, .page-content__data--centered, .page-content__empty--centered {
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.page-content__data {
  padding: var(--spacing-400) 0;
  min-height: 100%;
}
@media (min-width: 600px) {
  .page-content__data {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }
}
.page-content__data--no-padding {
  padding: 0;
}
@media (max-width: 599px) {
  .page-content__data--no-padding--m {
    padding: 0;
  }
}

.cl-landing {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "before" "top" "aside" "left";
  grid-gap: var(--spacing-400);
  width: 100%;
  max-width: var(--max-cl-landing-width, var(--max-content-width));
  margin: 0 auto;
}
@media (min-width: 1040px) {
  .cl-landing {
    grid-template-areas: "before before" "top    top" "left   aside";
    grid-template-columns: minmax(0, 1fr) minmax(0, 400px);
    grid-column-gap: var(--spacing-800);
  }
}
.cl-landing .topics {
  grid-area: before;
}
.cl-landing .section--top {
  grid-area: top;
}
.cl-landing .section--left {
  grid-area: left;
}
.cl-landing .section--aside {
  grid-area: aside;
}

.cl-classic {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: "aside" "content";
  gap: var(--spacing-400);
}
@media (max-width: 1039px) {
  .cl-classic.cl-classic--m-aside-off {
    grid-template-areas: "content";
    grid-template-rows: minmax(0, 1fr);
  }
  .cl-classic.cl-classic--m-aside-off .section--aside {
    display: none;
  }
}
@media (min-width: 1040px) {
  .cl-classic {
    grid-template-areas: "aside content";
    grid-template-columns: 300px minmax(0, 1fr);
    grid-template-rows: auto;
    gap: var(--spacing-800);
  }
}
.cl-classic .section--aside {
  grid-area: aside;
}
.cl-classic .section--article {
  grid-area: content;
}

.cl-detail {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "content" "more";
  gap: var(--spacing-400);
}
@media (min-width: 600px) {
  .cl-detail {
    grid-template-areas: "content" "more";
  }
}
@media (min-width: 1040px) {
  .cl-detail {
    grid-template-areas: "content aside" "more more";
    grid-template-columns: min(var(--l-content-mw, 650px), 50vw) var(--l-aside-mw, minmax(0, 1fr));
    grid-column-gap: 80px;
    justify-content: space-between;
  }
}
@media (min-width: 1040px) {
  .cl-detail--fluid {
    grid-template-columns: minmax(0, 1fr) var(--l-aside-mw, minmax(0, 1fr));
    grid-column-gap: var(--spacing-800);
  }
}
.cl-detail--centered {
  justify-content: center;
  grid-column-gap: var(--spacing-800);
}
.cl-detail .section--article, .cl-detail .section--content {
  grid-area: content;
}
.cl-detail .section--aside {
  grid-area: aside;
}
@media (max-width: 1039px) {
  .cl-detail .section--aside {
    display: none;
  }
}
.cl-detail .section--more {
  grid-area: more;
}

.cl-simple {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  gap: var(--spacing-400);
}

/* ==========================================================================
   OB: SECTION LAYOUT
   ========================================================================== */
.section--top {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--spacing-400);
}
.section--top::after {
  content: "";
  display: block;
  height: 2px;
  background: var(--border-secondary);
  margin: var(--spacing-400) 0;
  grid-column: 1/-1;
}
@media (min-width: 600px) {
  .section--top {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: flex-start;
  }
}
.section--side-less {
  margin: 0 calc(var(--side-gap) * -1);
}
.section--side-less__normalize {
  margin: 0 var(--side-gap);
}
@media (min-width: 600px) {
  .section--side-less {
    margin: 0;
  }
  .section--side-less__normalize {
    margin: 0;
  }
}

.sub-section--search {
  height: var(--default-height);
  display: flex;
  width: 100%;
  max-width: var(--limit-search-width, 640px);
  align-self: center;
  justify-content: center;
}

.grid {
  display: grid;
}
.grid--1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid--2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid--3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid--4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid--5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid--6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid--7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid--8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
@media (min-width: 600px) {
  .grid--t {
    display: grid;
  }
  .grid--t--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--t--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--t--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--t--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--t--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--t--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--t--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--t--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media (min-width: 1040px) {
  .grid--d {
    display: grid;
  }
  .grid--d--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--d--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--d--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--d--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--d--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--d--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--d--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--d--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .grid--m {
    display: grid;
  }
  .grid--m--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--m--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--m--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--m--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--m--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--m--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--m--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--m--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.flex,
.row,
.column {
  display: flex;
  flex-shrink: 1;
}

.row,
.column {
  list-style: none;
  min-width: 0;
}
.row--wrap,
.column--wrap {
  flex-wrap: wrap;
}

.row {
  align-items: center;
}
.row--center {
  justify-content: center;
}
.row--space-between {
  justify-content: space-between;
}
.row--space-evenly {
  justify-content: space-evenly;
}
.row--end {
  justify-content: end;
}
@media (min-width: 600px) {
  .row--t--start {
    justify-content: flex-start;
  }
  .row--t--center {
    justify-content: center;
  }
  .row--t--space-between {
    justify-content: space-between;
  }
  .row--t--space-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 600px) {
  .row .column--t {
    flex-direction: column;
  }
  .row .column--t--reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1040px) {
  .row .column--d {
    flex-direction: column;
  }
  .row .column--d--reverse {
    flex-direction: column-reverse;
  }
}

.column {
  flex-direction: column;
}
.column--reverse {
  flex-direction: column-reverse;
}
.column--fs {
  align-items: flex-start;
}
.column--center {
  align-items: center;
}
@media (min-width: 600px) {
  .column.row--t {
    flex-direction: row;
  }
  .column.row--t--reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1040px) {
  .column.row--d {
    flex-direction: row;
  }
  .column.row--d--reverse {
    flex-direction: row-reverse;
  }
}

.self--start {
  align-self: flex-start;
}

.shrink {
  flex-shrink: 1;
  min-width: 0;
}

.gsa {
  flex: 1 1 auto;
}

.no-shrink {
  flex-shrink: 0;
}

.no-grow {
  flex-grow: 0;
}

.fluid {
  width: 100%;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 31;
  height: 100%;
}
.header::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: var(--background-primary);
  border-bottom: 1px solid var(--border-secondary);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  z-index: -1;
}
.header.transparent::before {
  opacity: 0;
}
@media (min-width: 600px) {
  .header {
    padding: 0 var(--side-gap);
  }
}
.header-inner {
  max-width: var(--max-content-width);
  margin: 0 auto;
  display: grid;
  grid-template-rows: 100%;
  gap: var(--spacing-200);
  height: 100%;
  grid-template-areas: "h-logo h-actions-right";
  grid-template-columns: auto minmax(0, 1fr);
}
@media (max-width: 413px) {
  .header-inner .header__part--center {
    display: none;
  }
}
.header-inner--layout-pornsitesall {
  grid-template-areas: "h-actions-left h-logo h-actions-right";
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}
@media (min-width: 600px) {
  .header-inner {
    grid-template-areas: "h-logo h-center h-actions-right";
    grid-template-columns: 165px minmax(0, 1fr) 165px;
  }
}
@media (min-width: 1040px) {
  .header-inner {
    grid-template-columns: 240px minmax(0, 1fr) 240px;
    gap: var(--spacing-400);
    padding: 0;
  }
}
@media (min-width: 1040px) {
  .header-inner--layout-help {
    grid-template-columns: 300px minmax(0, 500px) auto;
    gap: var(--spacing-800);
  }
}
.header__part {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__part--logo {
  grid-area: h-logo;
}
.header__part--center {
  grid-area: h-center;
  justify-content: center;
}
@media (min-width: 1040px) {
  .header__part--center {
    justify-content: flex-start;
  }
}
.header__part--actions-right {
  grid-area: h-actions-right;
  justify-content: flex-end;
}
.header .logo {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-200);
  height: 100%;
}
@media (min-width: 600px) {
  .header .logo {
    padding: 0;
  }
}
.header .logo > img {
  height: 25px;
}
.header__navigation {
  display: flex;
  margin: 0 auto;
  gap: var(--spacing-100);
}
.header__navigation .button {
  background: var(--background-primary);
  color: var(--text-secondary);
}
.header__navigation .button:not(.active):hover {
  color: var(--text-secondary-hover);
}
.header__navigation .button.active {
  color: var(--text-primary);
}
.header__actions {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 var(--spacing-100);
}
@media (min-width: 600px) {
  .header__actions {
    gap: var(--spacing-200);
    padding-right: 0;
  }
}
.header__actions-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__actions-item svg path {
  stroke: var(--element-icon-primary);
}
.header__actions-item--action {
  padding: 0 var(--spacing-100);
  cursor: pointer;
}
.header__actions-item--no-padding {
  padding: 0;
}
.header__actions-item .dropdown-holder {
  margin-right: -4px;
}

.footer {
  text-align: center;
  background-color: var(--background-primary);
  color: var(--text-tertiary);
}
.footer-inner {
  max-width: var(--max-content-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-200) 0;
  gap: var(--spacing-200);
  border-top: 1px solid var(--border-secondary);
}
@media (min-width: 600px) {
  .footer-inner {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    height: 64px;
    align-items: center;
    padding: 0;
  }
}
.footer a {
  color: var(--text-tertiary);
  font-weight: 400;
}
.footer a:hover {
  color: var(--text-tertiary-hover);
}
.footer__links {
  order: 2;
}
.footer__links ul {
  display: flex;
  justify-content: center;
}
.footer__links li {
  list-style: none;
}
.footer__links li:not(:last-child)::after {
  content: "•";
  flex-shrink: 0;
  margin: 0 var(--spacing-200);
  color: var(--text-tertiary);
}
.footer__copyright {
  order: 3;
  text-align: center;
  color: var(--text-tertiary);
}
.footer__copyright span {
  cursor: pointer;
}
@media (min-width: 600px) {
  .footer__copyright {
    text-align: end;
  }
}
.footer__socials {
  order: 1;
  display: flex;
  justify-content: center;
}
.footer__socials path {
  fill: var(--element-icon-secondary);
}
@media (min-width: 600px) {
  .footer__socials {
    justify-content: flex-start;
  }
}
.footer__social {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.footer__social span {
  margin-left: var(--spacing-100);
}
.footer__social:not(:last-child)::after {
  content: "•";
  flex-shrink: 0;
  margin: 0 var(--spacing-200);
  color: var(--text-tertiary);
  font-weight: 500;
}
.footer__languages {
  order: 4;
}

.blog-item {
  display: grid;
  grid-template-areas: "preview" "content" "title";
  gap: var(--spacing-200);
}
@media (min-width: 600px) {
  .blog-item {
    grid-template-areas: "preview" "title" "content";
  }
}
@media (min-width: 600px) {
  .blog-item--main {
    gap: var(--spacing-300);
  }
}
.blog-item__preview {
  grid-area: preview;
}
.blog-item__title {
  grid-area: title;
}
@media (min-width: 600px) {
  .blog-item__title {
    order: unset;
  }
}
.blog-item__content {
  grid-area: content;
  display: flex;
  flex-direction: column;
}
.blog-item__badges {
  position: absolute;
  bottom: var(--spacing-200);
  left: var(--spacing-200);
}
.blog-item__description {
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-secondary);
}
.blog-item__description:hover {
  color: var(--text-secondary-hover);
}
.blog-item__read-more {
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  display: none;
}
@media (min-width: 600px) {
  .blog-item__read-more {
    display: inline-flex;
    gap: var(--spacing-75);
  }
}
.blog-item__read-more a {
  display: inline-flex;
  align-items: center;
  color: var(--text-primary);
  transition: color 0.2s;
}
.blog-item__read-more a path {
  fill: var(--text-primary);
}
.blog-item__read-more a:hover {
  color: var(--text-primary-hover);
}
.blog-item__read-more a:hover path {
  fill: var(--text-primary-hover);
}
.blog-item--in-row .blog-item__content {
  row-gap: var(--spacing-200);
}
.blog-item--in-row .blog-item__description {
  --clamp-rows: 3;
}
@media (max-width: 599px) {
  .blog-item--in-row .blog-item__description {
    display: none;
  }
}
@media (min-width: 600px) {
  .blog-item--in-row {
    grid-template-areas: "title      title" "preview    content";
    grid-row-gap: var(--spacing-300);
    grid-column-gap: 0;
    grid-template-columns: min(49%, 364px) minmax(44%, 1fr);
    align-items: center;
  }
  .blog-item--in-row .blog-item__content {
    padding: 0 var(--spacing-400);
  }
}
.blog-item--listing {
  grid-template-areas: "preview" "title" "content";
  align-content: flex-start;
}
.blog-item--listing .blog-item__title, .blog-item--listing .blog-item__content {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blog-item--listing .blog-item__content {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.blog-item--recommended {
  width: 100%;
  flex: 0 0 auto;
  grid-template-areas: "preview" "title";
  --clamp-rows: 2;
}

.author {
  display: flex;
  align-items: center;
  gap: var(--spacing-150);
}
.author__avatar {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.author__avatar::before {
  content: "";
  background: var(--background-secondary);
}
.author__avatar::before, .author__avatar > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.author__timestamp, .author__reading-time {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-150);
}
.author__timestamp::before, .author__reading-time::before {
  content: "|";
  color: var(--transparent-25);
}

.badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  gap: 8px;
}

.badge {
  cursor: pointer;
  flex-shrink: 0;
}
.badge__inner {
  display: flex;
  align-items: center;
  gap: var(--spacing-75);
  height: 44px;
  padding: 0 var(--spacing-300);
  border-radius: var(--b-badge-border-radius, 6px);
  background-color: var(--background-button-default);
  font-weight: 500;
  font-size: 16px;
  color: var(--text-primary);
  text-transform: uppercase;
}
.badge__inner > img {
  height: auto;
  width: auto;
  max-height: 24px;
}
.badge__inner > svg path {
  stroke: var(--element-icon-primary);
}
.badge__inner:not(.badge__inner--colors-set):hover {
  background-color: var(--background-button-hover);
}
.badge__inner:focus-visible {
  outline: color-mix(in srgb, var(--state-focus-default) 25%, transparent) 2px solid;
  outline-offset: 2px;
}
.badge__inner--colors-set:hover {
  filter: brightness(0.9);
}

.banner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  overflow: clip;
  border-radius: var(--radius-150);
}
.banner__image, .banner__inner {
  position: absolute;
  left: 0;
  width: 100%;
}
.banner__image {
  top: 0;
  height: 100%;
  background: var(--background-secondary);
  position: relative;
  z-index: -1;
}
.banner__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner__inner {
  padding: var(--spacing-150);
  display: flex;
}
.banner__inner--top {
  top: 0;
  justify-content: space-between;
}
.banner__inner--bottom {
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.banner__icon {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-50);
}
.banner__title, .banner__description {
  color: var(--non-changing-light);
}
.banner__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.17px;
}

.xfree-banner {
  display: flex;
  padding: var(--spacing-300);
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-300);
  border-radius: var(--radius-150);
  background: #7E1FE0;
}
.xfree-banner__left {
  display: flex;
}
.xfree-banner__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacing-150);
}
.xfree-banner__right h4 {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}
.xfree-banner__right .button {
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  background: #171717;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-100);
  padding: 0 var(--spacing-300);
  height: var(--default-height);
  border-radius: var(--radius-75);
  background: var(--custom-color, var(--background-button-default));
  transition: transform 0.24s, background 0.24s;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
}
.button > svg {
  flex-shrink: 0;
}
.button > svg path[fill] {
  fill: var(--element-icon-primary);
}
.button > svg path[stroke] {
  stroke: var(--element-icon-primary);
}
@media (hover: hover) {
  .button:hover {
    background: var(--custom-color-hover, var(--background-button-hover));
  }
  .button:focus-visible {
    outline: color-mix(in srgb, var(--state-focus-default) 25%, transparent) 2px solid;
    outline-offset: 2px;
  }
  .button:not(.not-clickable):active {
    transform: scale(0.95);
    background: var(--background-button-active);
  }
}
.button.disabled {
  cursor: not-allowed;
  color: var(--text-secondary);
}
.button.disabled > svg path[fill] {
  fill: var(--element-icon-secondary);
}
.button.disabled > svg path[stroke] {
  stroke: var(--element-icon-secondary);
}
.button.not-clickable {
  cursor: default;
}
.button--small {
  height: 30px;
  padding: 0 var(--spacing-150);
  border-radius: var(--radius-1000);
  gap: var(--spacing-50);
}
.button--width {
  width: auto;
  max-width: 300px;
  flex-shrink: 1;
  flex-grow: 1;
}
.button--width--0 {
  max-width: var(--spacing-0);
}
.button--width--25 {
  max-width: var(--spacing-25);
}
.button--width--50 {
  max-width: var(--spacing-50);
}
.button--width--75 {
  max-width: var(--spacing-75);
}
.button--width--100 {
  max-width: var(--spacing-100);
}
.button--width--150 {
  max-width: var(--spacing-150);
}
.button--width--200 {
  max-width: var(--spacing-200);
}
.button--width--250 {
  max-width: var(--spacing-250);
}
.button--width--300 {
  max-width: var(--spacing-300);
}
.button--width--400 {
  max-width: var(--spacing-400);
}
.button--width--500 {
  max-width: var(--spacing-500);
}
.button--width--600 {
  max-width: var(--spacing-600);
}
.button--width--800 {
  max-width: var(--spacing-800);
}
.button--width--1000 {
  max-width: var(--spacing-1000);
}
.button--width--1600 {
  max-width: var(--spacing-1600);
}
.button--full-width {
  width: 100%;
}
.button--height--0 {
  height: var(--spacing-0);
}
.button--height--25 {
  height: var(--spacing-25);
}
.button--height--50 {
  height: var(--spacing-50);
}
.button--height--75 {
  height: var(--spacing-75);
}
.button--height--100 {
  height: var(--spacing-100);
}
.button--height--150 {
  height: var(--spacing-150);
}
.button--height--200 {
  height: var(--spacing-200);
}
.button--height--250 {
  height: var(--spacing-250);
}
.button--height--300 {
  height: var(--spacing-300);
}
.button--height--400 {
  height: var(--spacing-400);
}
.button--height--500 {
  height: var(--spacing-500);
}
.button--height--600 {
  height: var(--spacing-600);
}
.button--height--800 {
  height: var(--spacing-800);
}
.button--height--1000 {
  height: var(--spacing-1000);
}
.button--height--1600 {
  height: var(--spacing-1600);
}
.button--no-padding {
  padding: 0;
}
.button--justify-start {
  justify-content: flex-start;
}
.button--square {
  width: var(--default-height);
  height: var(--default-height);
  padding: 0;
}
.button--square:is(.button--small) {
  width: 30px;
  height: 30px;
}
.button--rounded {
  border-radius: var(--radius-1000);
}
.button--curved {
  border-radius: var(--radius-75);
}
.button--sharp {
  border-radius: var(--radius-0);
}
.button--brand--primary {
  background: var(--background-button-brand-primary-default, var(--background-button-default));
  color: var(--non-changing-light);
}
.button--brand--primary > svg path[fill] {
  fill: var(--non-changing-light);
}
.button--brand--primary > svg path[stroke] {
  stroke: var(--non-changing-light);
}
@media (hover: hover) {
  .button--brand--primary:hover {
    background: var(--background-button-brand-primary-hover, var(--background-button-hover));
    color: var(--non-changing-light);
  }
  .button--brand--primary:hover > svg path[fill] {
    fill: var(--non-changing-light);
  }
  .button--brand--primary:hover > svg path[stroke] {
    stroke: var(--non-changing-light);
  }
  .button--brand--primary:focus-visible {
    outline-color: color-mix(in srgb, var(--background-button-brand-primary-default, var(--background-button-default)) 25%, transparent);
  }
  .button--brand--primary:active {
    background: var(--background-button-brand-primary-active, var(--background-button-default)) !important;
  }
}
.button--brand--secondary {
  background: var(--background-button-brand-secondary-default, var(--background-button-default));
  color: var(--text-brand-primary);
}
.button--brand--secondary > svg path[fill] {
  fill: var(--element-brand-icon-primary);
}
.button--brand--secondary > svg path[stroke] {
  stroke: var(--element-brand-icon-primary);
}
@media (hover: hover) {
  .button--brand--secondary:hover {
    background: var(--background-button-brand-secondary-hover, var(--background-button-hover));
    color: var(--text-brand-primary);
  }
  .button--brand--secondary:hover > svg path[fill] {
    fill: var(--element-brand-icon-primary);
  }
  .button--brand--secondary:hover > svg path[stroke] {
    stroke: var(--element-brand-icon-primary);
  }
  .button--brand--secondary:focus-visible {
    outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
  }
  .button--brand--secondary:active {
    background: var(--background-button-brand-secondary-active, var(--background-button-default)) !important;
  }
}
.button--brand--tertiary {
  background: var(--background-button-brand-tertiary-default, var(--background-button-default));
  color: var(--text-link);
}
.button--brand--tertiary > svg path[fill] {
  fill: var(--element-link);
}
.button--brand--tertiary > svg path[stroke] {
  stroke: var(--element-link);
}
@media (hover: hover) {
  .button--brand--tertiary:hover {
    background: var(--background-button-brand-tertiary-hover, var(--background-button-hover));
    color: var(--text-link);
  }
  .button--brand--tertiary:hover > svg path[fill] {
    fill: var(--element-link);
  }
  .button--brand--tertiary:hover > svg path[stroke] {
    stroke: var(--element-link);
  }
  .button--brand--tertiary:focus-visible {
    outline-color: color-mix(in srgb, var(--background-button-brand-tertiary-hover, var(--background-button-hover)) 25%, transparent);
  }
  .button--brand--tertiary:active {
    background: var(--background-button-brand-tertiary-active, var(--background-button-default)) !important;
  }
}
.button--semi-transparent {
  background: var(--transparent-75);
  color: var(--non-changing-light);
}
.button--semi-transparent > svg path[fill] {
  fill: var(--non-changing-light);
}
.button--semi-transparent > svg path[stroke] {
  stroke: var(--non-changing-light);
}
@media (hover: hover) {
  .button--semi-transparent:hover {
    background: var(--transparent-50);
    color: var(--non-changing-light);
  }
  .button--semi-transparent:hover > svg path[fill] {
    fill: var(--non-changing-light);
  }
  .button--semi-transparent:hover > svg path[stroke] {
    stroke: var(--non-changing-light);
  }
  .button--semi-transparent:focus-visible {
    outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
  }
  .button--semi-transparent:active {
    background: var(--transparent-25) !important;
  }
}
.button--semi-transparent-dark {
  background: var(--color-transparent-black-75);
  color: var(--non-changing-light);
}
.button--semi-transparent-dark > svg path[fill] {
  fill: var(--non-changing-light);
}
.button--semi-transparent-dark > svg path[stroke] {
  stroke: var(--non-changing-light);
}
@media (hover: hover) {
  .button--semi-transparent-dark:hover {
    background: var(--color-transparent-black-50);
    color: var(--non-changing-light);
  }
  .button--semi-transparent-dark:hover > svg path[fill] {
    fill: var(--non-changing-light);
  }
  .button--semi-transparent-dark:hover > svg path[stroke] {
    stroke: var(--non-changing-light);
  }
  .button--semi-transparent-dark:focus-visible {
    outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
  }
  .button--semi-transparent-dark:active {
    background: var(--color-transparent-black-25) !important;
  }
}
.button--transparent {
  background: transparent;
}
.button--transparent-only, .button--transparent-only:hover {
  background: transparent;
}
@media (hover: hover) {
  .button--transparent-only:focus-visible, .button--transparent-only:hover:focus-visible {
    outline: none;
  }
}
.button--outline {
  border: 1px solid var(--background-button-default);
  background: transparent;
}
.button--outline-thick {
  box-shadow: 0 0 0 4px var(--background-primary);
}
.button--icon--brand svg path[stroke] {
  stroke: var(--element-brand-icon-primary);
}
.button--icon--brand svg path[fill] {
  fill: var(--element-brand-icon-primary);
}
.button--ellipsis {
  max-width: 100%;
}
.button--ellipsis > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-25);
  height: 20px;
  padding: 0 var(--spacing-75);
  margin: var(--spacing-50) var(--spacing-25);
  background: var(--custom-color, var(--transparent-50));
  color: var(--non-changing-light);
  border-radius: var(--radius-25);
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
}
.label--c-green {
  background: var(--color-green-300);
  color: var(--state-success-active);
}

.link-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100%;
}
.link-nav--l {
  max-width: 650px;
  width: 100%;
}
.link-nav--l .link-nav-cat {
  height: 72px;
}
.link-nav--aside {
  padding: 0;
}
.link-nav--enhanced .link-nav-cat {
  cursor: pointer;
  border-radius: var(--radius-75);
  border: 1px solid var(--border-secondary);
}
.link-nav--enhanced .link-nav-sub {
  padding: 0 var(--spacing-200);
  max-height: 0;
}
.link-nav--enhanced .link-nav-sub.opened {
  max-height: 96px;
}
.link-nav--enhanced .link-nav-sub__item:first-child {
  margin-top: var(--spacing-150);
}
.link-nav--enhanced .link-nav-sub__item:last-child {
  margin-bottom: var(--spacing-200);
}
@media (max-width: 599px) {
  .link-nav--enhanced .link-nav__item {
    border-color: transparent;
  }
}
.link-nav--enhanced.opened .chevron {
  transform: rotate(-90deg);
}
.link-nav__item {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-primary);
}
.link-nav__item:not(:first-child) {
  transition: border-top-color 0.2s;
  border-top: 1px solid var(--border-secondary);
}
.link-nav__item:hover, .link-nav__item:focus {
  border-color: transparent;
}
.link-nav__item:hover .link-nav-cat, .link-nav__item:focus .link-nav-cat {
  background: var(--background-secondary);
}
.link-nav__item:hover + .link-nav__item, .link-nav__item:focus + .link-nav__item {
  border-top-color: transparent;
}
.link-nav__item > button {
  font-weight: 500;
  color: var(--text-primary);
}
.link-nav__item > a {
  color: var(--text-primary);
}
.link-nav__item img {
  width: 16px;
}
.link-nav__item .chevron {
  width: 16px;
  height: 16px;
  margin-left: auto;
  transition: transform 0.2s;
}
.link-nav-cat {
  display: flex;
  align-items: center;
  height: var(--default-height);
  gap: var(--spacing-150);
  padding: 0 var(--spacing-200);
  width: 100%;
  text-align: left;
  border-radius: var(--radius-75);
  transition: background-color 0.3s;
}
.link-nav-cat path {
  fill: var(--text-primary);
}
.link-nav-sub {
  max-height: 0;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: max-height, opacity;
  transition-timing-function: ease-out;
  pointer-events: none;
  list-style: none;
  width: 100%;
  padding-left: 46px;
  padding-right: var(--spacing-200);
  will-change: max-height;
}
.link-nav-sub.opened {
  display: flex;
  flex-direction: column;
  opacity: 1;
  pointer-events: unset;
}
.link-nav-sub.opened .link-nav-sub__item {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(1) {
  transition-delay: 0.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(2) {
  transition-delay: 0.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(3) {
  transition-delay: 0.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(4) {
  transition-delay: 0.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(5) {
  transition-delay: 0.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(6) {
  transition-delay: 0.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(7) {
  transition-delay: 0.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(8) {
  transition-delay: 0.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(9) {
  transition-delay: 0.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(10) {
  transition-delay: 1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(11) {
  transition-delay: 1.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(12) {
  transition-delay: 1.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(13) {
  transition-delay: 1.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(14) {
  transition-delay: 1.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(15) {
  transition-delay: 1.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(16) {
  transition-delay: 1.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(17) {
  transition-delay: 1.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(18) {
  transition-delay: 1.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(19) {
  transition-delay: 1.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(20) {
  transition-delay: 2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(21) {
  transition-delay: 2.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(22) {
  transition-delay: 2.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(23) {
  transition-delay: 2.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(24) {
  transition-delay: 2.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(25) {
  transition-delay: 2.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(26) {
  transition-delay: 2.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(27) {
  transition-delay: 2.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(28) {
  transition-delay: 2.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(29) {
  transition-delay: 2.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(30) {
  transition-delay: 3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(31) {
  transition-delay: 3.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(32) {
  transition-delay: 3.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(33) {
  transition-delay: 3.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(34) {
  transition-delay: 3.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(35) {
  transition-delay: 3.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(36) {
  transition-delay: 3.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(37) {
  transition-delay: 3.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(38) {
  transition-delay: 3.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(39) {
  transition-delay: 3.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(40) {
  transition-delay: 4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(41) {
  transition-delay: 4.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(42) {
  transition-delay: 4.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(43) {
  transition-delay: 4.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(44) {
  transition-delay: 4.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(45) {
  transition-delay: 4.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(46) {
  transition-delay: 4.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(47) {
  transition-delay: 4.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(48) {
  transition-delay: 4.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(49) {
  transition-delay: 4.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(50) {
  transition-delay: 5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(51) {
  transition-delay: 5.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(52) {
  transition-delay: 5.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(53) {
  transition-delay: 5.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(54) {
  transition-delay: 5.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(55) {
  transition-delay: 5.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(56) {
  transition-delay: 5.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(57) {
  transition-delay: 5.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(58) {
  transition-delay: 5.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(59) {
  transition-delay: 5.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(60) {
  transition-delay: 6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(61) {
  transition-delay: 6.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(62) {
  transition-delay: 6.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(63) {
  transition-delay: 6.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(64) {
  transition-delay: 6.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(65) {
  transition-delay: 6.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(66) {
  transition-delay: 6.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(67) {
  transition-delay: 6.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(68) {
  transition-delay: 6.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(69) {
  transition-delay: 6.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(70) {
  transition-delay: 7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(71) {
  transition-delay: 7.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(72) {
  transition-delay: 7.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(73) {
  transition-delay: 7.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(74) {
  transition-delay: 7.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(75) {
  transition-delay: 7.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(76) {
  transition-delay: 7.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(77) {
  transition-delay: 7.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(78) {
  transition-delay: 7.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(79) {
  transition-delay: 7.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(80) {
  transition-delay: 8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(81) {
  transition-delay: 8.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(82) {
  transition-delay: 8.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(83) {
  transition-delay: 8.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(84) {
  transition-delay: 8.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(85) {
  transition-delay: 8.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(86) {
  transition-delay: 8.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(87) {
  transition-delay: 8.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(88) {
  transition-delay: 8.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(89) {
  transition-delay: 8.9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(90) {
  transition-delay: 9s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(91) {
  transition-delay: 9.1s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(92) {
  transition-delay: 9.2s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(93) {
  transition-delay: 9.3s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(94) {
  transition-delay: 9.4s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(95) {
  transition-delay: 9.5s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(96) {
  transition-delay: 9.6s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(97) {
  transition-delay: 9.7s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(98) {
  transition-delay: 9.8s;
}
.link-nav-sub.opened .link-nav-sub__item:nth-child(99) {
  transition-delay: 9.9s;
}
.link-nav-sub__item {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  position: relative;
  display: flex;
  transform: translateY(-20px);
  opacity: 0;
  transition: transform 0.2s, opacity 0.1s;
}
.link-nav-sub__item:nth-child(1) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(2) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(3) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(4) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(5) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(6) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(7) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(8) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(9) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(10) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(11) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(12) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(13) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(14) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(15) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(16) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(17) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(18) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(19) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(20) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(21) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(22) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(23) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(24) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(25) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(26) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(27) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(28) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(29) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(30) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(31) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(32) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(33) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(34) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(35) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(36) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(37) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(38) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(39) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(40) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(41) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(42) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(43) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(44) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(45) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(46) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(47) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(48) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(49) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(50) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(51) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(52) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(53) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(54) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(55) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(56) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(57) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(58) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(59) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(60) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(61) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(62) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(63) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(64) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(65) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(66) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(67) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(68) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(69) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(70) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(71) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(72) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(73) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(74) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(75) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(76) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(77) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(78) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(79) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(80) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(81) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(82) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(83) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(84) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(85) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(86) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(87) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(88) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(89) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(90) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(91) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(92) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(93) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(94) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(95) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(96) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(97) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(98) {
  transition-delay: 0s;
}
.link-nav-sub__item:nth-child(99) {
  transition-delay: 0s;
}
.link-nav-sub__item:first-child {
  margin-top: calc(var(--spacing-75) * -1);
}
.link-nav-sub__item:last-child {
  margin-bottom: var(--spacing-300);
}
.link-nav-sub__item a {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-secondary);
  font-weight: 400;
  padding: var(--spacing-75) 0;
}
.link-nav-sub__item a:hover {
  text-decoration: underline;
}
.link-nav-sub__item a:first-letter {
  text-transform: capitalize;
}
.link-nav-sub__item.active a {
  color: var(--text-link);
}
.link-nav-sub__item.active a:hover {
  color: var(--text-link-hover);
  text-decoration: none;
}

.quick-links__nav {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--spacing-150);
}
@media (min-width: 1040px) {
  .quick-links__nav {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: var(--spacing-600);
    row-gap: var(--spacing-200);
  }
}
.quick-links__link {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06px;
  color: var(--text-brand-primary);
}
.quick-links__link:hover {
  color: var(--text-brand-primary);
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: calc(var(--spacing-200) * -1);
}
@media (min-width: 1040px) {
  .list {
    font-size: 20px;
    line-height: 1.4;
  }
}
.list li {
  display: flex;
  padding: var(--spacing-200) var(--spacing-150);
  border-radius: 6px;
}
.list li:not(:first-child) {
  border-top: 1px solid var(--border-secondary);
}
.list li:hover, .list li:focus {
  border-top-color: transparent;
  background: var(--background-secondary);
}
.list li:hover + li, .list li:focus + li {
  border-top-color: transparent;
}
.list a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: var(--spacing-150);
  padding: var(--spacing-150) 0;
  color: var(--text-primary);
  transition: color 0.2s;
}
.list a svg {
  flex-shrink: 0;
}
.list a path {
  fill: var(--text-link);
}

.preview {
  position: relative;
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 1/1) {
  .preview {
    height: 0;
    padding-bottom: 56.25%;
  }
}
.preview__thumbnail {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.preview__thumbnail::before {
  content: "";
  background: var(--background-secondary);
  border: 1px solid var(--border-secondary);
}
.preview__thumbnail::before,
.preview__thumbnail img,
.preview__thumbnail video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.preview--vertical {
  aspect-ratio: auto;
}
.preview--vertical .preview__thumbnail {
  height: auto;
}
.preview--vertical img,
.preview--vertical video {
  position: static;
  width: 100%;
  height: auto;
}
.preview__badges {
  position: absolute;
  bottom: var(--spacing-200);
  left: var(--spacing-200);
}

.pagination .button--square {
  width: 40px;
  height: 40px;
}

.scroller {
  position: relative;
}

.scroll-area {
  --scroller-items: 1;
  --scroller-next-item: 33px;
  --scroller-gap: var(--l-scroller-gap, var(--spacing-200));
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--scroller-gap);
  padding: 0 var(--side-gap);
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll-area--snap {
  scroll-snap-type: x mandatory;
  scroll-padding: var(--side-gap);
}
.scroll-area--snap > * {
  scroll-snap-align: center;
}
@media (min-width: 600px) {
  .scroll-area--snap {
    scroll-padding: 0;
  }
  .scroll-area--snap > * {
    scroll-snap-align: start;
  }
}
.scroll-area::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background-color: transparent;
}
.scroll-area::-webkit-scrollbar-thumb {
  display: none !important;
  background-color: transparent;
}
.scroll-area::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll-area:not(.scroll-area--custom) > * {
  width: calc((100% + var(--side-gap) - var(--scroller-gap) * var(--scroller-items) - var(--scroller-next-item)) / var(--scroller-items));
  flex-shrink: 0;
}
@media (min-width: 600px) {
  .scroll-area {
    --scroller-items: 2;
    --scroller-next-item: 0px;
    padding: 0;
  }
  .scroll-area:not(.scroll-area--custom) > * {
    width: calc((100% - var(--scroller-gap) * (var(--scroller-items) - 1) - var(--scroller-next-item)) / var(--scroller-items));
  }
}
@media (min-width: 1040px) {
  .scroll-area {
    --scroller-items: 3;
  }
}
@media (min-width: 1600px) {
  .scroll-area {
    --scroller-items: 4;
    --scroller-gap: var(--spacing-400);
  }
}
@media (min-width: 1040px) {
  .scroll-area--hero {
    --scroller-items: 2;
  }
}
@media (min-width: 1600px) {
  .scroll-area--hero {
    --scroller-items: 3;
    --scroller-gap: var(--spacing-400);
  }
}
.scroll-area--hero + .category__controls button {
  top: 50%;
  height: 100%;
}

.vertical .scroll-area {
  --scroller-items: 1.5;
  --scroller-next-item: 0;
}
.vertical .scroll-area:not(.scroll-area--custom) > * {
  width: calc((100% + var(--side-gap) - var(--scroller-gap)) / var(--scroller-items));
}
@media (min-width: 440px) {
  .vertical .scroll-area {
    --scroller-items: 2.25;
  }
  .vertical .scroll-area:not(.scroll-area--custom) > * {
    width: calc((100% - var(--scroller-gap) * (var(--scroller-items) - 1.25)) / var(--scroller-items));
  }
}
@media (min-width: 600px) {
  .vertical .scroll-area {
    --scroller-items: 3;
    padding: 0;
  }
  .vertical .scroll-area:not(.scroll-area--custom) > * {
    width: calc((100% - var(--scroller-gap) * (var(--scroller-items) - 1)) / var(--scroller-items));
  }
}
@media (min-width: 1040px) {
  .vertical .scroll-area {
    --scroller-items: 4;
  }
}
@media (min-width: 1400px) {
  .vertical .scroll-area {
    --scroller-items: 5;
    --scroller-gap: var(--spacing-400);
  }
}

.scroller--fade-sides::before, .scroller--fade-sides::after {
  content: "";
  display: block;
  width: var(--side-gap);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
}
.scroller--fade-sides::before {
  left: 0;
  background: linear-gradient(to right, var(--background-primary) 0%, rgba(255, 255, 255, 0) 100%);
}
.scroller--fade-sides::after {
  right: 0;
  background: linear-gradient(to left, var(--background-primary) 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 1039px) {
  .scroller--fade-sides--d-only::before, .scroller--fade-sides--d-only::after {
    display: none;
  }
}
@media (min-width: 1040px) {
  .scroller--d--grid {
    margin: 0;
  }
  .scroller--d--grid .scroll-area {
    overflow-x: unset;
    overflow-y: unset;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .scroller--d--grid .scroll-area > * {
    width: 100%;
  }
  .scroller--d--cols {
    margin: 0;
  }
  .scroller--d--cols .scroll-area {
    overflow-x: unset;
    overflow-y: unset;
    padding: 0;
    display: grid;
  }
  .scroller--d--cols .scroll-area > * {
    width: 100%;
  }
  .scroller--d--cols--2 .scroll-area {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.scroller--avatars .scroll-area > * {
  max-width: 156px;
}
@media (min-width: 1040px) {
  .scroller--avatars .scroll-area {
    max-width: 360px;
  }
}
@media (min-width: 1040px) {
  .scroller--twitter .scroll-area {
    flex-flow: column nowrap;
  }
  .scroller--twitter .scroll-area > .twitter-item:not(:first-child) {
    margin-top: 30px;
  }
}

.search-results {
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  font-size: 16px;
  line-height: 1.5;
}
.search-results__title {
  text-transform: capitalize;
}
.search-results__results {
  margin: 0;
}
.search-results__label {
  color: var(--text-primary);
  font-size: 16px;
  line-height: 1.5;
}
.search-results--no-results {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-200);
  flex-grow: 1;
  text-align: center;
}
@media (min-width: 1040px) {
  .search-results--no-results {
    max-width: 640px;
    aspect-ratio: 1/1;
    flex-grow: 0;
  }
}
.search-results--no-results .search-results__title {
  font-size: 16px;
  font-weight: 700;
}
.search-results--no-results .search-results__subtitle {
  color: var(--text-secondary);
}

.search-container {
  position: relative;
  display: flex;
  width: 100%;
  gap: var(--spacing-200);
}
.search {
  position: relative;
  display: flex;
  width: 100%;
  gap: var(--spacing-200);
  margin: 0;
}
.search-input {
  display: flex;
  align-items: center;
  gap: var(--spacing-75);
  height: var(--default-height);
  border-radius: var(--radius-75);
  background-color: var(--background-input-default);
  padding: 0 0 0 var(--spacing-200);
  width: 100%;
}
.search-input__icon {
  display: flex;
  width: var(--spacing-300);
  height: var(--spacing-300);
}
.search-input__icon path {
  stroke: var(--element-icon-primary);
}
.search-input > input {
  border: none;
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding: 0;
  color: var(--text-primary);
}
.search-input > input::placeholder {
  color: var(--text-tertiary);
  opacity: 1;
}
.search-input__status {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--spacing-200);
}
.search-input__status .status {
  display: flex;
}
.search-input__status .status--loader {
  animation: spin 750ms infinite;
}
.search-input__status .status--cross:hover {
  cursor: pointer;
}
.search-whisper {
  position: absolute;
  z-index: 10;
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  padding: var(--spacing-100);
  border-radius: 6px;
  box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
  max-height: 235px;
  overflow: hidden auto;
}
.search-whisper__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}
.search-whisper__list--no-results .text {
  white-space: normal;
}
.search-whisper li, .search-whisper__item {
  min-height: 48px;
  border-radius: 3px;
}
.search-whisper__item {
  padding: 0 var(--spacing-150);
  display: flex;
  align-items: center;
  gap: var(--spacing-150);
  font-size: 16px;
  color: var(--text-primary);
}
.search-whisper__item svg {
  flex-shrink: 0;
}
.search-whisper__item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-whisper__item:hover {
  background-color: var(--background-secondary);
  color: var(--text-primary);
}
.search-whisper li:focus .search__results__item {
  background-color: var(--border-secondary);
  color: var(--text-primary);
}

.HeroSlider:not(.tns-slider),
.CenteredSlider:not(.tns-slider),
.LeftSlider:not(.tns-slider),
.LeftSliderPlus:not(.tns-slider) {
  display: flex;
  flex-flow: row;
  overflow: hidden;
}
.HeroSlider:not(.tns-slider) > div,
.CenteredSlider:not(.tns-slider) > div,
.LeftSlider:not(.tns-slider) > div,
.LeftSliderPlus:not(.tns-slider) > div {
  flex-shrink: 0;
  width: 100%;
}

.HeroSlider:not(.tns-slider) {
  gap: var(--spacing-300);
  margin: 0 36px 21px;
}
@media (min-width: 600px) {
  .HeroSlider:not(.tns-slider) {
    margin: 0;
    gap: var(--spacing-200);
  }
  .HeroSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200)) / 2);
  }
}
@media (min-width: 1200px) {
  .HeroSlider:not(.tns-slider) {
    gap: var(--spacing-400);
  }
  .HeroSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-400) * 2) / 3);
  }
}

.CenteredSlider:not(.tns-slider) {
  gap: var(--spacing-300);
  margin: 0 36px 21px;
}
@media (min-width: 600px) {
  .CenteredSlider:not(.tns-slider) {
    margin: 0;
  }
  .CenteredSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-300)) / 2);
  }
}
@media (min-width: 1040px) {
  .CenteredSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-300) * 2) / 3);
  }
}
@media (min-width: 1600px) {
  .CenteredSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-300) * 3) / 4);
  }
}

.LeftSlider:not(.tns-slider) {
  gap: var(--spacing-200);
  margin: 0 0 21px var(--spacing-300);
}
.LeftSlider:not(.tns-slider) > div {
  width: calc(100% - var(--spacing-600));
}
@media (min-width: 600px) {
  .LeftSlider:not(.tns-slider) {
    margin: 0;
  }
  .LeftSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200)) / 2);
  }
}
@media (min-width: 1040px) {
  .LeftSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200) * 2) / 3);
  }
}
@media (min-width: 1600px) {
  .LeftSlider:not(.tns-slider) {
    gap: var(--spacing-400);
  }
  .LeftSlider:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-400) * 3) / 4);
  }
}

.LeftSliderPlus:not(.tns-slider) {
  gap: var(--spacing-200);
  margin: 0 0 21px var(--spacing-300);
}
.LeftSliderPlus:not(.tns-slider) > div {
  width: calc((100% - var(--spacing-200) - var(--spacing-300)) / 1.5);
}
@media (min-width: 440px) {
  .LeftSliderPlus:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200) * 2 - var(--spacing-300)) / 2.5);
  }
}
@media (min-width: 600px) {
  .LeftSliderPlus:not(.tns-slider) {
    margin: 0;
  }
  .LeftSliderPlus:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200) * 2) / 3);
  }
}
@media (min-width: 1040px) {
  .LeftSliderPlus:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-200) * 3) / 4);
  }
}
@media (min-width: 1400px) {
  .LeftSliderPlus:not(.tns-slider) {
    gap: var(--spacing-400);
  }
  .LeftSliderPlus:not(.tns-slider) > div {
    width: calc((100% - var(--spacing-400) * 4) / 5);
  }
}

@media (max-width: 599px) {
  .tns-inner:has(.LeftSlider) {
    margin: 0 24px !important;
  }
}

.tns-outer:has(.card--blog) + .category__controls button {
  top: calc((100% - 76px) / 2);
}

.tns-outer:has(.banner, .site-item--content-inside) + .category__controls button {
  top: 50%;
}

.socials {
  display: flex;
  align-items: center;
  margin: calc(var(--spacing-100) * -1);
}

.social {
  padding: var(--spacing-100);
  cursor: pointer;
}
.social__inner {
  display: flex;
  width: 32px;
  height: 32px;
}
.social__inner > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.static-aside__section {
  display: flex;
  flex-direction: column;
}
.static-aside__section:not(:last-child) {
  margin-bottom: 50px;
}
.static-aside__section__header {
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  text-align: left;
  color: var(--text-secondary);
  margin-bottom: 40px;
}

.aside-links ul {
  display: flex;
  flex-direction: column;
}
.aside-links li {
  list-style: none;
}
.aside-links li:not(:last-child) {
  margin-bottom: 24px;
}
.aside-links a {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: var(--text-secondary);
}
.aside-links a:hover {
  color: var(--text-secondary-hover);
}

.topics,
.topic {
  display: flex;
  align-items: center;
}

.topics {
  list-style: none;
  height: max-content;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
@media (min-width: 600px) {
  .topics {
    margin: -4px;
    padding: 4px;
  }
}
.topics::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background-color: transparent;
}
.topics::-webkit-scrollbar-thumb {
  display: none !important;
  background-color: transparent;
}
.topics::-webkit-scrollbar-track {
  background-color: transparent;
}
@media (min-width: 600px) {
  .topics--center::before, .topics--center::after {
    content: "";
    display: block;
    flex: 1 1 auto;
    height: 20px;
  }
  .topics--center::before {
    margin-right: calc(var(--spacing-100) * -1);
  }
  .topics--center::after {
    margin-left: calc(var(--spacing-100) * -1);
  }
}
.topics.section--side-less {
  margin-left: calc(var(--spacing-200) * -1);
  margin-right: calc(var(--spacing-200) * -1);
  padding-left: var(--spacing-200);
  padding-right: var(--spacing-200);
}

.topic {
  gap: var(--spacing-50);
  background: var(--background-button-default);
  color: var(--text-primary);
  white-space: nowrap;
  transition: background 0.3s;
  height: 30px;
  padding: 0 var(--spacing-150);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  flex-shrink: 0;
  border-radius: 6px;
}
@media (min-width: 600px) {
  .topic {
    height: 44px;
    padding: 0 var(--spacing-300);
  }
}
.topic > img {
  width: 22px;
}
.topic:hover {
  background: var(--background-button-hover);
}
.topic.active {
  background: var(--background-button-active);
}
.topic-holder {
  display: flex;
  align-items: center;
  height: 100%;
}

.l-main--airy .topics {
  margin-left: calc(var(--side-gap) * -1);
  margin-right: calc(var(--side-gap) * -1);
  padding-left: var(--side-gap);
  padding-right: var(--side-gap);
}

.vertical-banner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  color: var(--text-primary);
}
.vertical-banner:not(:last-child) {
  margin-bottom: var(--spacing-400);
}
.vertical-banner img,
.vertical-banner video {
  border-radius: 6px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  height: 21px;
  gap: var(--spacing-75);
  list-style: none;
  overflow: hidden;
  max-width: 100%;
}
.breadcrumbs-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-75);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.breadcrumbs-item:not(:first-child) {
  flex-shrink: 10;
}
.breadcrumbs-item:last-child {
  flex-shrink: 100;
}
.breadcrumbs-item:last-child > span {
  color: var(--text-primary);
}
.breadcrumbs-item > a, .breadcrumbs-item > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  color: var(--text-tertiary);
}
.breadcrumbs-item:first-child > a {
  display: flex;
}
.breadcrumbs-item:first-child > a svg path {
  stroke: var(--element-brand-icon-primary);
}
.breadcrumbs .separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
}
.breadcrumbs .separator path {
  fill: var(--element-icon-secondary);
}

.card {
  display: flex;
  position: relative;
  aspect-ratio: 1/1;
}
.card--hero {
  aspect-ratio: 16/9;
}
@media (min-width: 1040px) {
  .card--hero {
    gap: var(--spacing-400);
  }
}
.card--blog {
  aspect-ratio: unset;
}
@media (min-width: 600px) {
  .card--offer {
    aspect-ratio: 49/50;
  }
}
.card__thumbnail {
  position: relative;
  border-radius: var(--radius-150);
  overflow: hidden;
}
@media (max-width: 599px) {
  .card__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 600px) {
  .card__thumbnail {
    aspect-ratio: 16/9;
  }
}
.card__thumbnail > img, .card__thumbnail > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-150);
}
.card__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-150);
  padding: var(--spacing-150);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 var(--radius-150) var(--radius-150);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.card__info__left, .card__info__right {
  display: flex;
  align-items: center;
  gap: var(--spacing-100);
}
.card__info__left {
  overflow: hidden;
}
.card__info__right {
  flex-shrink: 0;
}
.card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-button-default);
  width: var(--spacing-400);
  height: var(--spacing-400);
  border-radius: var(--radius-75);
  flex-shrink: 0;
}
.card__icon > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-75);
}
.card__text {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card__title, .card__subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--non-changing-light);
  font-size: 12px;
  letter-spacing: 0.17px;
}
.card__title {
  font-weight: 500;
  line-height: 18px;
}
.card__subtitle {
  line-height: 17px;
}
.card__button {
  background: var(--color-transparent-black-75);
  color: #fff;
}
.card__button:hover {
  background: var(--color-transparent-black-75);
  color: #fff;
}

.tns-outer__track {
  line-height: 0;
}
.tns-outer .tns-nav {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: unset;
  gap: var(--spacing-100);
  margin-top: var(--spacing-200);
  overflow: hidden;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 600px) {
  .tns-outer .tns-nav {
    margin-top: var(--spacing-400);
  }
}
.tns-outer [aria-controls] {
  flex: 0 0 5px;
  background: var(--transparent-25);
  margin: 0;
  width: 5px;
  height: 5px;
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: var(--radius-1000);
}
.tns-outer [aria-controls].tns-nav-active {
  flex: 0 0 20px;
  background: var(--color-pink-500);
}

.category__header {
  height: 52px;
  padding: 0 var(--spacing-150);
  border-radius: var(--radius-75);
  background: var(--custom-category-color, var(--background-button-default));
  z-index: 1;
}
@media (min-width: 600px) {
  .category__header {
    height: 56px;
    padding: 0 var(--spacing-200);
  }
}
.category__header__icon {
  width: 24px;
}
.category__header__text,
.category__header .h2,
.category__header h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.category.header-transparent .category__header {
  height: auto;
  background: transparent;
}
.category__description {
  padding: 0 var(--spacing-150);
  --clamp-rows: 2;
}
.category-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--spacing-300);
  padding-bottom: var(--spacing-200);
}
@media (min-width: 600px) {
  .category-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1040px) {
  .category-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: var(--spacing-400);
  }
}
@media (min-width: 1200px) {
  .category-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.category-grid .site-item {
  padding-top: var(--spacing-300);
}
@media (min-width: 1040px) {
  .category-grid .site-item {
    padding-top: var(--spacing-400);
  }
}
.category-grid .site-item__content {
  justify-content: space-between;
}
.category-grid .site-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background: var(--border-secondary);
}
.category-grid .site-item:first-child {
  padding-top: 0;
}
.category-grid .site-item:first-child::before {
  display: none;
}
@media (min-width: 600px) {
  .category-grid .site-item:nth-child(2) {
    padding-top: 0;
  }
  .category-grid .site-item:nth-child(2)::before {
    display: none;
  }
}
@media (min-width: 1040px) {
  .category-grid .site-item:nth-child(3) {
    padding-top: 0;
  }
  .category-grid .site-item:nth-child(3)::before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .category-grid .site-item:nth-child(4) {
    padding-top: 0;
  }
  .category-grid .site-item:nth-child(4)::before {
    display: none;
  }
}
.category-grid--in-content {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.category-grid--in-content .site-item:nth-child(2) {
  padding-top: 0;
}
.category-grid--in-content .site-item:nth-child(2)::before {
  display: none;
}
@media (min-width: 600px) {
  .category-grid--in-content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .category-grid--in-content .site-item:nth-child(3) {
    padding-top: 0;
  }
  .category-grid--in-content .site-item:nth-child(3)::before {
    display: block;
  }
}
@media (min-width: 1040px) {
  .category-grid--in-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .category-grid--in-content .site-item:nth-child(3) {
    padding-top: var(--spacing-400);
  }
  .category-grid--in-content .site-item:nth-child(3)::before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .category-grid--in-content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .category-grid--in-content .site-item:nth-child(2), .category-grid--in-content .site-item:nth-child(3) {
    padding-top: 0;
  }
  .category-grid--in-content .site-item:nth-child(2)::before, .category-grid--in-content .site-item:nth-child(3)::before {
    display: none;
  }
}
@media (min-width: 1600px) {
  .category-grid--in-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .category-grid--in-content .site-item:nth-child(4) {
    padding-top: 0;
  }
  .category-grid--in-content .site-item:nth-child(4)::before {
    display: none;
  }
}
.category-grid--competitors {
  row-gap: var(--spacing-200);
  column-gap: var(--spacing-400);
}
.category-grid--competitors .site-item {
  padding-top: var(--spacing-200);
}
@media (min-width: 1040px) {
  .category-grid--competitors > *:nth-child(7) {
    display: none;
  }
  .category-grid--competitors > *:nth-child(8) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .category-grid--competitors > *:nth-child(7) {
    display: flex;
  }
  .category-grid--competitors > *:nth-child(8) {
    display: flex;
  }
}
.category-auto-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.category-column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
}
.category-column .site-item {
  padding-bottom: var(--spacing-400);
  border-bottom: 1px solid var(--border-secondary);
}
.category__button {
  min-width: 300px;
  align-self: center;
}
.category__controls button {
  position: absolute;
  top: calc((100% - 61px) / 2);
  height: calc(100% - 61px);
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.24s ease-in-out;
  transition-delay: 1s;
}
.category__controls button.prev {
  left: 0;
  transform: translate(-50%, -50%);
}
.category__controls button.next {
  right: 0;
  transform: translate(50%, -50%);
}
.category__controls button.hide {
  opacity: 0 !important;
  transition: opacity 0.24s ease-in-out;
}

.category__content,
.trigger-hero-slider {
  position: relative;
}
.category__content:hover .category__controls button,
.trigger-hero-slider:hover .category__controls button {
  opacity: 1;
  transition-duration: 0.24s;
  transition-delay: 0s;
}
.category__content.vertical .site-item__thumbnail,
.trigger-hero-slider.vertical .site-item__thumbnail {
  aspect-ratio: 9/16;
}

.site-item {
  position: relative;
  overflow: hidden;
}
.site-item--content-inside {
  border-radius: var(--radius-150);
}
.site-item--content-inside .site-item {
  --site-logo-size: var(--spacing-400);
}
@media (min-width: 600px) {
  .site-item--content-inside .site-item {
    --site-logo-size: 44px;
  }
}
.site-item--content-inside .site-item__actions {
  opacity: 1;
}
.site-item--content-inside .site-item__content {
  position: absolute;
  bottom: var(--spacing-150);
  left: var(--spacing-150);
  right: var(--spacing-150);
  width: 100%;
  padding: var(--spacing-150);
  margin: calc(var(--spacing-150) * -1);
  justify-content: space-between;
  z-index: 3;
}
.site-item--content-inside .site-item__content::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  z-index: -1;
}
.site-item--content-inside .site-item__content h3 {
  color: var(--non-changing-light);
}
.site-item--content-inside .site-item__content p {
  color: var(--non-changing-light);
  opacity: 0.75;
}
.site-item--actions-inside {
  --site-logo-size: var(--spacing-400);
}
.site-item--actions-inside .site-item__media-wrapper {
  border-radius: var(--radius-75);
  overflow: clip;
}
.site-item--actions-inside .site-item__actions {
  position: absolute;
  bottom: calc(45px + var(--spacing-200) + var(--spacing-100));
  right: var(--spacing-100);
}
@media (min-width: 600px) {
  .site-item--actions-inside .site-item__media-wrapper:hover + .site-item__content .site-item__actions {
    opacity: 1;
  }
  .site-item--actions-inside .site-item__actions {
    opacity: 0;
    transition: opacity 0.24s ease-in-out;
  }
  .site-item--actions-inside .site-item__actions:hover {
    opacity: 1;
  }
}
.site-item__media-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
}
.site-item__media-wrapper .loader {
  display: none;
}
.site-item__media-wrapper.loading .loader {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-200);
  animation: spin 750ms infinite;
  position: absolute;
  top: 8px;
  left: -4px;
}
.site-item__thumbnail {
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
  background: var(--background-secondary);
}
.site-item__thumbnail.vertical {
  aspect-ratio: 9/16;
}
.site-item__thumbnail.square {
  aspect-ratio: 1;
}
.site-item__thumbnail img,
.site-item__thumbnail video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.site-item__thumbnail video {
  pointer-events: none;
  z-index: 2;
}
.site-item__thumbnail video::-webkit-media-controls {
  display: none;
}
.site-item__thumbnail img:not(.base, .icon) {
  z-index: 1;
}
.site-item__thumbnail img:not(.base, .icon).active {
  z-index: 2;
}
.site-item__content {
  --clamp-rows: 1;
  min-height: 45px;
  transform: translateZ(0);
  z-index: 2;
}
.site-item__info {
  display: grid;
  align-items: center;
  column-gap: var(--spacing-150);
  row-gap: 1px;
  grid-template-areas: "logo title" "logo description";
  grid-template-columns: minmax(0, var(--site-logo-size, 44px)) minmax(0, 1fr);
}
.site-item--info-column .site-item__info {
  --site-logo-size: 30px;
  grid-template-areas: "logo title" "description description";
  column-gap: var(--spacing-100);
  row-gap: var(--spacing-75);
}
.site-item__logo {
  grid-area: logo;
}
.site-item__title {
  grid-area: title;
}
.site-item__description {
  grid-area: description;
}
.site-item__logo {
  flex-shrink: 0;
  width: var(--site-logo-size, 100%);
  aspect-ratio: 1/1;
  display: flex;
  border-radius: var(--radius-75);
  position: relative;
  border: 1px solid var(--icon-outline, transparent);
}
.site-item__description > em {
  font-style: normal;
  color: var(--text-link);
}

.index__header {
  padding: 0 var(--spacing-75);
  height: 40px;
  display: flex;
  align-items: center;
  background: var(--background-primary);
  position: sticky;
  top: var(--header-height);
  z-index: 1;
}
.index__items {
  padding-left: var(--spacing-250);
  margin-left: var(--spacing-150);
  border-left: 1px solid var(--border-primary);
}

.site-detail {
  position: relative;
  height: max-content;
}
@media (min-width: 600px) {
  .site-detail {
    position: sticky;
    top: calc(var(--header-height) + var(--spacing-300));
  }
}
@media (min-width: 600px) {
  .site-detail-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--spacing-400);
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
}
@media (min-width: 1040px) {
  .site-detail-wrapper {
    grid-template-columns: minmax(0, 1fr) minmax(0, 450px);
    gap: var(--spacing-800);
  }
}
.site-detail__hero {
  grid-area: hero;
  position: relative;
  background: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: var(--spacing-200);
  z-index: -1;
  aspect-ratio: 1/1;
  overflow: clip;
}
.site-detail__hero:not(.placeholder) > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.site-detail__hero:not(.placeholder) > img.placeholder {
  position: static;
  width: auto;
  height: auto;
}
.site-detail__intro {
  --clamp-rows: 3;
}
@media (min-width: 600px) {
  .site-detail__intro {
    margin-top: 0;
  }
}
.site-detail__intro:not(.static) h1 {
  color: var(--non-changing-light);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
@media (min-width: 600px) {
  .site-detail__intro:not(.static) h1 {
    color: var(--text-primary);
    text-shadow: none;
  }
}
.site-detail__hero-box {
  position: absolute;
  top: calc(-100vw - 74px);
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}
@media (min-width: 600px) {
  .site-detail__hero-box {
    position: static;
    height: auto;
    justify-content: center;
  }
}
.site-detail__intro, .site-detail__hero-box {
  gap: var(--spacing-300);
}
@media (min-width: 600px) {
  .site-detail__intro, .site-detail__hero-box {
    margin-top: 0;
    gap: var(--spacing-400);
  }
}
@media (max-width: 599px) {
  .site-detail__gallery {
    position: relative;
    margin: calc(var(--spacing-300) * -1) calc(var(--spacing-300) * -1) 0;
    height: calc(100vw + 50px + var(--spacing-300));
  }
}
.site-detail__gallery .gallery {
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--spacing-75);
}
@media (max-width: 599px) {
  .site-detail__gallery .gallery {
    aspect-ratio: 1/1;
  }
  .site-detail__gallery .gallery:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 52.6%, var(--background-primary) 100%);
    display: block;
  }
}
.site-detail__gallery .gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--spacing-200);
  height: 100%;
  width: 100%;
  overflow: clip;
  background: var(--background-secondary);
}
@media (min-width: 600px) {
  .site-detail__gallery .gallery-item {
    border-radius: var(--radius-75);
    height: auto;
  }
}
.site-detail__gallery .gallery-item > img:not(.icon) {
  width: 100%;
}
@media (max-width: 599px) {
  .site-detail__gallery .gallery-item > img:not(.icon) {
    height: 100%;
    object-fit: cover;
  }
}
.site-detail__gallery .gallery-item--icon-placeholder {
  aspect-ratio: 1/1;
}
.site-detail__gallery .gallery__nav .button {
  width: 100%;
}
.site-detail__gallery .gallery__nav .button-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  padding: 0 var(--spacing-200);
  display: flex;
  align-items: center;
}
.site-detail__gallery .gallery__nav .button-wrapper:has(.button[data-nav=previous]) {
  left: 0;
}
.site-detail__gallery .gallery__nav .button-wrapper:has(.button[data-nav=next]) {
  right: 0;
}
.site-detail__icon {
  width: 44px;
  height: 44px;
  display: flex;
  border-radius: var(--radius-75);
}
.site-detail__icon > img {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-75);
  background: var(--non-changing-light);
}
.site-detail__label {
  margin-left: auto;
  padding: var(--spacing-50) var(--spacing-25);
}
.site-detail__descriptors.row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--spacing-150);
  justify-content: center;
  height: 80px;
}
.site-detail__descriptors .descriptor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--spacing-50);
}
.site-detail__descriptors .descriptor__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
}
.site-detail__tags {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-100);
}
.site-detail__tags .button--small {
  gap: var(--spacing-50);
}
.site-detail__tags .button--small.active {
  background: var(--background-button-brand-primary-default, var(--background-button-default));
  color: var(--non-changing-light);
}
.site-detail__tags .button--small.active:hover {
  background: var(--background-button-brand-primary-hover, var(--background-button-hover));
  color: var(--non-changing-light);
}
.site-detail__tags .button--small.active:active {
  background: var(--background-button-brand-primary-active, var(--background-button-default));
}
.site-detail__highlights {
  padding: var(--spacing-300) 0;
}
.site-detail__section:last-child {
  border-bottom: none;
}
.site-detail__section__header {
  border-radius: var(--radius-75);
}
.site-detail__section__header .icon svg path {
  stroke: var(--element-icon-primary);
}
.site-detail__section__content .statistics .row:not(:last-child) {
  padding-bottom: var(--spacing-150);
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: var(--spacing-150);
}
.site-detail__section__content .statistics .row-show-more {
  justify-content: center;
}
.site-detail__section__content .statistics .row-show-more .button {
  height: 30px;
  font-size: 14px;
}
.site-detail__section__content .site-detail__social {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 66px;
  gap: var(--spacing-50);
}
.site-detail__section__content .site-detail__social__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--default-height);
  height: var(--default-height);
}
.site-detail__section__content .site-detail__social__label {
  color: var(--text-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.17px;
}
.site-detail__section.opened .icon {
  transform: rotate(180deg);
  transition: transform 0.4s;
}
.site-detail__section.opened .site-detail__section__content {
  transition: max-height 0.35s, opacity 0.35s;
  max-height: 100vh;
  opacity: 1;
}
.site-detail__section.closed .icon {
  transform: rotate(0deg);
}
.site-detail__section.closed .site-detail__section__content {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s, opacity 0.35s;
}

.psa-score {
  width: 40px;
  height: 30px;
  border-radius: 6px;
  background: linear-gradient(-45deg, #A0F878, #2A6011, #AFFF8A);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 1px;
}
.psa-score__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #7CC05C;
}

.dots {
  display: flex;
  gap: 3px;
}
.dots i {
  display: flex;
  width: 4px;
  height: 4px;
  background: var(--element-icon-secondary);
  border-radius: 50%;
}

.icon-placeholder {
  width: 100%;
  height: 100%;
  position: relative;
}
.icon-placeholder .base,
.icon-placeholder .icon {
  position: absolute;
  height: auto;
  opacity: 1;
}
.icon-placeholder .base {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px) brightness(0.8);
  transform: scale(1.15);
  transform-origin: center;
  border-radius: 0;
}
.icon-placeholder .icon {
  width: var(--icon-size, 44px);
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--radius-75);
  box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
}

.crema-tags {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: flex-start;
  gap: var(--spacing-75);
}
@media (min-width: 600px) {
  .crema-tags {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1040px) {
  .crema-tags {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 600px) {
  .crema-tags--one-row {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-secondary);
  aspect-ratio: 17/10;
}
.tag-title {
  color: var(--non-changing-light);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.tag-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-75);
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.tag-image > img, .tag-image::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tag-image > img {
  object-fit: cover;
}
.tag-image::after {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.words-cloud {
  align-content: center;
}
.words-cloud__word {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  transition: transform 0.3s ease;
}
.words-cloud__word[data-weight="7"] {
  font-size: 60px;
}
.words-cloud__word[data-weight="6"] {
  font-size: 40px;
}
.words-cloud__word[data-weight="5"] {
  font-size: 34px;
}
.words-cloud__word[data-weight="4"] {
  font-size: 30px;
}
.words-cloud__word[data-weight="3"] {
  font-size: 20px;
}
.words-cloud__word[data-weight="2"] {
  font-size: 18px;
}
.words-cloud__word[data-weight="1"] {
  font-size: 16px;
}

.image-block {
  max-width: 960px;
  margin: 0 auto;
  padding: var(--spacing-800) 0;
}
.image-block__image {
  max-width: 460px;
}
.image-block__image img {
  max-width: 100%;
}

.twitter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  border: 1px solid var(--border-primary);
  border-radius: 6px;
}
@media (min-width: 1040px) {
  .twitter-item {
    flex-direction: row;
    width: 100%;
    height: 110px;
  }
}
.twitter-item__image, .twitter-item__icon {
  flex-shrink: 0;
  position: relative;
  top: -1px;
  left: -1px;
  right: -1px;
  width: calc(100% + 2px);
  aspect-ratio: 220/140;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: flex;
}
.twitter-item__image > img, .twitter-item__icon > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: none;
}
@media (min-width: 1040px) {
  .twitter-item__image, .twitter-item__icon {
    top: unset;
    right: unset;
    width: 110px;
    height: 110px;
    border-radius: 6px 0 0 6px;
  }
}
.twitter-item__icon {
  justify-content: center;
  align-items: center;
}
.twitter-item__icon--freevideo {
  background-color: #2C2C2C;
}
.twitter-item__icon--freevideo path {
  fill: #C9C9C9;
}
.twitter-item__icon--xfree {
  background-color: #EAEAEA;
}
.twitter-item__icon--xfree path {
  fill: #2C2C2C;
}
.twitter-item__title {
  color: var(--text-secondary);
  padding: 3.38vw 3.9vw;
  transition: color 0.2s;
}
@media (min-width: 600px) {
  .twitter-item__title {
    padding: 2.15vw 2.35vw;
  }
}
@media (min-width: 1040px) {
  .twitter-item__title {
    padding: 0 20px;
  }
}
.twitter-item__title:hover {
  color: var(--text-secondary-hover);
}
.twitter-item__title h3 {
  font-weight: 700;
  font-size: 3.38vw;
  line-height: 1.285;
}
@media (min-width: 600px) {
  .twitter-item__title h3 {
    font-size: 2.15vw;
  }
}
@media (min-width: 1040px) {
  .twitter-item__title h3 {
    font-size: 16px;
    line-height: 22px;
  }
}

.avatar {
  display: block;
  border-radius: 50%;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  text-decoration: none;
}
.avatar-wrapper {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.avatar__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--background-secondary);
}
.avatar__image > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.avatar__pill {
  position: absolute;
  display: flex;
  align-items: center;
  gap: var(--spacing-75);
  height: var(--spacing-400);
  padding: 0 var(--spacing-150);
  right: 0;
  bottom: var(--spacing-150);
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--background-tertiary);
  color: var(--text-primary);
  border-radius: 100px;
}
.avatar__pill:before {
  content: "";
  display: inline-block;
  align-self: center;
  width: 6px;
  height: 6px;
  background: var(--c-malachite);
  border-radius: 50%;
  flex-shrink: 0;
}
.avatar__caption {
  display: inline-block;
  margin-top: var(--spacing-150);
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin__ema-list {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-300) var(--spacing-150);
  gap: var(--spacing-200);
  background: var(--background-tertiary);
  border-radius: 6px;
  box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
  list-style: none;
}
.admin__ema-list li {
  list-style: none;
  border-bottom: 1px solid var(--background-secondary);
  padding-bottom: var(--spacing-200);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}
.admin__ema-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.admin__ema-list small {
  font-weight: 700;
  color: var(--text-primary);
}
.admin__ema-list small > a {
  padding-left: 0;
  font-weight: 700;
}
.admin__ema-list a {
  font-weight: 500;
  font-size: 14px;
  padding-left: var(--spacing-100);
  color: var(--text-link);
}
.admin__ema-list a:hover, .admin__ema-list a:focus {
  color: var(--text-link-hover);
}
.admin__check-list {
  position: fixed;
  right: var(--spacing-800);
  top: calc(148px + var(--spacing-800));
  z-index: 99;
  display: flex;
  padding: var(--spacing-300);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-150);
  background: var(--background-tertiary);
  border-radius: 6px;
  box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
  list-style: none;
  max-width: 400px;
}
.admin__check-list li {
  position: relative;
  padding-left: var(--spacing-400);
  font-weight: 500;
  font-size: 18px;
}
.admin__check-list li:before {
  position: absolute;
  content: "✅";
  left: 0;
  top: 0;
}

.section--error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: var(--spacing-400);
  color: var(--text-secondary);
  margin: auto;
}
.section--error:not(.section--error--psa) .icon {
  width: 32px;
  height: 32px;
  display: flex;
}
.section--error:not(.section--error--psa) .icon path {
  fill: var(--text-primary);
}
.section--error:not(.section--error--psa) h1 {
  font-weight: 700;
  color: var(--text-primary);
  font-size: 16px;
  line-height: 1.5;
}
.section--error:not(.section--error--psa) p {
  font-size: 16px;
  line-height: 1.5;
}
.section--error--freevideo {
  --section-title-font-size: 22px;
  padding: 48px 24px;
  gap: unset;
  text-align: center;
}
.section--error--freevideo video {
  max-width: 80vw;
  margin-bottom: 56px;
}
.section--error--freevideo h1 {
  font-weight: 600;
  font-size: var(--section-title-font-size, 22px);
  margin: var(--section-title-margin, 20px);
  line-height: normal;
}
.section--error--freevideo p {
  max-width: 620px;
  font-weight: 500;
  font-size: var(--section-text-font-size);
  line-height: var(--section-text-line-height);
  margin: var(--section-text-margin, 0 0 40px);
}
.section--error--psa {
  height: 400px;
  text-align: center;
}
.section--error--psa .button {
  width: 300px;
  flex-grow: 0;
}

.aside__section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
}
@media (min-width: 1040px) {
  .aside__section .aside__header, .aside__section .aside__content {
    max-width: 330px;
  }
}
.aside__section--sticky {
  position: sticky;
  top: calc(var(--header-height) + var(--spacing-400));
  max-height: calc(100dvh - (var(--header-height) + var(--spacing-400)));
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.aside__section--sticky::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background-color: transparent;
}
.aside__section--sticky::-webkit-scrollbar-thumb {
  display: none !important;
  background-color: transparent;
}
.aside__section--sticky::-webkit-scrollbar-track {
  background-color: transparent;
}
.aside__section--sticky--blog-detail {
  margin-top: calc(var(--spacing-400) + 21px);
}
.aside__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
  position: relative;
}
.aside__header::after {
  content: "";
  display: block;
  width: 160px;
  height: 2px;
  background: var(--element-icon-primary);
  position: absolute;
  top: 0;
  left: 0;
}
.aside__header a {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-150);
  color: var(--text-primary);
}
.aside__header a .icon {
  display: flex;
}
.aside__header a .icon path {
  fill: var(--element-icon-secondary);
}

div[data-crudadmin-editor],
.article:has(:not(.article__content)),
.article__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.article__section {
  display: flex;
  flex-direction: column;
}
.article__section--device-content {
  gap: var(--spacing-400);
}
.article__section--device-content .mobile,
.article__section--device-content .desktop {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
}
.article__section--device-content .mobile .main-video,
.article__section--device-content .desktop .main-video {
  flex-shrink: 0;
}
.article__section--device-content .mobile {
  order: -1;
}
.article__section--device-content .desktop {
  order: 1;
}
@media (min-width: 1040px) {
  .article__section--device-content .mobile {
    order: 1;
    flex-direction: row;
    align-items: stretch;
  }
  .article__section--device-content .mobile .main-video {
    width: min(30%, 300px);
  }
  .article__section--device-content .desktop {
    order: -1;
  }
}
.article__section--quick-links {
  padding-top: var(--spacing-400);
  gap: var(--spacing-200);
  border-top: 1px solid var(--border-secondary);
}
@media (min-width: 600px) {
  .article__section--quick-links {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: "prev next";
  }
}
.article__section--quick-links .quick-links__button {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--spacing-300);
  gap: var(--spacing-300);
  color: var(--text-primary);
  border-radius: var(--spacing-75);
  border: 1px solid var(--border-secondary);
  background: var(--background-secondary);
  width: 100%;
}
.article__section--quick-links .quick-links__button h4 {
  max-width: 100%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.article__section--quick-links .quick-links__button--prev {
  grid-area: prev;
}
.article__section--quick-links .quick-links__button--prev svg {
  transform: rotate(180deg);
}
.article__section--quick-links .quick-links__button--next {
  grid-area: next;
  flex-flow: row-reverse;
  justify-content: flex-end;
}
.article__section--quick-links .quick-links__button--next .quick-links__content {
  margin-right: auto;
}
.article__section--quick-links .quick-links__button svg {
  flex-shrink: 0;
}
.article__section--quick-links .quick-links__button path {
  fill: var(--text-primary);
}
.article__section--quick-links .quick-links__content {
  display: flex;
  flex-direction: column;
}
.article__section--related {
  border-top: 1px solid var(--border-secondary);
  padding: var(--spacing-400) 0;
}
.article__section--related h3 {
  margin-bottom: var(--spacing-400);
}
.article__section--related ul {
  list-style: none;
  margin: calc(var(--spacing-150) * -1) 0 0;
}
.article__section--related ul li {
  display: flex;
}
.article__section--related ul a {
  padding: var(--spacing-150) 0;
  font-weight: 500;
  color: var(--text-link);
}
.article__section--related ul a:hover {
  color: var(--text-link-hover);
}
.article__subtitle.with-separator {
  padding-bottom: var(--spacing-400);
  margin-bottom: var(--spacing-400);
  border-bottom: 1px solid var(--border-secondary);
}
.article__title {
  margin-top: 0 !important;
}
@media (min-width: 600px) {
  .article__bar hr {
    order: 1;
    flex: 1 1 100%;
  }
  .article__bar--bottom hr {
    order: -1;
  }
}
.article__date {
  padding-bottom: var(--spacing-400);
  border-bottom: 1px solid var(--border-secondary);
  font-style: italic;
  color: var(--text-secondary);
}
.article__socials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.article__content {
  padding-bottom: var(--spacing-400);
  overflow: hidden;
}
.article__content.no-separator {
  border: none;
}

.terms h2 {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
}
.terms li {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}
.terms li strong {
  color: var(--text-primary);
}

.listing__intro {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  padding: var(--spacing-400) 0;
}
@media (min-width: 1040px) {
  .listing__intro {
    width: 50%;
    padding: var(--spacing-800) 0;
  }
}
.listing__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--spacing-400);
}
@media (min-width: 600px) {
  .listing__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1040px) {
  .listing__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: var(--spacing-800);
  }
}
.listing__grid--load-more {
  margin-top: var(--spacing-400);
}
.listing__load-more {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}

.articles-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--spacing-400);
}
@media (min-width: 600px) {
  .articles-list {
    gap: var(--spacing-800);
  }
}
.articles-list + .articles-list {
  margin-top: var(--spacing-400);
}
@media (min-width: 600px) {
  .articles-list + .articles-list {
    margin-top: var(--spacing-800);
  }
}

@media (min-width: 600px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .tablet-only {
    display: none !important;
  }
}
@media (min-width: 1040px) {
  .tablet-only {
    display: none !important;
  }
}
@media (max-width: 599px) {
  .tablet-up {
    display: none !important;
  }
}

@media (max-width: 1039px) {
  .desktop-only {
    display: none !important;
  }
}
@media (min-width: 1040px) {
  .desktop-none {
    display: none !important;
  }
}

.inline {
  display: inline;
}

.is-blurred {
  position: relative;
}
.is-blurred:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
}
@supports (backdrop-filter: blur(10px)) {
  .is-blurred:after {
    backdrop-filter: blur(20px) brightness(110%);
    --webkit-backdrop-filter: blur(20px) brightness(110%);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp-rows, 3);
  line-clamp: var(--clamp-rows, 3);
  -webkit-box-orient: vertical;
}
.line-clamp--2 {
  --clamp-rows: 2;
}
.line-clamp--read-more {
  cursor: pointer;
}
.line-clamp--read-more--em {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700;
  color: var(--text-brand-primary);
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-wrap {
  white-space: nowrap;
}

.word-break {
  word-break: break-word;
}

.sticky {
  position: sticky;
  top: 0;
}

.gap--0 {
  gap: var(--spacing-0);
}
.gap--25 {
  gap: var(--spacing-25);
}
.gap--50 {
  gap: var(--spacing-50);
}
.gap--75 {
  gap: var(--spacing-75);
}
.gap--100 {
  gap: var(--spacing-100);
}
.gap--150 {
  gap: var(--spacing-150);
}
.gap--200 {
  gap: var(--spacing-200);
}
.gap--250 {
  gap: var(--spacing-250);
}
.gap--300 {
  gap: var(--spacing-300);
}
.gap--400 {
  gap: var(--spacing-400);
}
.gap--500 {
  gap: var(--spacing-500);
}
.gap--600 {
  gap: var(--spacing-600);
}
.gap--800 {
  gap: var(--spacing-800);
}
.gap--1000 {
  gap: var(--spacing-1000);
}
.gap--1600 {
  gap: var(--spacing-1600);
}
@media (min-width: 600px) {
  .gap--t--0 {
    gap: var(--spacing-0);
  }
  .gap--t--25 {
    gap: var(--spacing-25);
  }
  .gap--t--50 {
    gap: var(--spacing-50);
  }
  .gap--t--75 {
    gap: var(--spacing-75);
  }
  .gap--t--100 {
    gap: var(--spacing-100);
  }
  .gap--t--150 {
    gap: var(--spacing-150);
  }
  .gap--t--200 {
    gap: var(--spacing-200);
  }
  .gap--t--250 {
    gap: var(--spacing-250);
  }
  .gap--t--300 {
    gap: var(--spacing-300);
  }
  .gap--t--400 {
    gap: var(--spacing-400);
  }
  .gap--t--500 {
    gap: var(--spacing-500);
  }
  .gap--t--600 {
    gap: var(--spacing-600);
  }
  .gap--t--800 {
    gap: var(--spacing-800);
  }
  .gap--t--1000 {
    gap: var(--spacing-1000);
  }
  .gap--t--1600 {
    gap: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .gap--d--0 {
    gap: var(--spacing-0);
  }
  .gap--d--25 {
    gap: var(--spacing-25);
  }
  .gap--d--50 {
    gap: var(--spacing-50);
  }
  .gap--d--75 {
    gap: var(--spacing-75);
  }
  .gap--d--100 {
    gap: var(--spacing-100);
  }
  .gap--d--150 {
    gap: var(--spacing-150);
  }
  .gap--d--200 {
    gap: var(--spacing-200);
  }
  .gap--d--250 {
    gap: var(--spacing-250);
  }
  .gap--d--300 {
    gap: var(--spacing-300);
  }
  .gap--d--400 {
    gap: var(--spacing-400);
  }
  .gap--d--500 {
    gap: var(--spacing-500);
  }
  .gap--d--600 {
    gap: var(--spacing-600);
  }
  .gap--d--800 {
    gap: var(--spacing-800);
  }
  .gap--d--1000 {
    gap: var(--spacing-1000);
  }
  .gap--d--1600 {
    gap: var(--spacing-1600);
  }
}
@media (min-width: 1200px) {
  .gap--m--0 {
    gap: var(--spacing-0);
  }
  .gap--m--25 {
    gap: var(--spacing-25);
  }
  .gap--m--50 {
    gap: var(--spacing-50);
  }
  .gap--m--75 {
    gap: var(--spacing-75);
  }
  .gap--m--100 {
    gap: var(--spacing-100);
  }
  .gap--m--150 {
    gap: var(--spacing-150);
  }
  .gap--m--200 {
    gap: var(--spacing-200);
  }
  .gap--m--250 {
    gap: var(--spacing-250);
  }
  .gap--m--300 {
    gap: var(--spacing-300);
  }
  .gap--m--400 {
    gap: var(--spacing-400);
  }
  .gap--m--500 {
    gap: var(--spacing-500);
  }
  .gap--m--600 {
    gap: var(--spacing-600);
  }
  .gap--m--800 {
    gap: var(--spacing-800);
  }
  .gap--m--1000 {
    gap: var(--spacing-1000);
  }
  .gap--m--1600 {
    gap: var(--spacing-1600);
  }
}
.gap-row--0 {
  row-gap: var(--spacing-0);
}
.gap-row--25 {
  row-gap: var(--spacing-25);
}
.gap-row--50 {
  row-gap: var(--spacing-50);
}
.gap-row--75 {
  row-gap: var(--spacing-75);
}
.gap-row--100 {
  row-gap: var(--spacing-100);
}
.gap-row--150 {
  row-gap: var(--spacing-150);
}
.gap-row--200 {
  row-gap: var(--spacing-200);
}
.gap-row--250 {
  row-gap: var(--spacing-250);
}
.gap-row--300 {
  row-gap: var(--spacing-300);
}
.gap-row--400 {
  row-gap: var(--spacing-400);
}
.gap-row--500 {
  row-gap: var(--spacing-500);
}
.gap-row--600 {
  row-gap: var(--spacing-600);
}
.gap-row--800 {
  row-gap: var(--spacing-800);
}
.gap-row--1000 {
  row-gap: var(--spacing-1000);
}
.gap-row--1600 {
  row-gap: var(--spacing-1600);
}
@media (min-width: 600px) {
  .gap-row--t--0 {
    row-gap: var(--spacing-0);
  }
  .gap-row--t--25 {
    row-gap: var(--spacing-25);
  }
  .gap-row--t--50 {
    row-gap: var(--spacing-50);
  }
  .gap-row--t--75 {
    row-gap: var(--spacing-75);
  }
  .gap-row--t--100 {
    row-gap: var(--spacing-100);
  }
  .gap-row--t--150 {
    row-gap: var(--spacing-150);
  }
  .gap-row--t--200 {
    row-gap: var(--spacing-200);
  }
  .gap-row--t--250 {
    row-gap: var(--spacing-250);
  }
  .gap-row--t--300 {
    row-gap: var(--spacing-300);
  }
  .gap-row--t--400 {
    row-gap: var(--spacing-400);
  }
  .gap-row--t--500 {
    row-gap: var(--spacing-500);
  }
  .gap-row--t--600 {
    row-gap: var(--spacing-600);
  }
  .gap-row--t--800 {
    row-gap: var(--spacing-800);
  }
  .gap-row--t--1000 {
    row-gap: var(--spacing-1000);
  }
  .gap-row--t--1600 {
    row-gap: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .gap-row--d--0 {
    row-gap: var(--spacing-0);
  }
  .gap-row--d--25 {
    row-gap: var(--spacing-25);
  }
  .gap-row--d--50 {
    row-gap: var(--spacing-50);
  }
  .gap-row--d--75 {
    row-gap: var(--spacing-75);
  }
  .gap-row--d--100 {
    row-gap: var(--spacing-100);
  }
  .gap-row--d--150 {
    row-gap: var(--spacing-150);
  }
  .gap-row--d--200 {
    row-gap: var(--spacing-200);
  }
  .gap-row--d--250 {
    row-gap: var(--spacing-250);
  }
  .gap-row--d--300 {
    row-gap: var(--spacing-300);
  }
  .gap-row--d--400 {
    row-gap: var(--spacing-400);
  }
  .gap-row--d--500 {
    row-gap: var(--spacing-500);
  }
  .gap-row--d--600 {
    row-gap: var(--spacing-600);
  }
  .gap-row--d--800 {
    row-gap: var(--spacing-800);
  }
  .gap-row--d--1000 {
    row-gap: var(--spacing-1000);
  }
  .gap-row--d--1600 {
    row-gap: var(--spacing-1600);
  }
}
.gap-column--0 {
  column-gap: var(--spacing-0);
}
.gap-column--25 {
  column-gap: var(--spacing-25);
}
.gap-column--50 {
  column-gap: var(--spacing-50);
}
.gap-column--75 {
  column-gap: var(--spacing-75);
}
.gap-column--100 {
  column-gap: var(--spacing-100);
}
.gap-column--150 {
  column-gap: var(--spacing-150);
}
.gap-column--200 {
  column-gap: var(--spacing-200);
}
.gap-column--250 {
  column-gap: var(--spacing-250);
}
.gap-column--300 {
  column-gap: var(--spacing-300);
}
.gap-column--400 {
  column-gap: var(--spacing-400);
}
.gap-column--500 {
  column-gap: var(--spacing-500);
}
.gap-column--600 {
  column-gap: var(--spacing-600);
}
.gap-column--800 {
  column-gap: var(--spacing-800);
}
.gap-column--1000 {
  column-gap: var(--spacing-1000);
}
.gap-column--1600 {
  column-gap: var(--spacing-1600);
}
@media (min-width: 600px) {
  .gap-column--t--0 {
    column-gap: var(--spacing-0);
  }
  .gap-column--t--25 {
    column-gap: var(--spacing-25);
  }
  .gap-column--t--50 {
    column-gap: var(--spacing-50);
  }
  .gap-column--t--75 {
    column-gap: var(--spacing-75);
  }
  .gap-column--t--100 {
    column-gap: var(--spacing-100);
  }
  .gap-column--t--150 {
    column-gap: var(--spacing-150);
  }
  .gap-column--t--200 {
    column-gap: var(--spacing-200);
  }
  .gap-column--t--250 {
    column-gap: var(--spacing-250);
  }
  .gap-column--t--300 {
    column-gap: var(--spacing-300);
  }
  .gap-column--t--400 {
    column-gap: var(--spacing-400);
  }
  .gap-column--t--500 {
    column-gap: var(--spacing-500);
  }
  .gap-column--t--600 {
    column-gap: var(--spacing-600);
  }
  .gap-column--t--800 {
    column-gap: var(--spacing-800);
  }
  .gap-column--t--1000 {
    column-gap: var(--spacing-1000);
  }
  .gap-column--t--1600 {
    column-gap: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .gap-column--d--0 {
    column-gap: var(--spacing-0);
  }
  .gap-column--d--25 {
    column-gap: var(--spacing-25);
  }
  .gap-column--d--50 {
    column-gap: var(--spacing-50);
  }
  .gap-column--d--75 {
    column-gap: var(--spacing-75);
  }
  .gap-column--d--100 {
    column-gap: var(--spacing-100);
  }
  .gap-column--d--150 {
    column-gap: var(--spacing-150);
  }
  .gap-column--d--200 {
    column-gap: var(--spacing-200);
  }
  .gap-column--d--250 {
    column-gap: var(--spacing-250);
  }
  .gap-column--d--300 {
    column-gap: var(--spacing-300);
  }
  .gap-column--d--400 {
    column-gap: var(--spacing-400);
  }
  .gap-column--d--500 {
    column-gap: var(--spacing-500);
  }
  .gap-column--d--600 {
    column-gap: var(--spacing-600);
  }
  .gap-column--d--800 {
    column-gap: var(--spacing-800);
  }
  .gap-column--d--1000 {
    column-gap: var(--spacing-1000);
  }
  .gap-column--d--1600 {
    column-gap: var(--spacing-1600);
  }
}

.p-a--0 {
  padding: var(--spacing-0);
}
.p-a--25 {
  padding: var(--spacing-25);
}
.p-a--50 {
  padding: var(--spacing-50);
}
.p-a--75 {
  padding: var(--spacing-75);
}
.p-a--100 {
  padding: var(--spacing-100);
}
.p-a--150 {
  padding: var(--spacing-150);
}
.p-a--200 {
  padding: var(--spacing-200);
}
.p-a--250 {
  padding: var(--spacing-250);
}
.p-a--300 {
  padding: var(--spacing-300);
}
.p-a--400 {
  padding: var(--spacing-400);
}
.p-a--500 {
  padding: var(--spacing-500);
}
.p-a--600 {
  padding: var(--spacing-600);
}
.p-a--800 {
  padding: var(--spacing-800);
}
.p-a--1000 {
  padding: var(--spacing-1000);
}
.p-a--1600 {
  padding: var(--spacing-1600);
}
@media (min-width: 600px) {
  .p-a--t--0 {
    padding: var(--spacing-0);
  }
  .p-a--t--25 {
    padding: var(--spacing-25);
  }
  .p-a--t--50 {
    padding: var(--spacing-50);
  }
  .p-a--t--75 {
    padding: var(--spacing-75);
  }
  .p-a--t--100 {
    padding: var(--spacing-100);
  }
  .p-a--t--150 {
    padding: var(--spacing-150);
  }
  .p-a--t--200 {
    padding: var(--spacing-200);
  }
  .p-a--t--250 {
    padding: var(--spacing-250);
  }
  .p-a--t--300 {
    padding: var(--spacing-300);
  }
  .p-a--t--400 {
    padding: var(--spacing-400);
  }
  .p-a--t--500 {
    padding: var(--spacing-500);
  }
  .p-a--t--600 {
    padding: var(--spacing-600);
  }
  .p-a--t--800 {
    padding: var(--spacing-800);
  }
  .p-a--t--1000 {
    padding: var(--spacing-1000);
  }
  .p-a--t--1600 {
    padding: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .p-a--d--0 {
    padding: var(--spacing-0);
  }
  .p-a--d--25 {
    padding: var(--spacing-25);
  }
  .p-a--d--50 {
    padding: var(--spacing-50);
  }
  .p-a--d--75 {
    padding: var(--spacing-75);
  }
  .p-a--d--100 {
    padding: var(--spacing-100);
  }
  .p-a--d--150 {
    padding: var(--spacing-150);
  }
  .p-a--d--200 {
    padding: var(--spacing-200);
  }
  .p-a--d--250 {
    padding: var(--spacing-250);
  }
  .p-a--d--300 {
    padding: var(--spacing-300);
  }
  .p-a--d--400 {
    padding: var(--spacing-400);
  }
  .p-a--d--500 {
    padding: var(--spacing-500);
  }
  .p-a--d--600 {
    padding: var(--spacing-600);
  }
  .p-a--d--800 {
    padding: var(--spacing-800);
  }
  .p-a--d--1000 {
    padding: var(--spacing-1000);
  }
  .p-a--d--1600 {
    padding: var(--spacing-1600);
  }
}

.p-tb--0 {
  padding-top: var(--spacing-0);
  padding-bottom: var(--spacing-0);
}
.p-tb--25 {
  padding-top: var(--spacing-25);
  padding-bottom: var(--spacing-25);
}
.p-tb--50 {
  padding-top: var(--spacing-50);
  padding-bottom: var(--spacing-50);
}
.p-tb--75 {
  padding-top: var(--spacing-75);
  padding-bottom: var(--spacing-75);
}
.p-tb--100 {
  padding-top: var(--spacing-100);
  padding-bottom: var(--spacing-100);
}
.p-tb--150 {
  padding-top: var(--spacing-150);
  padding-bottom: var(--spacing-150);
}
.p-tb--200 {
  padding-top: var(--spacing-200);
  padding-bottom: var(--spacing-200);
}
.p-tb--250 {
  padding-top: var(--spacing-250);
  padding-bottom: var(--spacing-250);
}
.p-tb--300 {
  padding-top: var(--spacing-300);
  padding-bottom: var(--spacing-300);
}
.p-tb--400 {
  padding-top: var(--spacing-400);
  padding-bottom: var(--spacing-400);
}
.p-tb--500 {
  padding-top: var(--spacing-500);
  padding-bottom: var(--spacing-500);
}
.p-tb--600 {
  padding-top: var(--spacing-600);
  padding-bottom: var(--spacing-600);
}
.p-tb--800 {
  padding-top: var(--spacing-800);
  padding-bottom: var(--spacing-800);
}
.p-tb--1000 {
  padding-top: var(--spacing-1000);
  padding-bottom: var(--spacing-1000);
}
.p-tb--1600 {
  padding-top: var(--spacing-1600);
  padding-bottom: var(--spacing-1600);
}
@media (min-width: 600px) {
  .p-tb--t--0 {
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
  }
  .p-tb--t--25 {
    padding-top: var(--spacing-25);
    padding-bottom: var(--spacing-25);
  }
  .p-tb--t--50 {
    padding-top: var(--spacing-50);
    padding-bottom: var(--spacing-50);
  }
  .p-tb--t--75 {
    padding-top: var(--spacing-75);
    padding-bottom: var(--spacing-75);
  }
  .p-tb--t--100 {
    padding-top: var(--spacing-100);
    padding-bottom: var(--spacing-100);
  }
  .p-tb--t--150 {
    padding-top: var(--spacing-150);
    padding-bottom: var(--spacing-150);
  }
  .p-tb--t--200 {
    padding-top: var(--spacing-200);
    padding-bottom: var(--spacing-200);
  }
  .p-tb--t--250 {
    padding-top: var(--spacing-250);
    padding-bottom: var(--spacing-250);
  }
  .p-tb--t--300 {
    padding-top: var(--spacing-300);
    padding-bottom: var(--spacing-300);
  }
  .p-tb--t--400 {
    padding-top: var(--spacing-400);
    padding-bottom: var(--spacing-400);
  }
  .p-tb--t--500 {
    padding-top: var(--spacing-500);
    padding-bottom: var(--spacing-500);
  }
  .p-tb--t--600 {
    padding-top: var(--spacing-600);
    padding-bottom: var(--spacing-600);
  }
  .p-tb--t--800 {
    padding-top: var(--spacing-800);
    padding-bottom: var(--spacing-800);
  }
  .p-tb--t--1000 {
    padding-top: var(--spacing-1000);
    padding-bottom: var(--spacing-1000);
  }
  .p-tb--t--1600 {
    padding-top: var(--spacing-1600);
    padding-bottom: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .p-tb--d--0 {
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
  }
  .p-tb--d--25 {
    padding-top: var(--spacing-25);
    padding-bottom: var(--spacing-25);
  }
  .p-tb--d--50 {
    padding-top: var(--spacing-50);
    padding-bottom: var(--spacing-50);
  }
  .p-tb--d--75 {
    padding-top: var(--spacing-75);
    padding-bottom: var(--spacing-75);
  }
  .p-tb--d--100 {
    padding-top: var(--spacing-100);
    padding-bottom: var(--spacing-100);
  }
  .p-tb--d--150 {
    padding-top: var(--spacing-150);
    padding-bottom: var(--spacing-150);
  }
  .p-tb--d--200 {
    padding-top: var(--spacing-200);
    padding-bottom: var(--spacing-200);
  }
  .p-tb--d--250 {
    padding-top: var(--spacing-250);
    padding-bottom: var(--spacing-250);
  }
  .p-tb--d--300 {
    padding-top: var(--spacing-300);
    padding-bottom: var(--spacing-300);
  }
  .p-tb--d--400 {
    padding-top: var(--spacing-400);
    padding-bottom: var(--spacing-400);
  }
  .p-tb--d--500 {
    padding-top: var(--spacing-500);
    padding-bottom: var(--spacing-500);
  }
  .p-tb--d--600 {
    padding-top: var(--spacing-600);
    padding-bottom: var(--spacing-600);
  }
  .p-tb--d--800 {
    padding-top: var(--spacing-800);
    padding-bottom: var(--spacing-800);
  }
  .p-tb--d--1000 {
    padding-top: var(--spacing-1000);
    padding-bottom: var(--spacing-1000);
  }
  .p-tb--d--1600 {
    padding-top: var(--spacing-1600);
    padding-bottom: var(--spacing-1600);
  }
}

.p-lr--0 {
  padding-left: var(--spacing-0);
  padding-right: var(--spacing-0);
}
.p-lr--25 {
  padding-left: var(--spacing-25);
  padding-right: var(--spacing-25);
}
.p-lr--50 {
  padding-left: var(--spacing-50);
  padding-right: var(--spacing-50);
}
.p-lr--75 {
  padding-left: var(--spacing-75);
  padding-right: var(--spacing-75);
}
.p-lr--100 {
  padding-left: var(--spacing-100);
  padding-right: var(--spacing-100);
}
.p-lr--150 {
  padding-left: var(--spacing-150);
  padding-right: var(--spacing-150);
}
.p-lr--200 {
  padding-left: var(--spacing-200);
  padding-right: var(--spacing-200);
}
.p-lr--250 {
  padding-left: var(--spacing-250);
  padding-right: var(--spacing-250);
}
.p-lr--300 {
  padding-left: var(--spacing-300);
  padding-right: var(--spacing-300);
}
.p-lr--400 {
  padding-left: var(--spacing-400);
  padding-right: var(--spacing-400);
}
.p-lr--500 {
  padding-left: var(--spacing-500);
  padding-right: var(--spacing-500);
}
.p-lr--600 {
  padding-left: var(--spacing-600);
  padding-right: var(--spacing-600);
}
.p-lr--800 {
  padding-left: var(--spacing-800);
  padding-right: var(--spacing-800);
}
.p-lr--1000 {
  padding-left: var(--spacing-1000);
  padding-right: var(--spacing-1000);
}
.p-lr--1600 {
  padding-left: var(--spacing-1600);
  padding-right: var(--spacing-1600);
}
@media (min-width: 600px) {
  .p-lr--t--0 {
    padding-left: var(--spacing-0);
    padding-right: var(--spacing-0);
  }
  .p-lr--t--25 {
    padding-left: var(--spacing-25);
    padding-right: var(--spacing-25);
  }
  .p-lr--t--50 {
    padding-left: var(--spacing-50);
    padding-right: var(--spacing-50);
  }
  .p-lr--t--75 {
    padding-left: var(--spacing-75);
    padding-right: var(--spacing-75);
  }
  .p-lr--t--100 {
    padding-left: var(--spacing-100);
    padding-right: var(--spacing-100);
  }
  .p-lr--t--150 {
    padding-left: var(--spacing-150);
    padding-right: var(--spacing-150);
  }
  .p-lr--t--200 {
    padding-left: var(--spacing-200);
    padding-right: var(--spacing-200);
  }
  .p-lr--t--250 {
    padding-left: var(--spacing-250);
    padding-right: var(--spacing-250);
  }
  .p-lr--t--300 {
    padding-left: var(--spacing-300);
    padding-right: var(--spacing-300);
  }
  .p-lr--t--400 {
    padding-left: var(--spacing-400);
    padding-right: var(--spacing-400);
  }
  .p-lr--t--500 {
    padding-left: var(--spacing-500);
    padding-right: var(--spacing-500);
  }
  .p-lr--t--600 {
    padding-left: var(--spacing-600);
    padding-right: var(--spacing-600);
  }
  .p-lr--t--800 {
    padding-left: var(--spacing-800);
    padding-right: var(--spacing-800);
  }
  .p-lr--t--1000 {
    padding-left: var(--spacing-1000);
    padding-right: var(--spacing-1000);
  }
  .p-lr--t--1600 {
    padding-left: var(--spacing-1600);
    padding-right: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .p-lr--d--0 {
    padding-left: var(--spacing-0);
    padding-right: var(--spacing-0);
  }
  .p-lr--d--25 {
    padding-left: var(--spacing-25);
    padding-right: var(--spacing-25);
  }
  .p-lr--d--50 {
    padding-left: var(--spacing-50);
    padding-right: var(--spacing-50);
  }
  .p-lr--d--75 {
    padding-left: var(--spacing-75);
    padding-right: var(--spacing-75);
  }
  .p-lr--d--100 {
    padding-left: var(--spacing-100);
    padding-right: var(--spacing-100);
  }
  .p-lr--d--150 {
    padding-left: var(--spacing-150);
    padding-right: var(--spacing-150);
  }
  .p-lr--d--200 {
    padding-left: var(--spacing-200);
    padding-right: var(--spacing-200);
  }
  .p-lr--d--250 {
    padding-left: var(--spacing-250);
    padding-right: var(--spacing-250);
  }
  .p-lr--d--300 {
    padding-left: var(--spacing-300);
    padding-right: var(--spacing-300);
  }
  .p-lr--d--400 {
    padding-left: var(--spacing-400);
    padding-right: var(--spacing-400);
  }
  .p-lr--d--500 {
    padding-left: var(--spacing-500);
    padding-right: var(--spacing-500);
  }
  .p-lr--d--600 {
    padding-left: var(--spacing-600);
    padding-right: var(--spacing-600);
  }
  .p-lr--d--800 {
    padding-left: var(--spacing-800);
    padding-right: var(--spacing-800);
  }
  .p-lr--d--1000 {
    padding-left: var(--spacing-1000);
    padding-right: var(--spacing-1000);
  }
  .p-lr--d--1600 {
    padding-left: var(--spacing-1600);
    padding-right: var(--spacing-1600);
  }
}

.p-b--0 {
  padding-bottom: var(--spacing-0);
}
.p-b--25 {
  padding-bottom: var(--spacing-25);
}
.p-b--50 {
  padding-bottom: var(--spacing-50);
}
.p-b--75 {
  padding-bottom: var(--spacing-75);
}
.p-b--100 {
  padding-bottom: var(--spacing-100);
}
.p-b--150 {
  padding-bottom: var(--spacing-150);
}
.p-b--200 {
  padding-bottom: var(--spacing-200);
}
.p-b--250 {
  padding-bottom: var(--spacing-250);
}
.p-b--300 {
  padding-bottom: var(--spacing-300);
}
.p-b--400 {
  padding-bottom: var(--spacing-400);
}
.p-b--500 {
  padding-bottom: var(--spacing-500);
}
.p-b--600 {
  padding-bottom: var(--spacing-600);
}
.p-b--800 {
  padding-bottom: var(--spacing-800);
}
.p-b--1000 {
  padding-bottom: var(--spacing-1000);
}
.p-b--1600 {
  padding-bottom: var(--spacing-1600);
}
@media (min-width: 600px) {
  .p-b--t--0 {
    padding-bottom: var(--spacing-0);
  }
  .p-b--t--25 {
    padding-bottom: var(--spacing-25);
  }
  .p-b--t--50 {
    padding-bottom: var(--spacing-50);
  }
  .p-b--t--75 {
    padding-bottom: var(--spacing-75);
  }
  .p-b--t--100 {
    padding-bottom: var(--spacing-100);
  }
  .p-b--t--150 {
    padding-bottom: var(--spacing-150);
  }
  .p-b--t--200 {
    padding-bottom: var(--spacing-200);
  }
  .p-b--t--250 {
    padding-bottom: var(--spacing-250);
  }
  .p-b--t--300 {
    padding-bottom: var(--spacing-300);
  }
  .p-b--t--400 {
    padding-bottom: var(--spacing-400);
  }
  .p-b--t--500 {
    padding-bottom: var(--spacing-500);
  }
  .p-b--t--600 {
    padding-bottom: var(--spacing-600);
  }
  .p-b--t--800 {
    padding-bottom: var(--spacing-800);
  }
  .p-b--t--1000 {
    padding-bottom: var(--spacing-1000);
  }
  .p-b--t--1600 {
    padding-bottom: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .p-b--d--0 {
    padding-bottom: var(--spacing-0);
  }
  .p-b--d--25 {
    padding-bottom: var(--spacing-25);
  }
  .p-b--d--50 {
    padding-bottom: var(--spacing-50);
  }
  .p-b--d--75 {
    padding-bottom: var(--spacing-75);
  }
  .p-b--d--100 {
    padding-bottom: var(--spacing-100);
  }
  .p-b--d--150 {
    padding-bottom: var(--spacing-150);
  }
  .p-b--d--200 {
    padding-bottom: var(--spacing-200);
  }
  .p-b--d--250 {
    padding-bottom: var(--spacing-250);
  }
  .p-b--d--300 {
    padding-bottom: var(--spacing-300);
  }
  .p-b--d--400 {
    padding-bottom: var(--spacing-400);
  }
  .p-b--d--500 {
    padding-bottom: var(--spacing-500);
  }
  .p-b--d--600 {
    padding-bottom: var(--spacing-600);
  }
  .p-b--d--800 {
    padding-bottom: var(--spacing-800);
  }
  .p-b--d--1000 {
    padding-bottom: var(--spacing-1000);
  }
  .p-b--d--1600 {
    padding-bottom: var(--spacing-1600);
  }
}

.mlr-a {
  margin-left: auto;
  margin-right: auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.icon {
  display: flex;
}
.icon--primary path[fill] {
  fill: var(--element-brand-icon-primary);
}
.icon--primary path[stroke] {
  stroke: var(--element-brand-icon-primary);
}

.cursor-pointer {
  cursor: pointer;
}

.mw {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mw--0 {
  max-width: var(--spacing-0);
}
.mw--25 {
  max-width: var(--spacing-25);
}
.mw--50 {
  max-width: var(--spacing-50);
}
.mw--75 {
  max-width: var(--spacing-75);
}
.mw--100 {
  max-width: var(--spacing-100);
}
.mw--150 {
  max-width: var(--spacing-150);
}
.mw--200 {
  max-width: var(--spacing-200);
}
.mw--250 {
  max-width: var(--spacing-250);
}
.mw--300 {
  max-width: var(--spacing-300);
}
.mw--400 {
  max-width: var(--spacing-400);
}
.mw--500 {
  max-width: var(--spacing-500);
}
.mw--600 {
  max-width: var(--spacing-600);
}
.mw--800 {
  max-width: var(--spacing-800);
}
.mw--1000 {
  max-width: var(--spacing-1000);
}
.mw--1600 {
  max-width: var(--spacing-1600);
}
@media (min-width: 600px) {
  .mw--t--0 {
    max-width: var(--spacing-0);
  }
  .mw--t--25 {
    max-width: var(--spacing-25);
  }
  .mw--t--50 {
    max-width: var(--spacing-50);
  }
  .mw--t--75 {
    max-width: var(--spacing-75);
  }
  .mw--t--100 {
    max-width: var(--spacing-100);
  }
  .mw--t--150 {
    max-width: var(--spacing-150);
  }
  .mw--t--200 {
    max-width: var(--spacing-200);
  }
  .mw--t--250 {
    max-width: var(--spacing-250);
  }
  .mw--t--300 {
    max-width: var(--spacing-300);
  }
  .mw--t--400 {
    max-width: var(--spacing-400);
  }
  .mw--t--500 {
    max-width: var(--spacing-500);
  }
  .mw--t--600 {
    max-width: var(--spacing-600);
  }
  .mw--t--800 {
    max-width: var(--spacing-800);
  }
  .mw--t--1000 {
    max-width: var(--spacing-1000);
  }
  .mw--t--1600 {
    max-width: var(--spacing-1600);
  }
}
@media (min-width: 1040px) {
  .mw--d--0 {
    max-width: var(--spacing-0);
  }
  .mw--d--25 {
    max-width: var(--spacing-25);
  }
  .mw--d--50 {
    max-width: var(--spacing-50);
  }
  .mw--d--75 {
    max-width: var(--spacing-75);
  }
  .mw--d--100 {
    max-width: var(--spacing-100);
  }
  .mw--d--150 {
    max-width: var(--spacing-150);
  }
  .mw--d--200 {
    max-width: var(--spacing-200);
  }
  .mw--d--250 {
    max-width: var(--spacing-250);
  }
  .mw--d--300 {
    max-width: var(--spacing-300);
  }
  .mw--d--400 {
    max-width: var(--spacing-400);
  }
  .mw--d--500 {
    max-width: var(--spacing-500);
  }
  .mw--d--600 {
    max-width: var(--spacing-600);
  }
  .mw--d--800 {
    max-width: var(--spacing-800);
  }
  .mw--d--1000 {
    max-width: var(--spacing-1000);
  }
  .mw--d--1600 {
    max-width: var(--spacing-1600);
  }
}

.placeholder {
  display: flex;
}
.placeholder--sq {
  width: var(--default-height);
  height: var(--default-height);
}

body.hamburger-opened .header .search-button {
  display: none !important;
}
body.lightbox-opened .header {
  z-index: 0;
}
body.hide-pagination .pagination {
  display: none;
}

.js-search-button .btn {
  display: flex;
}
.js-search-button .search {
  display: none;
}
.js-search-button.scrolled .btn {
  display: none;
}
.js-search-button.scrolled .search {
  display: flex;
}

@media (max-width: 599px) {
  .default-hidden-m:not(.visible) {
    display: none;
  }
}

.js-showall-btn span {
  display: initial;
}
.js-showall-btn span.visible {
  display: none;
}
.js-showall-btn.visible svg {
  transform: rotate(180deg);
}
.js-showall-btn.visible span {
  display: none;
}
.js-showall-btn.visible span.visible {
  display: initial;
}