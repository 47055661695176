blockquote {
	position: relative;
    font-size: 22px;
    letter-spacing: 0;
    font-style: italic;
    color: var(--text-secondary);

    @include breakpoint(t) {
        font-size: 26px;
    }

    p {
        display: inline;
        font-size: inherit;
        color: var(--text-secondary);
    }

	&:before {
		content: "„";
	}
	&:after {
		content: "“";
	}
}

em {
    font-style: normal;
}

*[data-crudadmin-editor],
.article:has(:not(.article__content)),
.article__content {
    :is(h1, h2, h3, h4, ul, ol) {
        &:not(:first-child) {
            margin-top:var(--spacing-300);
        }
    }

    :is(img, video, table, hr) {
        margin-top: var(--spacing-300);
    }

    :is(h1, h2, h3, h4):not(:has(+ ul, + ol)) {
        margin-bottom: var(--spacing-100);
    }

    p + :is(ul, ol) {
        margin-top: var(--spacing-100) !important;
    }

    :is(ul, ol) + p {
        margin-top: var(--spacing-300);
    }

    ol,
    ul {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-100);

        li {
            position: relative;
            font-size: 16px;
            line-height: 24px;

            &::before {
                display: inline-block;
                width: var(--spacing-300);
                flex-shrink: 0;
                position: absolute;
                left: 0;
                line-height: 24px;
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: item;

        & > li {
            padding-left: var(--spacing-300);
            counter-increment: item;

            &::before {
                content: counters(item, '.') '. ';
            }
        }

        ol {
            & > li {
                padding-left: var(--spacing-600);

                & > ol > li {
                    padding-left: var(--spacing-800);
                }
            }
        }
    }

    ul {
        counter-reset: item-ul;
        list-style: disc;

        & > li {
            margin-left: var(--spacing-300);
        }
    }

    img, video {
        max-width: 100%;
        height: auto;
        border-radius: var(--radius-75);
    }

    iframe {
        width: 100%;
        min-height: 440px;
        display: block;
    }

    table {
        text-align: left;
        border-collapse: collapse;
        width: 100%;
        border: 1px solid var(--border-secondary);

        caption {
            margin-bottom: 8px;
            font-weight: 600;
        }

        thead {
            background: var(--background-tertiary);
            font-weight: 600;
        }

        th {
            border: none;
        }

        td {
            border: 1px solid;
        }

        td, th {
            padding: 8px;
            word-break: break-word;
            border-color: var(--border-secondary);

            p {
                margin-bottom: 0;
            }
        }
    }

    // default blue link in content editable
    a {
        text-decoration: underline;
        color: var(--text-link);

        &:hover {
            color: var(--text-link-hover);
        }
    }
}
