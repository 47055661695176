.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 31;
    height: 100%;

    &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background: var(--background-primary);
        border-bottom: 1px solid var(--border-secondary);
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.3s;
        z-index: -1;
    }

    &.transparent::before {
        opacity: 0;
    }

    @include breakpoint(t) {
        padding: 0 var(--side-gap);
    }

    &-inner {
        max-width: var(--max-content-width);
        margin: 0 auto;
        display: grid;
        grid-template-rows: 100%;
        gap: var(--spacing-200);
        height: 100%;
        grid-template-areas: "h-logo h-actions-right";
        grid-template-columns: auto minmax(0, 1fr);

        @include max-breakpoint(s) {
            .header__part--center {
                display: none;
            }
        }

        // Changes for small devices (keeping classic t/d settings)
        &--layout {
            &-pornsitesall {
                grid-template-areas: "h-actions-left h-logo h-actions-right";
                grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
            }
        }

        @include breakpoint(t) {
            grid-template-areas: "h-logo h-center h-actions-right";
            grid-template-columns: 165px minmax(0, 1fr) 165px;
        }

        @include breakpoint(d) {
            grid-template-columns: 240px minmax(0, 1fr) 240px;
            gap: var(--spacing-400);
            padding: 0;
        }

        // Changes for large devices (rewriting classic t/d settings)
        &--layout {
            &-help {
                @include breakpoint(d) {
                    grid-template-columns: 300px minmax(0, 500px) auto;
                    gap: var(--spacing-800);
                }
            }
        }
    }

    &__part {
        display: flex;
        align-items: center;
        height: 100%;

        &--logo {
            grid-area: h-logo;
        }

        &--center {
            grid-area: h-center;
            justify-content: center;

            @include breakpoint(d) {
                justify-content: flex-start;
            }
        }

        &--actions-right {
            grid-area: h-actions-right;
            justify-content: flex-end;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        padding: 0 var(--spacing-200);
        height: 100%;

        @include breakpoint(t) {
            padding: 0;
        }

        & > img {
            height: 25px;
        }

    }

    // Navigation wrapper
    &__navigation {
        display: flex;
        margin: 0 auto;
        gap: var(--spacing-100);

        .button {
            background: var(--background-primary);
            color: var(--text-secondary);

            &:not(.active):hover {
                color: var(--text-secondary-hover);
            }

            &.active {
                color: var(--text-primary);
            }
        }
    }

    // Actions wrapper like scheme switch, search, hamburger ...
    &__actions {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        padding: 0 var(--spacing-100);

        @include breakpoint(t) {
            gap: var(--spacing-200);
            padding-right: 0;
        }

        &-item {
            display: flex;
            align-items: center;
            height: 100%;

            svg path {
                stroke: var(--element-icon-primary);
            }

            &--action {
                padding: 0 var(--spacing-100);
                cursor: pointer;
            }

            &--no-padding {
                padding: 0;
            }

            .dropdown-holder {
                margin-right: -4px;
            }
        }
    }
}
