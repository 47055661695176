// Internal
@import '../../sass/settings/colors';
@import '../../sass/settings/global';

@import '../../sass/tools/mixins';

@import '../../sass/generic/reset';
@import '../../sass/generic/shared';

@import '../../sass/elements/buttons';
@import '../../sass/elements/editor-content';
@import '../../sass/elements/inputs';
@import '../../sass/elements/text';
@import '../../sass/elements/structure';

@import '../../sass/objects/themes';
@import '../../sass/objects/animations';
@import '../../sass/objects/layout';
@import '../../sass/objects/layout-content';
@import '../../sass/objects/layout-section';

// COMPONENTS
@import '../../sass/components/header';
@import '../../sass/components/footer';
@import '../../sass/components/blog-item';
@import '../../sass/components/author';
@import '../../sass/components/badges';
@import '../../sass/components/banner';
@import '../../sass/components/button';
@import '../../sass/components/hero';
@import '../../sass/components/label';
@import '../../sass/components/link-nav';
@import '../../sass/components/list';
@import '../../sass/components/preview';
@import '../../sass/components/pagination';
@import '../../sass/components/scroller';
@import '../../sass/components/search-results';
@import '../../sass/components/search';
@import '../../sass/components/slider';
@import '../../sass/components/socials';
@import '../../sass/components/static';
@import '../../sass/components/topics';
@import '../../sass/components/vertical-banner';
@import '../../sass/components/breadcrumb';

// Crema components
@import '../../sass/components/crema/card';
@import '../../sass/components/crema/category';
@import '../../sass/components/crema/index';
@import '../../sass/components/crema/site';
@import '../../sass/components/crema/tags';
@import '../../sass/components/crema/words-cloud';

// Xema components
@import '../../sass/components/xema/image-text';

// Freevideo components
@import '../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../sass/components/glamino/avatar';

// Admin components
@import '../../sass/components/admin/admin';

// PAGES
@import '../../sass/pages/error';

// PAGES - PARTS
@import '../../sass/pages/parts/aside';
@import '../../sass/pages/parts/article';
@import '../../sass/pages/parts/information';
@import '../../sass/pages/parts/listing';

@import '../../sass/utilities/common';



.thumbnail {
    display: flex;
    width: 100%;
    cursor: pointer;

    & > img {
        width: 100%;
        border-radius: var(--radius-75);
    }
}

.thumbs {
    display: flex;
    gap: 8px;
    overflow: hidden;
    margin-top: 8px;

    &-item {
        max-width: 80px;


        img {
            border-radius: var(--radius-75);
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
}

.lightbox {
    &__holder {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(5px);
        z-index: 99;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;

    &__text {
        display: block;
        margin-top: 8px;
    }

    &__media {
        display: flex;
        max-height: calc(100vh - 40px);
        max-width: calc(100vw - 224px);
        border-radius: var(--radius-75);

        img {
            object-fit: contain;
            border-radius: 6px;
        }

        &.hold-width {
            img {
                width: calc(100vw - 224px);
                max-height: calc(100vh - 40px);
            }
        }

        &.hold-height {
            img {
                height: calc(100vh - 40px);
                max-width: calc(100vw - 224px);
            }
        }
    }

    &__arrow {
        position: absolute;
        z-index: 100;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 112px;
        cursor: pointer;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        .button {
            height: 70px;
            width: 35px;

            @include breakpoint(t) {
                height: 92px;
                width: 42px;
            }
        }

        img {
            transition: transform 0.2s, filter 0.2s;
        }

        @media (hover: hover) {
            &:hover {
                img {
                    transform: scale(1.2);
                    filter: brightness(120%);
                }
            }
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        transition: transform 0.2s;
        cursor: pointer;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        z-index: 110;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.popup {
    // No user select
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    width: 314px;
    max-width: calc(100% - 24px);
    display: grid;
    grid-template-rows: 60px minmax(0, 1fr);
    height: auto;
    max-height: 80vh;

    // @include breakpoint(t) {
    //     max-height: 80vh;

    //     @media screen and (max-height: 850px) {
    //         max-height: calc((var(--vh, 1vh) * 100) - 24px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    //     }
    // }

    &__holder {
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(5px);

        @include breakpoint(t) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--media {
        width: auto;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        max-height: calc(
            (var(--vh, 1vh) * 100) - 24px - env(safe-area-inset-top) -
                env(safe-area-inset-bottom)
        );
    }

    &--desktop-media {
        width: auto;
    }
}

.component-holder {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }

    &-enter-to,
    &-leave {
        opacity: 1;
    }
}

// popup base grow
.popup-base {
    &-enter-active,
    &-leave-active {
        transition: all 0.5s;
    }

    &-enter,
    &-leave-to {
        transform: scale(0.9) translateZ(0);
    }

    &-enter-to,
    &-leave {
        transform: scale(1) translateZ(0);
    }
}
