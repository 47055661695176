.words-cloud {
    align-content: center;

    &__word {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-primary);
        transition: transform 0.3s ease;

        // &:hover {
        //     transform: scale(1.1);
        // }

        &[data-weight="7"] {
            font-size: 60px;
        }

        &[data-weight="6"] {
            font-size: 40px;
        }

        &[data-weight="5"] {
            font-size: 34px;
        }

        &[data-weight="4"] {
            font-size: 30px;
        }

        &[data-weight="3"] {
            font-size: 20px;
        }

        &[data-weight="2"] {
            font-size: 18px;
        }

        &[data-weight="1"] {
            font-size: 16px;
        }
    }
}
