.breadcrumbs {
    display: flex;
    align-items: center;
    height: 21px;
    gap: var(--spacing-75);
    list-style: none;
    overflow: hidden;
    max-width: 100%;

    &-item {
        display: flex;
        align-items: center;
        gap: var(--spacing-75);
        position: relative;
        overflow: hidden;
        flex-shrink: 0;

        &:not(:first-child) {
            flex-shrink: 10;
        }

        &:last-child {
            flex-shrink: 100;

            & > span {
                color: var(--text-primary);
            }
        }

        & > a,
        & > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            color: var(--text-tertiary);
        }

        &:first-child {
            & > a {
                display: flex;

                svg path {
                    stroke: var(--element-brand-icon-primary );
                }
            }
        }
    }

    .separator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        flex-shrink: 0;

        path {
            fill: var(--element-icon-secondary);
        }
    }
}
