/* ==========================================================================
   OB: SECTION LAYOUT
   ========================================================================== */

//?? RM?
.section {
    // SECTION LAYOUT - Top
    // Two hero items
    &--top {
        display: grid;
        grid-template-columns: repeat(1, minmax(0,1fr));
        grid-gap: var(--spacing-400);

        &::after {
            content: '';
            display: block;
            height: 2px;
            background: var(--border-secondary);
            margin: var(--spacing-400) 0;
            grid-column: 1/-1;
        }

        @include breakpoint(t) {
            grid-template-columns: repeat(2, minmax(0,1fr));
            align-items: flex-start;
        }
    }

    // Content covers the sides (mobile only), useful where scroll X is being applied
    &--side-less {
        margin: 0 calc(var(--side-gap) * -1);

        // normalizing normal content
        &__normalize {
            margin: 0 var(--side-gap);
        }

        // mobile only reset
        @include breakpoint(t) {
            margin: 0;

            &__normalize {
                margin: 0;
            }
        }
    }
}

//?? RM?
// Sub-Sections
.sub-section {
    &--search {
        height: var(--default-height);
        display: flex;
        width: 100%;
        max-width: var(--limit-search-width, 640px);
        align-self: center;
        justify-content: center;
    }
}

.grid {
    display: grid;
    @include grid-fr;

    @include breakpoint(t) {
        &--t {
            display: grid;

            @include grid-fr;
        }
    }

    @include breakpoint(d) {
        &--d {
            display: grid;

            @include grid-fr;
        }
    }

    @include breakpoint(m) {
        &--m {
            display: grid;

            @include grid-fr;
        }
    }
}

.flex,
.row,
.column {
    display: flex;
    flex-shrink: 1;
}

.row,
.column {
    list-style: none;
    min-width: 0; // resets the min-width auto

    // Other
    &--wrap {
        flex-wrap: wrap;
    }
}

.row {
    align-items: center;

    // Align
    &--center {
        justify-content: center;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--space-evenly {
        justify-content: space-evenly;
    }

    &--end {
        justify-content: end;
    }

    @include breakpoint(t) {
        &--t {
            &--start {
                justify-content: flex-start;
            }

            &--center {
                justify-content: center;
            }

            &--space-between {
                justify-content: space-between;
            }

            &--space-evenly {
                justify-content: space-evenly;
            }
        }
    }


    .column {
        @include breakpoint(t) {
            &--t {
                flex-direction: column;

                &--reverse {
                    flex-direction: column-reverse;
                }
            }
        }

        @include breakpoint(d) {
            &--d {
                flex-direction: column;

                &--reverse {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}

.column {
    flex-direction: column;

    &--reverse {
        flex-direction: column-reverse;
    }

    // Align
    &--fs {
        align-items: flex-start;
    }

    &--center {
        align-items: center;
    }

    &.row {
        @include breakpoint(t) {
            &--t {
                flex-direction: row;

                &--reverse {
                    flex-direction: row-reverse;
                }
            }
        }

        @include breakpoint(d) {
            &--d {
                flex-direction: row;

                &--reverse {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}

.self {
    &--start {
        align-self: flex-start;
    }
}

.shrink {
    flex-shrink: 1;
    min-width: 0;
}

// Grow + shrink + width auto
.gsa {
    flex: 1 1 auto;
}

.no-shrink {
    flex-shrink: 0;
}

.no-grow {
    flex-grow: 0;
}

.fluid {
    width: 100%;
}

