.image-block {
    max-width: 960px;
    margin: 0 auto;
    padding: var(--spacing-800) 0;

    &__title {
    }

    &__image {
        max-width: 460px;

        img {
            max-width: 100%;
        }
    }
}