/* ==========================================================================
   OB: LAYOUT
   ========================================================================== */

// Setups
body {
    --header-height: 60px;
    --max-content-width: 1120px;
    --side-gap: var(--spacing-200);

    --default-height: 44px; // used for default elements like button, search etc.

    // Specifics
    &[data-project="pornsitesall"] {
        --max-content-width: 1800px;
        --max-cl-landing-width: 1400px;
    }

    @include breakpoint(t) {
        --side-gap: 3.6vw;
    }

    @include breakpoint(d) {
        --side-gap: 80px;
    }
}

// PAGE LAYOUT
.l-main {
	position: relative;
	min-height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: grid;
	grid-template-rows: calc(var(--header-height) + env(safe-area-inset-top)) auto minmax(0, 1fr) auto;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-template-areas:
			'header     header      header'
            'hero       hero        hero'
            '.		    main        .'
            '.          footer      .';
    grid-column-gap: var(--side-gap);


    &--airy {
        .page-content__data {
            padding: var(--spacing-300) 0;

            @include breakpoint(t) {
                &.p-t--t--400 {
                    padding-top: var(--spacing-400);
                }
            }

            @include breakpoint(d) {
                padding-bottom: var(--spacing-800);
            }
        }

        @include max-breakpoint(t) {
            --side-gap: var(--spacing-300);
        }
    }
}

header {
	grid-area: header;
}

main {
    grid-area: main;
}

footer {
    grid-area: footer;
}
