.site-detail {
    position: relative;
    height: max-content;

    @include breakpoint(t) {
        position: sticky;
        top: calc(var(--header-height) + var(--spacing-300));
    }

    &-wrapper {
        @include breakpoint(t) {
            display: grid;
            grid-template-columns:  repeat(2, minmax(0, 1fr));
            gap: var(--spacing-400);
            width: 100%;
            max-width: 1400px;
            margin: 0 auto;
        }

        @include breakpoint(d) {
            grid-template-columns:  minmax(0, 1fr) minmax(0, 450px);
            gap: var(--spacing-800);
        }
    }

    // Main image - mobile only
    &__hero {
        grid-area: hero;
        position: relative;
        background: var(--background-secondary);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: var(--spacing-200);
        z-index: -1;
        aspect-ratio: 1/1;
        overflow: clip;

        &:not(.placeholder) > img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.placeholder {
                position: static;
                width: auto;
                height: auto;
            }
        }
    }

    // Holder for wrapper over Hero
    &__intro {
        --clamp-rows: 3;

        @include breakpoint(t) {
            margin-top: 0;
        }

        &:not(.static) {
            h1 {
                color: var(--non-changing-light);
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

                @include breakpoint(t) {
                    color: var(--text-primary);
                    text-shadow: none;
                }
            }
        }
    }

    // Wrapper for initial content that holds hero size on mobile
    &__hero-box {
        position: absolute;
        top: calc(-100vw - 74px); //74px = gallery thumbnails + spacing
        justify-content: space-between;
        width: 100%;
        z-index: 1;

        @include breakpoint(t) {
            position: static;
            height: auto;
            justify-content: center;
        }
    }

    &__intro,
    &__hero-box {
        gap: var(--spacing-300);

        @include breakpoint(t) {
            margin-top: 0;
            gap: var(--spacing-400);
        }
    }

    &__gallery {
        @include max-breakpoint(t) {
            position: relative;
            margin: calc(var(--spacing-300) * -1) calc(var(--spacing-300) * -1) 0;
            height: calc(100vw + 50px + var(--spacing-300));
        }

        .gallery {
            // top: calc(var(--header-height) + env(safe-area-inset-top) + var(--spacing-400));
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: var(--spacing-75);

            @include max-breakpoint(t) {
                aspect-ratio: 1/1;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 52.60%, var(--background-primary) 100%);
                    display: block;
                }
            }

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: var(--spacing-200);
                height: 100%;
                width: 100%;
                overflow: clip;
                background: var(--background-secondary);

                @include breakpoint(t) {
                    border-radius: var(--radius-75);
                    height: auto;
                }

                & > img:not(.icon) {
                    width: 100%;

                    @include max-breakpoint(t) {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &--icon-placeholder {
                    aspect-ratio: 1/1;
                }
            }

            &__nav {
                .button {
                    width: 100%;

                    &-wrapper {
                        position: absolute;
                        z-index: 1;
                        top:0;
                        height: 100%;
                        padding: 0 var(--spacing-200);
                        display: flex;
                        align-items: center;

                        &:has(.button[data-nav="previous"]){
                            left: 0;
                        }

                        &:has(.button[data-nav="next"]){
                            right: 0;
                        }
                    }
                }
            }
        }

    }

    &__icon {
        width: 44px;
        height: 44px;
        display: flex;
        border-radius: var(--radius-75);

        & > img {
            width: 100%;
            height: 100%;
            border-radius: var(--radius-75);
            background: var(--non-changing-light);
        }
    }

    &__label {
        margin-left: auto;
        padding: var(--spacing-50) var(--spacing-25);
    }

    &__descriptors {
        &.row {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: var(--spacing-150);
            justify-content: center;
            height: 80px;
        }

        .descriptor {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: var(--spacing-50);

            &__title {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 28px;
            }
        }
    }

    &__tags {
        display: flex;
        flex-flow: row wrap;
        gap: var(--spacing-100);

        .button--small {
            gap: var(--spacing-50);

            &.active {
                background: var(--background-button-brand-primary-default, var(--background-button-default));
                color: var(--non-changing-light);

                &:hover {
                    background: var(--background-button-brand-primary-hover, var(--background-button-hover));
                    color: var(--non-changing-light);
                }

                &:active {
                    background: var(--background-button-brand-primary-active, var(--background-button-default));
                }
            }
        }
    }

    &__highlights {
        padding: var(--spacing-300) 0;
    }

    // Rollout sections
    &__section {

        &:last-child {
            border-bottom: none;
        }

        &__header {
            border-radius: var(--radius-75);

            .icon {
                svg path {
                    stroke: var(--element-icon-primary);
                }
            }
        }

        &__content {
            // Statistics
            .statistics {
                .row {
                    &:not(:last-child) {
                        padding-bottom: var(--spacing-150);
                        border-bottom: 1px solid var(--border-secondary);
                        margin-bottom: var(--spacing-150);
                    }
                }

                .row-show-more {
                    justify-content: center;

                    .button {
                        height: 30px;
                        font-size: 14px;
                    }
                }
            }

            // Socials
            .site-detail__social {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 66px;
                gap: var(--spacing-50);

                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: var(--default-height);
                    height: var(--default-height);
                }

                &__label {
                    color: var(--text-primary);
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0.17px;
                }
            }
        }

        // Open/Close functionality
        &.opened {
            .icon {
                transform: rotate(180deg);
                transition: transform .4s;
            }

            .site-detail {
                &__section {
                    &__content {
                        transition: max-height .35s, opacity .35s;
                        max-height: 100vh;
                        opacity: 1;
                    }
                }
            }
        }

        &.closed {
            .icon {
                transform: rotate(0deg);
            }

            .site-detail {
                &__section {
                    &__content {
                        max-height: 0;
                        opacity: 0;
                        transition: max-height .5s, opacity .35s;
                    }
                }
            }
        }
    }
}

// MOVE AWAY LATER
.psa {
    &-score {
        width: 40px;
        height: 30px;
        border-radius: 6px;
        background: linear-gradient(-45deg, #A0F878, #2A6011, #AFFF8A);
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        padding: 1px;

        &__inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background: #7CC05C;
        }
    }
}

.dots {
    display: flex;
    gap: 3px;

    i {
        display: flex;
        width: 4px;
        height: 4px;
        background: var(--element-icon-secondary);
        border-radius: 50%;
    }
}


.icon-placeholder {
    width: 100%;
    height: 100%;
    position: relative;

    .base,
    .icon {
        position: absolute;
        height: auto;
        opacity: 1;
    }

    .base {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(15px) brightness(0.8);
        transform: scale(1.15);
        transform-origin: center;
        border-radius: 0;
    }

    .icon {
        width: var(--icon-size, 44px);
        aspect-ratio: 1/1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: var(--radius-75);
        box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
    }
}

