.admin {
    // EMA crossroads
    &__ema-list {
        position: fixed;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        padding: var(--spacing-300) var(--spacing-150);
        gap: var(--spacing-200);
        background: var(--background-tertiary);
        border-radius: 6px;
        box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
        list-style: none;

        li {
            list-style: none;
            border-bottom: 1px solid var(--background-secondary);
            padding-bottom: var(--spacing-200);
            display: flex;
            flex-direction: column;
            gap: var(--spacing-100);

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        small {
            font-weight: 700;
            color: var(--text-primary);

            & > a {
                padding-left: 0;
                font-weight: 700;
            }
        }

        a {
            font-weight: 500;
            font-size: 14px;
            padding-left: var(--spacing-100);
            color: var(--text-link);

            &:hover,
            &:focus {
                color: var(--text-link-hover);
            }
        }
    }

    // Article detail
    &__check-list {
        position: fixed;
        right: var(--spacing-800);
        top: calc(148px + var(--spacing-800));
        z-index: 99;
        display: flex;
        padding: var(--spacing-300);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-150);
        background: var(--background-tertiary);
        border-radius: 6px;
        box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.15);
        list-style: none;
        max-width: 400px;

        li {
            position: relative;
            padding-left: var(--spacing-400);
            font-weight: 500;
            font-size: 18px;

            &:before {
                position: absolute;
                content: "✅";
                left: 0;
                top: 0;
            }
        }
    }
}
