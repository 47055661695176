.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-100);
    padding: 0 var(--spacing-300);
    height: var(--default-height);
    border-radius: var(--radius-75); //default
    background: var(--custom-color, var(--background-button-default));
    transition: transform .24s, background .24s;
    cursor: pointer;
    white-space: nowrap;
    flex-shrink: 0;

    & > svg {
        flex-shrink: 0;

        path {
            &[fill] {
                fill: var(--element-icon-primary);
            }

            &[stroke] {
                stroke: var(--element-icon-primary);
            }
        }
    }

    @media (hover:hover) {
        &:hover {
            background: var(--custom-color-hover, var(--background-button-hover));
        }

        &:focus-visible {
            outline: color-mix(in srgb, var(--state-focus-default) 25%, transparent) 2px solid;
            outline-offset: 2px;
        }

        &:not(.not-clickable):active {
            transform: scale(0.95);
            background: var(--background-button-active);
        }
    }

    &.disabled {
        cursor: not-allowed;
        color: var(--text-secondary);

        & > svg path {
            &[fill] {
                fill: var(--element-icon-secondary);
            }

            &[stroke] {
                stroke: var(--element-icon-secondary);
            }
        }
    }

    &.not-clickable {
        cursor: default;
    }

    // Other sizes
    &--small {
        height: 30px;
        padding: 0 var(--spacing-150);
        border-radius: var(--radius-1000); // default
        gap: var(--spacing-50);
    }

    // Other modifiers
    // Widths
    &--width {
        width: auto;
        max-width: 300px;
        flex-shrink: 1;
        flex-grow: 1;

        @include primitivesToProperty(max-width);
    }

    &--full-width {
        width: 100%;
    }

    &--height {
        @include primitivesToProperty(height);
    }

    &--no-padding {
        padding: 0;
    }

    &--justify {
        &-start {
            justify-content: flex-start;
        }
    }

    &--square {
        width: var(--default-height);
        height: var(--default-height);
        padding: 0;

        &:is(.button--small) {
            width: 30px;
            height: 30px;
        }
    }

    // Border radiuses
    &--rounded {
        border-radius: var(--radius-1000);
    }

    &--curved {
        border-radius: var(--radius-75);
    }

    &--sharp {
        border-radius: var(--radius-0);
    }

    // Colors
    &--brand {
        &--primary {
            background: var(--background-button-brand-primary-default, var(--background-button-default));
            color: var(--non-changing-light);

            & > svg path {
                &[fill] {
                    fill: var(--non-changing-light);
                }

                &[stroke] {
                    stroke: var(--non-changing-light);
                }
            }

            @media (hover:hover) {
                &:hover {
                    background: var(--background-button-brand-primary-hover, var(--background-button-hover));
                    color: var(--non-changing-light);

                    & > svg path {
                        &[fill] {
                            fill: var(--non-changing-light);
                        }

                        &[stroke] {
                            stroke: var(--non-changing-light);
                        }
                    }
                }

                &:focus-visible {
                    outline-color: color-mix(in srgb, var(--background-button-brand-primary-default, var(--background-button-default)) 25%, transparent);
                }

                &:active {
                    background: var(--background-button-brand-primary-active, var(--background-button-default)) !important;
                }
            }
        }

        &--secondary {
            background: var(--background-button-brand-secondary-default, var(--background-button-default));
            color: var(--text-brand-primary);

            & > svg path {
                &[fill] {
                    fill: var(--element-brand-icon-primary);
                }

                &[stroke] {
                    stroke: var(--element-brand-icon-primary);
                }
            }

            @media (hover:hover) {
                &:hover {
                    background: var(--background-button-brand-secondary-hover, var(--background-button-hover));
                    color: var(--text-brand-primary);

                    & > svg path {
                        &[fill] {
                            fill: var(--element-brand-icon-primary);
                        }

                        &[stroke] {
                            stroke: var(--element-brand-icon-primary);
                        }
                    }
                }

                &:focus-visible {
                    outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
                }

                &:active {
                    background: var(--background-button-brand-secondary-active, var(--background-button-default)) !important;
                }
            }
        }

        &--tertiary {
            background: var(--background-button-brand-tertiary-default, var(--background-button-default));
            color: var(--text-link);

            & > svg path {
                &[fill] {
                    fill: var(--element-link);
                }

                &[stroke] {
                    stroke: var(--element-link);
                }
            }

            @media (hover:hover) {
                &:hover {
                    background: var(--background-button-brand-tertiary-hover, var(--background-button-hover));
                    color: var(--text-link);

                    & > svg path {
                        &[fill] {
                            fill: var(--element-link);
                        }

                        &[stroke] {
                            stroke: var(--element-link);
                        }
                    }
                }

                &:focus-visible {
                    outline-color: color-mix(in srgb, var(--background-button-brand-tertiary-hover, var(--background-button-hover)) 25%, transparent);
                }

                &:active {
                    background: var(--background-button-brand-tertiary-active, var(--background-button-default)) !important;
                }
            }
        }
    }

    &--semi-transparent {
        background: var(--transparent-75);
        color: var(--non-changing-light);

        & > svg path {
            &[fill] {
                fill: var(--non-changing-light);
            }
            &[stroke] {
                stroke: var(--non-changing-light);
            }
        }

        @media (hover:hover) {
            &:hover {
                background: var(--transparent-50);
                color: var(--non-changing-light);

                & > svg path {
                    &[fill] {
                        fill: var(--non-changing-light);
                    }
                    &[stroke] {
                        stroke: var(--non-changing-light);
                    }
                }
            }

            &:focus-visible {
                outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
            }

            &:active {
                background: var(--transparent-25) !important;
            }
        }

        &-dark {
            background: var(--color-transparent-black-75);
            color: var(--non-changing-light);

            & > svg path {
                &[fill] {
                    fill: var(--non-changing-light);
                }
                &[stroke] {
                    stroke: var(--non-changing-light);
                }
            }

            @media (hover:hover) {
                &:hover {
                    background: var(--color-transparent-black-50);
                    color: var(--non-changing-light);

                    & > svg path {
                        &[fill] {
                            fill: var(--non-changing-light);
                        }
                        &[stroke] {
                            stroke: var(--non-changing-light);
                        }
                    }
                }

                &:focus-visible {
                    outline-color: color-mix(in srgb, var(--background-button-brand-secondary-hover, var(--background-button-hover)) 25%, transparent);
                }

                &:active {
                    background: var(--color-transparent-black-25) !important;
                }
            }
        }
    }

    &--transparent {
        background: transparent;
    }

    &--transparent-only,
    &--transparent-only:hover {
        background: transparent;

        @media (hover:hover) {
            &:focus-visible {
                outline: none;
            }
        }
    }

    &--outline {
        border: 1px solid var(--background-button-default);
        background: transparent;

        &-thick {
            box-shadow: 0 0 0 4px var(--background-primary);
        }
    }

    &--icon {
        &--brand {
            svg path {
                &[stroke] {
                    stroke: var(--element-brand-icon-primary)
                }

                &[fill] {
                    fill: var(--element-brand-icon-primary)
                }
            }
        }
    }

    &--ellipsis {
        max-width: 100%;

        & > span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
