// Internal
@import '../../../sass/settings/colors';
@import '../../../sass/settings/global';

@import '../../../sass/tools/mixins';

@import '../../../sass/generic/reset';
@import '../../../sass/generic/shared';

@import '../../../sass/elements/buttons';
@import '../../../sass/elements/editor-content';
@import '../../../sass/elements/inputs';
@import '../../../sass/elements/text';
@import '../../../sass/elements/structure';

@import '../../../sass/objects/themes';
@import '../../../sass/objects/animations';
@import '../../../sass/objects/layout';
@import '../../../sass/objects/layout-content';
@import '../../../sass/objects/layout-section';

// COMPONENTS
@import '../../../sass/components/header';
@import '../../../sass/components/footer';
@import '../../../sass/components/blog-item';
@import '../../../sass/components/author';
@import '../../../sass/components/badges';
@import '../../../sass/components/banner';
@import '../../../sass/components/button';
@import '../../../sass/components/hero';
@import '../../../sass/components/label';
@import '../../../sass/components/link-nav';
@import '../../../sass/components/list';
@import '../../../sass/components/preview';
@import '../../../sass/components/pagination';
@import '../../../sass/components/scroller';
@import '../../../sass/components/search-results';
@import '../../../sass/components/search';
@import '../../../sass/components/slider';
@import '../../../sass/components/socials';
@import '../../../sass/components/static';
@import '../../../sass/components/topics';
@import '../../../sass/components/vertical-banner';
@import '../../../sass/components/breadcrumb';

// Crema components
@import '../../../sass/components/crema/card';
@import '../../../sass/components/crema/category';
@import '../../../sass/components/crema/index';
@import '../../../sass/components/crema/site';
@import '../../../sass/components/crema/tags';
@import '../../../sass/components/crema/words-cloud';

// Xema components
@import '../../../sass/components/xema/image-text';

// Freevideo components
@import '../../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../../sass/components/glamino/avatar';

// Admin components
@import '../../../sass/components/admin/admin';

// PAGES
@import '../../../sass/pages/error';

// PAGES - PARTS
@import '../../../sass/pages/parts/aside';
@import '../../../sass/pages/parts/article';
@import '../../../sass/pages/parts/information';
@import '../../../sass/pages/parts/listing';

@import '../../../sass/utilities/common';



.gallery-display {
    position: relative;
    display: flex;
    width: 100%;

    &__main {
        display: flex;
        width: 100%;
        aspect-ratio: 1/1;

        & > img,
        & > video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include breakpoint(t) {
        display: none;
    }
}

.thumbnails {
    --l-scroller-gap: var(--spacing-100);

    @include breakpoint(t) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.thumbnail {
    display: flex;
    height: 50px;
    aspect-ratio: 16/9;

    &__item {
        height: 100%;
        width: 100%;
        border-radius: var(--radius-75);
        overflow: clip;
        position: relative;
        cursor: pointer;

        & > img,
        & > video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &__controls {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
    }

    @include breakpoint(t) {
        aspect-ratio: unset;
        height: auto;
        max-height: calc(
            100dvh - var(--header-height) - var(--spacing-300) - 21px -
                var(--spacing-300) - var(--spacing-300)
        );

        & > img {
            height: auto;
        }

        &:not(.is-vertical):first-child {
            grid-column: 1/-1;
        }
    }
}
