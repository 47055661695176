// Topics scrollable to the side
.topics,
.topic {
    display: flex;
    align-items: center;
}

// TODO: PT - merge with scroller and row
.topics {
    list-style: none;
    height: max-content;

    @include breakpoint(t) {
        margin: -4px;
        padding: 4px;
    }

    // Hidden scrollbar setup
    overflow-x: auto;
    overflow-y: hidden;

    @include invisibleScrollbar;

    &--center {
        // scroll if not enough space, else centered
        @include breakpoint(t) {
            &::before,
            &::after {
                content:'';
                display: block;
                flex: 1 1 auto;
                height: 20px;
            }

            &::before {
                margin-right: calc(var(--spacing-100) * -1)
            }

            &::after {
                margin-left: calc(var(--spacing-100) * -1)
            }
        }
    }

    &.section--side-less {
        margin-left: calc(var(--spacing-200) * -1);
        margin-right: calc(var(--spacing-200) * -1);
        padding-left: var(--spacing-200);
        padding-right: var(--spacing-200);
    }
}

.topic {
    gap: var(--spacing-50);
    background: var(--background-button-default);
    color: var(--text-primary);
    white-space: nowrap;
    transition: background .3s;
    height: 30px;
    padding: 0 var(--spacing-150);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    flex-shrink: 0;
    border-radius: 6px;

    @include breakpoint(t) {
        height: 44px;
        padding: 0 var(--spacing-300);
    }

    & > img {
        width: 22px;
    }

    &:hover {
        background: var(--background-button-hover);
    }

    &.active {
        background: var(--background-button-active);
    }

    &-holder {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

// Adjustment for a different layout
.l-main--airy {
    .topics {
        margin-left: calc(var(--side-gap) * -1);
        margin-right: calc(var(--side-gap) * -1);
        padding-left: var(--side-gap);
        padding-right: var(--side-gap);
    }
}
