/* ==========================================================================
   OB: CONTENT LAYOUT
   ========================================================================== */

// Setups
body {
    // Specifics
    &[data-project="pornsitesall"] {
        --l-content-mw: 600px;
        --l-aside-mw: 352px;

        &.page--category {
            --l-scroller-gap: var(--spacing-200);
        }
    }
}

.page-content {
	grid-area: main;
	position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

	&,
	&__data,
	&__empty {
        width: 100%;

        &--centered {
            align-items: center;
            justify-content: center;
            justify-items: center;
        }
	}

    &__data {
        padding: var(--spacing-400) 0;
        min-height: 100%;

        @include breakpoint(t) {
            max-width: var(--max-content-width);
            margin: 0 auto;
        }

        &--no-padding {
            padding: 0;

            &--m {
                @include max-breakpoint(t) {
                    padding: 0;
                }
            }
        }
    }
}

// CONTENT LAYOUT - LANDING
.cl-landing {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: 'before' 'top' 'aside' 'left';
    grid-gap: var(--spacing-400);
    width: 100%;
    max-width: var(--max-cl-landing-width, var(--max-content-width));
    margin: 0 auto;

    @include breakpoint(d) {
        grid-template-areas:    'before before'
                                'top    top'
                                'left   aside';
        grid-template-columns: minmax(0, 1fr) minmax(0, 400px);
        grid-column-gap: var(--spacing-800);
    }

    .topics {
        grid-area: before;
    }

    .section {
        &--top {
            grid-area: top;
        }

        &--left {
            grid-area: left;
        }

        &--aside {
            grid-area: aside;
        }
    }
}

// CONTENT LAYOUT - CLASSIC (aside + content)
.cl-classic {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: 'aside' 'content';
    gap: var(--spacing-400);

    &.cl-classic--m-aside-off {
        @include max-breakpoint(d) {
            grid-template-areas: 'content';
            grid-template-rows: minmax(0, 1fr);

            .section--aside {
                display: none;
            }
        }
    }

    @include breakpoint(d) {
        grid-template-areas: 'aside content';
        grid-template-columns: 300px minmax(0, 1fr);
        grid-template-rows: auto;
        gap: var(--spacing-800);
    }

    .section {
        &--aside {
            grid-area: aside;
        }

        &--article {
            grid-area: content;
        }
    }
}

// CONTENT LAYOUT - DETAIL
// content, aside (desktop only), more
.cl-detail {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: 'content' 'more';
    gap: var(--spacing-400);

    @include breakpoint(t) {
        grid-template-areas: 'content' 'more';
    }

    @include breakpoint(d) {
        grid-template-areas: 'content aside' 'more more';
        grid-template-columns: min(var(--l-content-mw, 650px), 50vw) var(--l-aside-mw, minmax(0,1fr));
        grid-column-gap: 80px;
        justify-content: space-between;
    }

    &--fluid {
        @include breakpoint(d) {
            grid-template-columns: minmax(0,1fr) var(--l-aside-mw, minmax(0,1fr));
            grid-column-gap: var(--spacing-800);
        }
    }

    &--centered {
        justify-content: center;
        grid-column-gap: var(--spacing-800);
    }

    .section {
        &--article,
        &--content {
            grid-area: content;
        }

        &--aside {
            grid-area: aside;

            @include max-breakpoint(d) {
                display: none;
            }
        }

        &--more {
            grid-area: more;
        }
    }
}

.cl-simple {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    gap: var(--spacing-400);
}
