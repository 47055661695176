/* ==========================================================================
   $MIXINS
   ========================================================================== */

@mixin breakpoint($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin max-breakpoint($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        @media (max-width: $value - 1) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin landscape {
    @media screen and (orientation: landscape) {
        @content;
    }
}

@mixin primitivesToProperty($properties...) {
    $sizes: 0, 25, 50, 75, 100, 150, 200, 250, 300, 400, 500, 600, 800, 1000, 1600;

    @each $size in $sizes {
        &--#{$size} {
            @each $property in $properties {
                #{$property}: var(--spacing-#{$size});
            }
        }
    }
}

@mixin grid-fr() {
    @for $i from 1 through 8 {
        &--#{$i} {
            grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
        }
    }
}

// MORE
@mixin backdrop() {
    background-color: rgba(0, 0, 0, 0.6);

    @supports (backdrop-filter: blur(10px)) {
        backdrop-filter: blur(20px) brightness(110%);
        --webkit-backdrop-filter: blur(20px) brightness(110%);
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@mixin invisibleScrollbar() {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; //hide scrollbar in firefox
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}
