.avatar {
    display: block;
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    text-decoration: none;

    &-wrapper {
        position: relative;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--background-secondary);

        & > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__pill {
        position: absolute;
        display: flex;
        align-items: center;
        gap: var(--spacing-75);
        height: var(--spacing-400);
        padding: 0 var(--spacing-150);
        right: 0;
        bottom: var(--spacing-150);
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        background-color: var(--background-tertiary);
        color: var(--text-primary);
        border-radius: 100px;

        &:before  {
            content: "";
            display: inline-block;
            align-self: center;
            width: 6px;
            height: 6px;
            background: var(--c-malachite);
            border-radius: 50%;
            flex-shrink: 0;
        }
    }

    &__caption {
        display: inline-block;
        margin-top: var(--spacing-150);
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
