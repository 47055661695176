.banner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    overflow: clip;
    border-radius: var(--radius-150);

    &__image,
    &__inner {
        position: absolute;
        left: 0;
        width: 100%;
    }

    &__image {
        top: 0;
        height: 100%;
        background: var(--background-secondary);
        position: relative;
        z-index: -1;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__inner {
        padding: var(--spacing-150);
        display: flex;

        &--top {
            top: 0;
            justify-content: space-between;
        }

        &--bottom {
            bottom: -1px;
            left: -1px;
            right: -1px;
            width: auto;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
        }
    }

    &__icon {
        width: 32px;
        height: 32px;
        border-radius: var(--radius-50);
    }

    &__title,
    &__description {
        color: var(--non-changing-light);
    }

    &__title {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.17px;
    }
}

// SPECIAL BANNERS
// XFREE BANNER
.xfree-banner {
    display: flex;
    padding: var(--spacing-300);
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-300);
    border-radius: var(--radius-150);
    background: #7E1FE0;

    &__left {
        display: flex;
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: var(--spacing-150);

        h4 {
            color: #FFF;
            font-size: 16px;
            font-weight: 400;
        }

        .button {
            color: #FFF;
            font-size: 16px;
            font-weight: 600;
            background: #171717;
        }
    }
}
