/* ==========================================================================
   OB: THEME
   ========================================================================== */
body {
    --element-icon-secondary: var(--color-neutral-400);
    --non-changing-dark: var(--color-neutral-900);
    --non-changing-light: var(--color-neutral-0);
    --state-error-active: var(--color-red-700);
    --state-error-default: var(--color-red-500);
    --state-error-hover: var(--color-red-600);
    --state-focus-active: var(--color-blue-700);
    --state-focus-default: var(--color-blue-500);
    --state-focus-hover: var(--color-blue-600);
    --state-success-active: var(--color-green-700);
    --state-success-default: var(--color-green-500);
    --state-success-hover: var(--color-green-600);
    --state-warning-active: var(--color-yellow-700);
    --state-warning-default: var(--color-yellow-500);
    --state-warning-hover: var(--color-yellow-600);
    --text-link: var(--color-blue-500);

    &[data-theme="light"] {
        --background-primary: var(--color-neutral-0);
        --background-secondary: var(--color-neutral-50);
        --background-tertiary: var(--color-neutral-100);
        --background-button-active: var(--color-neutral-300);
        --background-button-default: var(--color-neutral-100);
        --background-button-hover: var(--color-neutral-200);
        --background-button-pill: var(--color-neutral-0);
        --background-input-default: var(--color-neutral-100);
        --background-input-hover: var(--color-neutral-200);
        --border-primary: var(--color-neutral-100);
        --border-secondary: var(--color-neutral-200);
        --element-icon-primary: var(--color-neutral-900);
        --text-primary: var(--color-neutral-950);
        --text-secondary: var(--color-neutral-600);
        --text-tertiary: var(--color-neutral-400);
        --text-link-hover: var(--color-blue-600);
        --text-primary-hover: var(--color-neutral-1000);
        --text-secondary-hover: var(--color-neutral-700);
        --text-tertiary-hover: var(--color-neutral-500);
        --transparent-25: var(--color-transparent-black-25);
        --transparent-5: var(--color-transparent-black-5);
        --transparent-50: var(--color-transparent-black-50);
        --transparent-75: var(--color-transparent-black-75);

        // Specific
        --icon-outline: var(--border-primary);
    }

    &[data-theme="dark"] {
        --background-primary: var(--color-neutral-900);
        --background-secondary: var(--color-neutral-800);
        --background-tertiary: var(--color-neutral-950);
        --background-button-active: var(--color-neutral-600);
        --background-button-default: var(--color-neutral-750);
        --background-button-hover: var(--color-neutral-700);
        --background-button-pill: var(--color-neutral-800);
        --background-input-default: var(--color-neutral-750);
        --background-input-hover: var(--color-neutral-700);
        --border-primary: var(--color-neutral-800);
        --border-secondary: var(--color-neutral-700);
        --element-icon-primary: var(--color-neutral-0);
        --text-primary: var(--color-neutral-0);
        --text-secondary: var(--color-neutral-300);
        --text-tertiary: var(--color-neutral-400);
        --text-link-hover: var(--color-blue-400);
        --text-primary-hover: var(--color-neutral-0);
        --text-secondary-hover: var(--color-neutral-200);
        --text-tertiary-hover: var(--color-neutral-300);
        --transparent-25: var(--color-transparent-white-25);
        --transparent-5: var(--color-transparent-white-5);
        --transparent-50: var(--color-transparent-white-50);
        --transparent-75: var(--color-transparent-white-75);
    }

    background: var(--background-primary);
    color: var(--text-secondary);

    img {
        color: var(--background-tertiary);
    }

    // IMG with specified theme
    img[data-theme] {
        display: none;
    }

    &[data-theme="light"] {
        img[data-theme="light"] {
            display: initial;
        }
    }

    &[data-theme="dark"] {
        img[data-theme="dark"] {
            display: initial;
        }
    }

    // Project overwrites
    &[data-project="pornsitesall"] {
        &[data-theme="light"] {
            --background-button-brand-primary-active: var(--color-pink-700);
            --background-button-brand-primary-default: var(--color-pink-500);
            --background-button-brand-primary-hover: var(--color-pink-600);
            --background-button-brand-secondary-active: var(--color-pink-200);
            --background-button-brand-secondary-default: var(--color-pink-50);
            --background-button-brand-secondary-hover: var(--color-pink-100);
            --background-button-brand-tertiary-active: var(--color-blue-200);
            --background-button-brand-tertiary-default: var(--color-blue-50);
            --background-button-brand-tertiary-hover: var(--color-blue-100);
            --border-brand-focus: var(--color-pink-300);
            --element-link: var(--color-blue-500);
            --element-brand-icon-primary: var(--color-pink-500);
            --text-link: var(--color-blue-500);
            --text-brand-primary: var(--color-pink-500);
        }

        &[data-theme="dark"] {
            --background-button-brand-primary-active: var(--color-pink-350);
            --background-button-brand-primary-default: var(--color-pink-500);
            --background-button-brand-primary-hover: var(--color-pink-400);
            --background-button-brand-secondary-active: var(--color-pink-600);
            --background-button-brand-secondary-default: var(--color-pink-750);
            --background-button-brand-secondary-hover: var(--color-pink-700);
            --background-button-brand-tertiary-active: var(--color-blue-600);
            --background-button-brand-tertiary-default: var(--color-blue-750);
            --background-button-brand-tertiary-hover: var(--color-blue-700);
            --border-brand-focus: var(--color-pink-700);
            --element-link: var(--color-blue-500);
            --element-brand-icon-primary: var(--color-pink-500);
            --text-link: var(--color-blue-400);
            --text-brand-primary: var(--color-pink-400);
        }
    }

        &[data-project="xfree"] {
            &[data-theme="dark"] {
                --background-button-brand-primary-default: #7f1fe0;
                --background-button-brand-primary-hover: #8c35e3;
            }
            &[data-theme="light"] {
                --background-button-brand-primary-default: #7f1fe0;
                --background-button-brand-primary-hover: #731cca;
            }
        }
}
