.crema-tags {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: flex-start;
    gap: var(--spacing-75);

    @include breakpoint(t) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include breakpoint(d) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &--one-row {
        @include breakpoint(t) {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }
}

.tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-secondary);
    aspect-ratio: 17/10;

    &-title {
        color: var(--non-changing-light);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.50);
        z-index: 1;
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--radius-75);
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);

        & > img,
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        & > img {
            object-fit: cover;
        }

        &::after {
            content: '';
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
        }
    }
}
