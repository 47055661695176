.category {
    &__header {
        height: 52px;
        padding: 0 var(--spacing-150);
        border-radius: var(--radius-75);
        background: var(--custom-category-color, var(--background-button-default));
        z-index: 1;

        @include breakpoint(t) {
            height: 56px;
            padding: 0 var(--spacing-200);
        }

        &__icon {
            width: 24px;
        }

        &__text,
        .h2,
        h2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }
    }

    &.header-transparent {
        .category__header {
            height: auto;
            background: transparent;
        }
    }

    &__description {
        padding: 0 var(--spacing-150);
        --clamp-rows: 2;
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: var(--spacing-300);
        padding-bottom: var(--spacing-200);

        @include breakpoint(t) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint(d) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: var(--spacing-400);
        }

        @include breakpoint(m) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .site-item {
            padding-top: var(--spacing-300);

            @include breakpoint(d) {
                padding-top: var(--spacing-400);
            }

            &__content {
                justify-content: space-between;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                height: 1px;
                width: 100%;
                background: var(--border-secondary);
            }

            &:first-child {
                padding-top: 0;

                &::before {
                    display: none;
                }
            }

            @include breakpoint(t) {
                &:nth-child(2) {
                    padding-top: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            @include breakpoint(d) {
                &:nth-child(3) {
                    padding-top: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            @include breakpoint(m) {
                &:nth-child(4) {
                    padding-top: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        &--in-content {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .site-item {
                &:nth-child(2) {
                    padding-top: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            @include breakpoint(t) {
                grid-template-columns: repeat(3, minmax(0, 1fr));

                .site-item {
                    &:nth-child(3) {
                        padding-top: 0;

                        &::before {
                            display: block;
                        }
                    }
                }
            }

            @include breakpoint(d) {
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .site-item {
                    &:nth-child(3) {
                        padding-top: var(--spacing-400);

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            @include breakpoint(m) {
                grid-template-columns: repeat(3, minmax(0, 1fr));

                .site-item {
                    &:nth-child(2),
                    &:nth-child(3) {
                        padding-top: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            @include breakpoint(f) {
                grid-template-columns: repeat(4, minmax(0, 1fr));

                .site-item {
                    &:nth-child(4) {
                        padding-top: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &--competitors {
            row-gap: var(--spacing-200);
            column-gap: var(--spacing-400);

            .site-item {
                padding-top: var(--spacing-200);
            }

            @include breakpoint(d) {
                & > *:nth-child(7) {
                    display: none;
                }

                & > *:nth-child(8) {
                    display: none;
                }
            }

            @include breakpoint(m) {
                & > *:nth-child(7) {
                    display: flex;
                }

                & > *:nth-child(8) {
                    display: flex;
                }
            }
        }
    }

    &-auto-fill {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }

    &-column {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);

        .site-item {
            padding-bottom: var(--spacing-400);
            border-bottom: 1px solid var(--border-secondary);
        }
    }

    &__button {
        min-width: 300px;
        align-self: center;
    }

    // carousel controls for category
    &__controls {
        button {
            position: absolute;
            top: calc((100% - 61px)/2);
            height: calc(100% - 61px);
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 5;
            opacity: 0;
            transition: opacity .24s ease-in-out;
            transition-delay: 1s;

            &.prev {
                left: 0;
                transform: translate(-50%, -50%);
            }

            &.next {
                right: 0;
                transform: translate(50%, -50%);
            }

            &.hide {
                opacity: 0 !important;
                transition: opacity .24s ease-in-out;
            }
        }
    }
}

.category__content,
.trigger-hero-slider {
    position: relative;

    &:hover {
        .category__controls {
            button {
                opacity: 1;
                transition-duration: .24s;
                transition-delay: 0s;
            }
        }
    }

    &.vertical {
        .site-item__thumbnail {
            aspect-ratio: 9/16;
        }
    }
}

.site-item {
    position: relative;
    overflow: hidden;

    &--content-inside {
        border-radius: var(--radius-150);

        .site-item {
            --site-logo-size: var(--spacing-400);

            @include breakpoint(t) {
                --site-logo-size: 44px;
            }

            &__actions {
                opacity: 1;
            }

            &__content {
                position: absolute;
                bottom: var(--spacing-150);
                left: var(--spacing-150);
                right: var(--spacing-150);
                width: 100%;
                padding: var(--spacing-150);
                margin: calc(var(--spacing-150) * -1);
                justify-content: space-between;
                z-index: 3;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.75) 100%);
                    z-index: -1;
                }

                h3 {
                    color: var(--non-changing-light);
                }

                p {
                    color: var(--non-changing-light);
                    opacity: 0.75;
                }
            }
        }
    }

    &--actions-inside {
        --site-logo-size: var(--spacing-400);

        .site-item {
            &__media-wrapper {
                border-radius: var(--radius-75);
                overflow: clip;
            }

            &__actions {
                position: absolute;
                bottom: calc(45px + var(--spacing-200) + var(--spacing-100));
                right: var(--spacing-100);
            }
        }

        @include breakpoint(t) {
            .site-item {
                &__media-wrapper {
                    &:hover + .site-item__content .site-item__actions {
                        opacity: 1;
                    }
                }

                &__actions {
                    opacity: 0;
                    transition: opacity .24s ease-in-out;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__media-wrapper {
        position: relative;
        width: 100%;
        z-index: 1;

        .loader {
            display: none;
        }

        &.loading {
            .loader {
                display: flex;
                align-items: center;
                padding: 0 var(--spacing-200);
                animation: spin 750ms infinite;
                position: absolute;
                top: 8px;
                left: -4px;
            }
        }
    }

    &__thumbnail {
        display: flex;
        width: 100%;
        aspect-ratio: 16/9;
        background: var(--background-secondary);

        &.vertical {
            aspect-ratio: 9/16;
        }

        &.square {
            aspect-ratio: 1;
        }

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        video {
            pointer-events: none;
            z-index: 2;
        }

        video::-webkit-media-controls {
            display: none;
        }

        img:not(.base, .icon) {
            z-index: 1;

            &.active {
                z-index: 2;
            }
        }
    }

    &__content {
        --clamp-rows: 1;
        min-height: 45px;
        transform: translateZ(0);
        z-index: 2;
    }

    &__info {
        display: grid;
        align-items: center;
        column-gap: var(--spacing-150);
        row-gap: 1px;
        grid-template-areas: 'logo title' 'logo description';
        grid-template-columns: minmax(0, var(--site-logo-size, 44px)) minmax(0, 1fr);
    }

    &--info-column {
        .site-item__info {
            --site-logo-size: 30px;
            grid-template-areas: 'logo title' 'description description';
            column-gap: var(--spacing-100);
            row-gap: var(--spacing-75);
        }
    }

    &__logo {
        grid-area: logo;
    }

    &__title {
        grid-area: title;
    }

    &__description {
        grid-area: description;
    }

    &__logo {
        flex-shrink: 0;
        width: var(--site-logo-size, 100%);
        aspect-ratio: 1/1;
        display: flex;
        border-radius: var(--radius-75);
        position: relative;
        border: 1px solid var(--icon-outline, transparent);
    }

    &__description {
        & > em {
            font-style: normal;
            color: var(--text-link);
        }
    }

}
