// Internal
@import '../../sass/settings/colors';
@import '../../sass/settings/global';

@import '../../sass/tools/mixins';

@import '../../sass/generic/reset';
@import '../../sass/generic/shared';

@import '../../sass/elements/buttons';
@import '../../sass/elements/editor-content';
@import '../../sass/elements/inputs';
@import '../../sass/elements/text';
@import '../../sass/elements/structure';

@import '../../sass/objects/themes';
@import '../../sass/objects/animations';
@import '../../sass/objects/layout';
@import '../../sass/objects/layout-content';
@import '../../sass/objects/layout-section';

// COMPONENTS
@import '../../sass/components/header';
@import '../../sass/components/footer';
@import '../../sass/components/blog-item';
@import '../../sass/components/author';
@import '../../sass/components/badges';
@import '../../sass/components/banner';
@import '../../sass/components/button';
@import '../../sass/components/hero';
@import '../../sass/components/label';
@import '../../sass/components/link-nav';
@import '../../sass/components/list';
@import '../../sass/components/preview';
@import '../../sass/components/pagination';
@import '../../sass/components/scroller';
@import '../../sass/components/search-results';
@import '../../sass/components/search';
@import '../../sass/components/slider';
@import '../../sass/components/socials';
@import '../../sass/components/static';
@import '../../sass/components/topics';
@import '../../sass/components/vertical-banner';
@import '../../sass/components/breadcrumb';

// Crema components
@import '../../sass/components/crema/card';
@import '../../sass/components/crema/category';
@import '../../sass/components/crema/index';
@import '../../sass/components/crema/site';
@import '../../sass/components/crema/tags';
@import '../../sass/components/crema/words-cloud';

// Xema components
@import '../../sass/components/xema/image-text';

// Freevideo components
@import '../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../sass/components/glamino/avatar';

// Admin components
@import '../../sass/components/admin/admin';

// PAGES
@import '../../sass/pages/error';

// PAGES - PARTS
@import '../../sass/pages/parts/aside';
@import '../../sass/pages/parts/article';
@import '../../sass/pages/parts/information';
@import '../../sass/pages/parts/listing';

@import '../../sass/utilities/common';



.hamburger-menu {
    position: fixed;
    width: 100%;
    background: var(--background-primary);
    top: var(--header-height);
    left: 0;
    bottom: 0;
    z-index: 30;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
    padding: var(--spacing-300);

    &--gap {
        &-300 {
            gap: var(--spacing-300);
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);
    }

    &__search {
        min-height: var(--default-height);
        box-sizing: content-box;
    }

    &__nav {
        list-style: none;
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        &--blog {
            gap: var(--spacing-200);

            .button {
                gap: var(--spacing-75);

                & > img {
                    width: 19px;
                }
            }
        }

        &--crema {
            &__item {
                padding: var(--spacing-200) 0;

                &:not(:first-child) {
                    border-top: 1px solid var(--border-primary);
                }

                .icon {
                    display: flex;
                    width: 24px;
                    height: 24px;

                    :deep(svg) {
                        path {
                            stroke: var(--element-icon-primary);
                        }
                    }
                }
            }
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--default-height);

        .dropdown-holder {
            margin-left: auto;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-200);
        background: var(--background-primary);
        padding: var(--spacing-200) 0 0;
        margin-top: auto;
        border-top: 1px solid var(--border-secondary);

        &__wrapper {
            display: flex;
            align-items: center;
            color: var(--text-tertiary);
            text-align: center;
        }

        a {
            display: flex;
            align-items: center;
            color: var(--text-tertiary);
            font-weight: 400;

            &:not(:last-child)::after {
                content: "•";
                flex-shrink: 0;
                margin: 0 var(--spacing-200);
                font-weight: 300;
            }

            .text {
                margin-left: 6px;
            }

            path {
                &[stroke] {
                    stroke: var(--element-icon-secondary);
                }

                &[fill] {
                    fill: var(--element-icon-secondary);
                }
            }
        }
    }

    &-transition {
        &-enter-active,
        &-leave-active {
            transition: transform 0.5s;
        }

        &-enter-from,
        &-leave-to {
            transform: translate3d(-100%, 0, 0);
        }
    }
}
