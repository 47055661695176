.mobile-only {
    @include breakpoint(t) {
        display: none !important;
    }
}

.tablet {
    &-only {
        @include max-breakpoint(t) {
            display: none !important;
        }

        @include breakpoint(d) {
            display: none !important;
        }
    }

    &-up {
        @include max-breakpoint(t) {
            display: none !important;
        }
    }
}

.desktop {
    &-only {
        @include max-breakpoint(d) {
            display: none !important;
        }
    }

    &-none {
        @include breakpoint(d) {
            display: none !important;
        }
    }
}


.inline {
    display: inline;
}

.is-blurred {
    position: relative;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        @include backdrop;
    }
}

.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--clamp-rows, 3);
    line-clamp: var(--clamp-rows, 3);
    -webkit-box-orient: vertical;

    &--2 {
        --clamp-rows: 2;
    }

    &--read-more {
        cursor: pointer;

        // emphasised ellipsis
        &--em {
            font-size: 20px !important;
            line-height: 20px !important;
            font-weight: 700;
            color: var(--text-brand-primary);
        }
    }
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-wrap {
    white-space: nowrap;
}

.word-break {
    word-break: break-word;
}

// Position
.sticky {
    position: sticky;
    top: 0;
}

.gap {
    @include primitivesToProperty(gap);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(gap);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(gap);
        }
    }

    &--m {
        @include breakpoint(m) {
            @include primitivesToProperty(gap);
        }
    }

    &-row {
        @include primitivesToProperty(row-gap);

        &--t {
            @include breakpoint(t) {
                @include primitivesToProperty(row-gap);
            }
        }

        &--d {
            @include breakpoint(d) {
                @include primitivesToProperty(row-gap);
            }
        }
    }

    &-column {
        @include primitivesToProperty(column-gap);

        &--t {
            @include breakpoint(t) {
                @include primitivesToProperty(column-gap);
            }
        }

        &--d {
            @include breakpoint(d) {
                @include primitivesToProperty(column-gap);
            }
        }
    }
}

.p-a {
    @include primitivesToProperty(padding);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(padding);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(padding);
        }
    }
}

.p-tb {
    @include primitivesToProperty(padding-top, padding-bottom);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(padding-top, padding-bottom);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(padding-top, padding-bottom);
        }
    }
}


.p-lr {
    @include primitivesToProperty(padding-left, padding-right);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(padding-left, padding-right);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(padding-left, padding-right);
        }
    }
}

.p-b {
    @include primitivesToProperty(padding-bottom);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(padding-bottom);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(padding-bottom);
        }
    }
}

.mlr-a {
    margin-left: auto;
    margin-right: auto;
}

.ml-a {
    margin-left: auto;
}

.mr-a {
    margin-right: auto;
}

.icon {
    display: flex;

    &--primary {
        path[fill] {
            fill: var(--element-brand-icon-primary)
        }

        path[stroke] {
            stroke: var(--element-brand-icon-primary)
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

// Default max width
.mw {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include primitivesToProperty(max-width);

    &--t {
        @include breakpoint(t) {
            @include primitivesToProperty(max-width);
        }
    }

    &--d {
        @include breakpoint(d) {
            @include primitivesToProperty(max-width);
        }
    }
}

.placeholder {
    display: flex;

    &--sq {
        width: var(--default-height);
        height: var(--default-height);
    }
}


// Global body class hacks
body {

    &.hamburger-opened {
        .header .search-button {
            display: none !important;
        }
    }

    &.lightbox-opened {
        .header {
            z-index: 0;
        }
    }

    &.hide-pagination {
        .pagination {
            display: none;
        }

    }
}

// JS hacks
.js-search-button {
    .btn {
        display: flex;
    }

    .search {
        display: none;
    }

    &.scrolled {
        .btn {
            display: none;
        }

        .search {
            display: flex;
        }
    }
}

// JS supporting CSS
.default-hidden {
    &-m {
        @include max-breakpoint(t) {
            &:not(.visible) {
                display: none;
            }
        }
    }

}

.js-showall-btn {
    span {
        display: initial;

        &.visible {
            display: none;
        }
    }

    &.visible {
        svg {
            transform: rotate(180deg);
        }

        span {
            display: none;

            &.visible {
                display: initial;
            }
        }
    }
}
