.terms {
    h2 {
        display: inline-block;
        font-size: 16px;
        line-height: 22px;
    }

    li {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-100);

        strong {
            color: var(--text-primary);
        }
    }
}
