.section {
    &--error {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: var(--spacing-400);
        color: var(--text-secondary);
        margin: auto;

        &:not(.section--error--psa) {
            .icon {
                width: 32px;
                height: 32px;
                display: flex;

                path {
                    fill: var(--text-primary);
                }
            }

            h1 {
                font-weight: 700;
                color: var(--text-primary);
                font-size: 16px;
                line-height: 1.5;
            }

            p {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        // leaving FV special case for now
        &--freevideo {
            --section-title-font-size: 22px;
            padding: 48px 24px;
            gap: unset;
            text-align: center;

            video {
                max-width: 80vw;
                margin-bottom: 56px;
            }

            h1 {
                font-weight: 600;
                font-size: var(--section-title-font-size, 22px);
                margin: var(--section-title-margin, 20px);
                line-height: normal;
            }

            p {
                max-width: 620px;
                font-weight: 500;
                font-size: var(--section-text-font-size);
                line-height: var(--section-text-line-height);
                margin: var(--section-text-margin, 0 0 40px);
            }
        }

        &--psa {
            height: 400px;
            text-align: center;

            .button {
                width: 300px;
                flex-grow: 0;
            }
        }
    }
}
