html {
    font-family: 'SF Pro Text', '-apple-system',  'HelveticaNeue', 'Roboto', Helvetica, Arial, sans-serif;
	font-size: 16px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;

	&.no-bounce {
		overscroll-behavior-y: none;
		
		body {
			overscroll-behavior-y: none;
		}
	}
}

* {
	// outline: none !important;
	// -webkit-user-select: none;
	// -moz-user-select: none;
	// -ms-user-select: none;
	// user-select: none;
	// -webkit-touch-callout: none;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

body {
	overscroll-behavior: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[contenteditable] {
	-webkit-user-select: text;
	user-select: text;
}

img {
	-webkit-user-select: none; /* neoznacovat obrazky */
	-webkit-touch-callout: none; /* zakazani stahovani obrazku na iphonu */
	transform: translateZ(0);
}

a {
	text-decoration: none;
	cursor: pointer;
}
