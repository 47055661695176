// Internal
@import '../../../sass/settings/colors';
@import '../../../sass/settings/global';

@import '../../../sass/tools/mixins';

@import '../../../sass/generic/reset';
@import '../../../sass/generic/shared';

@import '../../../sass/elements/buttons';
@import '../../../sass/elements/editor-content';
@import '../../../sass/elements/inputs';
@import '../../../sass/elements/text';
@import '../../../sass/elements/structure';

@import '../../../sass/objects/themes';
@import '../../../sass/objects/animations';
@import '../../../sass/objects/layout';
@import '../../../sass/objects/layout-content';
@import '../../../sass/objects/layout-section';

// COMPONENTS
@import '../../../sass/components/header';
@import '../../../sass/components/footer';
@import '../../../sass/components/blog-item';
@import '../../../sass/components/author';
@import '../../../sass/components/badges';
@import '../../../sass/components/banner';
@import '../../../sass/components/button';
@import '../../../sass/components/hero';
@import '../../../sass/components/label';
@import '../../../sass/components/link-nav';
@import '../../../sass/components/list';
@import '../../../sass/components/preview';
@import '../../../sass/components/pagination';
@import '../../../sass/components/scroller';
@import '../../../sass/components/search-results';
@import '../../../sass/components/search';
@import '../../../sass/components/slider';
@import '../../../sass/components/socials';
@import '../../../sass/components/static';
@import '../../../sass/components/topics';
@import '../../../sass/components/vertical-banner';
@import '../../../sass/components/breadcrumb';

// Crema components
@import '../../../sass/components/crema/card';
@import '../../../sass/components/crema/category';
@import '../../../sass/components/crema/index';
@import '../../../sass/components/crema/site';
@import '../../../sass/components/crema/tags';
@import '../../../sass/components/crema/words-cloud';

// Xema components
@import '../../../sass/components/xema/image-text';

// Freevideo components
@import '../../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../../sass/components/glamino/avatar';

// Admin components
@import '../../../sass/components/admin/admin';

// PAGES
@import '../../../sass/pages/error';

// PAGES - PARTS
@import '../../../sass/pages/parts/aside';
@import '../../../sass/pages/parts/article';
@import '../../../sass/pages/parts/information';
@import '../../../sass/pages/parts/listing';

@import '../../../sass/utilities/common';



.slide-up {
    &-holder {
        height: 100vh;
        height: 100dvh;
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        z-index: 99;
        align-items: flex-end;
        display: flex;
        justify-content: center;

        .backdrop {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.75);
            backdrop-filter: blur(5px);
        }

        &.popup-inside {
            background: transparent;
        }

        @include breakpoint(t) {
            align-items: center;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;
        max-width: $slide-up-mw;

        @include breakpoint(d) {
            max-width: 1000px;
        }
    }

    &__drag-bar {
        display: flex;
        height: 32px;
        justify-content: center;
        align-items: flex-start;
        padding-top: var(--spacing-100);
        flex-shrink: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        .grab {
            display: inline-block;
            width: 150px;
            height: 5px;
            background: var(--color-transparent-white-75);
            border-radius: var(--radius-1000);
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        transition: opacity 0.24s ease-in;
        z-index: 2;
    }

    &__container {
        display: grid;
        grid-template-areas: "hero" "content";
        grid-template-columns: minmax(0, 1fr);
        background: var(--background-primary);
        border-radius: var(--radius-300) var(--radius-300) 0 0;
        color: var(--text-secondary);
        position: relative;
        z-index: 1;
        overflow-y: auto;
        overflow-x: clip;
        max-height: 90vh;

        @include breakpoint(t) {
            border-radius: var(--radius-300);
        }

        @include breakpoint(d) {
            grid-template-areas: "hero content";
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__hero {
        grid-area: hero;
    }

    &__content {
        grid-area: content;
    }
}

// Site detail adjustments
.site-detail {
    &__intro {
        --clamp-rows: 2;

        @include breakpoint(d) {
            height: 100%;
            justify-content: center;
        }
    }

    &__tags {
        flex-wrap: nowrap;
        margin: 0 calc(var(--spacing-300) * -1);
        padding: 0 var(--spacing-300);
        overflow-x: auto;
        overflow-y: hidden;
        @include invisibleScrollbar;
    }
}

// ANIMATIONS
.slide-up-animation {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.24s;

        &::before {
            transition: opacity 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }

        .slide-up-wrapper {
            transition: transform 0.24s cubic-bezier(0.2, 0, 0.05, 1);
        }
    }

    &-enter-from,
    &-leave-to {
        &::before {
            opacity: 0;
        }

        .slide-up-wrapper {
            transform: translateY(100%);

            @include breakpoint(t) {
                transform: translateY(calc(50vh + 50%));
            }
        }
    }
}
