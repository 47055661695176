.vertical-banner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    color: var(--text-primary);

    &:not(:last-child) {
        margin-bottom: var(--spacing-400);
    }

    img,
    video {
        border-radius: 6px;
    }
}
