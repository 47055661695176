/* ==========================================================================
   EL: INPUTS
   ========================================================================== */

input {
	border: none;
	outline: none;
	background: transparent;
	padding: 0;
	margin: 0;

	&::-ms-clear {
		display: none;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 60px #fff inset !important;
	background-color: #fff !important;
	background-clip: content-box !important;
}

input {
	&[type='checkbox'].labeled,
	&[type='file'] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		pointer-events: none;
	}
}

input {
	box-sizing: border-box;
	user-select: text;

	&:focus {
		outline: none;
	}

	&:-moz-ui-invalid {
		box-shadow: none;
	}
}

input:disabled,
textarea:disabled,
option:disabled,
optgroup:disabled,
select:disabled {
	color: unset;
	cursor: no-drop;
	opacity: 1;
	-webkit-text-fill-color: #000;
}
