.listing {
    &__intro {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-200);
        padding: var(--spacing-400) 0;

        @include breakpoint(d) {
            width: 50%;
            padding: var(--spacing-800) 0;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: var(--spacing-400);

        @include breakpoint(t) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint(d) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: var(--spacing-800);
        }

        &--load-more { // more posts
            margin-top: var(--spacing-400);
        }
    }

    &__load-more { // button
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
    }
}

.articles-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: var(--spacing-400);

    @include breakpoint(t) {
        gap: var(--spacing-800);
    }

    & + .articles-list {
        margin-top: var(--spacing-400);

        @include breakpoint(t) {
            margin-top: var(--spacing-800);
        }
    }
}
