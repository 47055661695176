$module: 'blog-item';

.blog-item {
    display: grid;
    grid-template-areas: 'preview' 'content' 'title';
    gap: var(--spacing-200);

    @include breakpoint(t) {
        grid-template-areas: 'preview' 'title' 'content';
    }

    &--main {
        @include breakpoint(t) {
            gap: var(--spacing-300);
        }
    }

    &__preview {
        grid-area: preview;
    }

    &__title {
        grid-area: title;

        @include breakpoint(t) {
            order: unset;
        }
    }

    &__content {
        grid-area: content;
        display: flex;
        flex-direction: column;
    }

    &__badges {
        position: absolute;
        bottom: var(--spacing-200);
        left: var(--spacing-200);
    }

    &__description {
        font-size: 16px;
        line-height: 1.5;
        color: var(--text-secondary);

        &:hover {
            color: var(--text-secondary-hover);
        }
    }

    &__read-more {
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        display: none;

        @include breakpoint(t) {
            display: inline-flex;
            gap:var(--spacing-75) ;
        }

        a {
            display: inline-flex;
            align-items: center;
            color: var(--text-primary);
            transition: color .2s;

            path {
                fill: var(--text-primary);
            }

            &:hover {
                color: var(--text-primary-hover); //TODO

                path {
                    fill: var(--text-primary-hover);
                }
            }
        }
    }

    // Listing variant
    &--in-row {
        .#{$module} {
            &__content {
                row-gap: var(--spacing-200);
            }

            &__description {
                --clamp-rows: 3;
                @include max-breakpoint(t) {
                    display: none;
                }
            }
        }

        @include breakpoint(t) {
            grid-template-areas:    "title      title"
                                    "preview    content";
            grid-row-gap: var(--spacing-300);
            grid-column-gap: 0;
            grid-template-columns: min(49%, 364px) minmax(44%, 1fr);
            align-items: center;

            .#{$module} {
                &__content {
                    padding: 0 var(--spacing-400);
                }
            }
        }
    }

    &--listing {
        grid-template-areas: 'preview' 'title' 'content';
        align-content: flex-start;

        .#{$module} {
            &__title,
            &__content {
                text-align: left;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            &__content {
                -webkit-line-clamp: 4;
                line-clamp: 4;
            }
        }
    }


    // recommended variant
    &--recommended {
        width: 100%;
        flex: 0 0 auto;
        grid-template-areas: 'preview' 'title';
        --clamp-rows: 2;
    }
}
