.list {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: calc(var(--spacing-200) * -1);

    @include breakpoint(d) {
        font-size: 20px;
        line-height: 1.4;
    }

    li {
        display: flex;
        padding: var(--spacing-200) var(--spacing-150);
        border-radius: 6px;

        &:not(:first-child) {
            border-top: 1px solid var(--border-secondary);
        }

        &:hover,
        &:focus {
            border-top-color: transparent;
            background: var(--background-secondary);

        }

        &:hover + li,
        &:focus + li {
            border-top-color: transparent;
        }
    }

    a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: var(--spacing-150);
        padding: var(--spacing-150) 0;
        color: var(--text-primary);
        transition: color 0.2s;

        svg {
            flex-shrink: 0;
        }

        path {
            fill: var(--text-link);
        }
    }
}
