// Global editor input wrapper
div[data-crudadmin-editor],
.article:has(:not(.article__content)),
.article__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);
}

.article {
    &__section {
        display: flex;
        flex-direction: column;

        // HERO WRAPPER
        // e.g. Blog detail
        &--device-content {
            gap: var(--spacing-400);

            .mobile,
            .desktop {
                display: flex;
                flex-direction: column;
                gap: var(--spacing-400);

                .main-video {
                    flex-shrink: 0;
                }
            }

            .mobile {
                order: -1;
            }

            .desktop {
                order: 1;
            }

            @include breakpoint(d) {
                .mobile {
                    order: 1;
                    flex-direction: row;
                    align-items: stretch;

                    .main-video {
                        width: min(30%, 300px);
                    }
                }

                .desktop {
                    order: -1;
                }
            }
        }

        &--quick-links {
            padding-top: var(--spacing-400);
            gap: var(--spacing-200);
            border-top: 1px solid var(--border-secondary);

            @include breakpoint(t) {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-areas: 'prev next';
            }

            .quick-links {
                &__button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: var(--spacing-300);
                    gap: var(--spacing-300);
                    color: var(--text-primary);
                    border-radius: var(--spacing-75);
                    border: 1px solid var(--border-secondary);
                    background: var(--background-secondary);
                    width: 100%;

                    h4 {
                        max-width: 100%;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }

                    &--prev {
                        grid-area: prev;

                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    &--next {
                        grid-area: next;
                        flex-flow: row-reverse;
                        justify-content: flex-end;

                        .quick-links__content {
                            margin-right: auto;
                        }
                    }

                    svg {
                        flex-shrink: 0;
                    }

                    path {
                        fill: var(--text-primary);
                    }
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &--related {
            border-top: 1px solid var(--border-secondary);
            padding: var(--spacing-400) 0;

            h3 {
                margin-bottom: var(--spacing-400);
            }

            ul {
                list-style: none;
                margin: calc(var(--spacing-150) * -1) 0 0;

                li {
                    display: flex;
                }

                a {
                    padding: var(--spacing-150) 0;
                    font-weight: 500;
                    color: var(--text-link);

                    &:hover {
                        color: var(--text-link-hover);
                    }
                }
            }
        }
    }

    &__subtitle {
        &.with-separator {
            padding-bottom: var(--spacing-400);
            margin-bottom: var(--spacing-400);
            border-bottom: 1px solid var(--border-secondary);
        }
    }

    &__title {
        margin-top: 0 !important;
    }

    // BAR
    // Article info bar consists of author, date, reading time and on
    &__bar {
        @include breakpoint(t) {
            hr {
                order: 1;
                flex: 1 1 100%;
            }

            &--bottom {
                hr {
                    order: -1;
                }
            }
        }
    }


    &__date {
        padding-bottom: var(--spacing-400);
        border-bottom: 1px solid var(--border-secondary);
        font-style: italic;
        color: var(--text-secondary);
    }

    &__socials {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        padding-bottom: var(--spacing-400);
        overflow: hidden;

        &.no-separator {
            border:  none;
        }
    }
}
