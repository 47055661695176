// Internal
@import '../../../sass/settings/colors';
@import '../../../sass/settings/global';

@import '../../../sass/tools/mixins';

@import '../../../sass/generic/reset';
@import '../../../sass/generic/shared';

@import '../../../sass/elements/buttons';
@import '../../../sass/elements/editor-content';
@import '../../../sass/elements/inputs';
@import '../../../sass/elements/text';
@import '../../../sass/elements/structure';

@import '../../../sass/objects/themes';
@import '../../../sass/objects/animations';
@import '../../../sass/objects/layout';
@import '../../../sass/objects/layout-content';
@import '../../../sass/objects/layout-section';

// COMPONENTS
@import '../../../sass/components/header';
@import '../../../sass/components/footer';
@import '../../../sass/components/blog-item';
@import '../../../sass/components/author';
@import '../../../sass/components/badges';
@import '../../../sass/components/banner';
@import '../../../sass/components/button';
@import '../../../sass/components/hero';
@import '../../../sass/components/label';
@import '../../../sass/components/link-nav';
@import '../../../sass/components/list';
@import '../../../sass/components/preview';
@import '../../../sass/components/pagination';
@import '../../../sass/components/scroller';
@import '../../../sass/components/search-results';
@import '../../../sass/components/search';
@import '../../../sass/components/slider';
@import '../../../sass/components/socials';
@import '../../../sass/components/static';
@import '../../../sass/components/topics';
@import '../../../sass/components/vertical-banner';
@import '../../../sass/components/breadcrumb';

// Crema components
@import '../../../sass/components/crema/card';
@import '../../../sass/components/crema/category';
@import '../../../sass/components/crema/index';
@import '../../../sass/components/crema/site';
@import '../../../sass/components/crema/tags';
@import '../../../sass/components/crema/words-cloud';

// Xema components
@import '../../../sass/components/xema/image-text';

// Freevideo components
@import '../../../sass/components/freevideo/twitter-item';

// Glamino components
@import '../../../sass/components/glamino/avatar';

// Admin components
@import '../../../sass/components/admin/admin';

// PAGES
@import '../../../sass/pages/error';

// PAGES - PARTS
@import '../../../sass/pages/parts/aside';
@import '../../../sass/pages/parts/article';
@import '../../../sass/pages/parts/information';
@import '../../../sass/pages/parts/listing';

@import '../../../sass/utilities/common';



.load-more-button {
    display: flex;
    height: var(--default-height);
}
.loader {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--spacing-200);
    animation: spin 750ms infinite;
}
